<template>
    <!-- 调拨单-收 -->
    <div>
        <div class="dept-retreat">
            <div class="grid-box">
                <div class="right-box">
                    <div>
                        <basic-button
                            :disabled="displayRule('check')"
                            @click="delvoidList"
                            type="primary"
                            :loading="loading"
                            icon-class="acceptance"
                            style="width: 200px"
                        >
                            开始验收
                        </basic-button>
                    </div>
                </div>
                <grid-manager :option="gridOption"></grid-manager>
            </div>
            <SearchFormItem
                :options="options"
                @searchHandel="sureSharch"
                @getState="getFormState"
                ref="SearchFormItemIns"
            ></SearchFormItem>
        </div>
        <el-dialog
            :append-to-body="true"
            title="请选择包裹号"
            :visible.sync="packDialogShow"
            width="20%"
            :before-close="packClose"
            custom-class="pack-dialog"
        >
            <el-select v-model="packageId" size="small" placeholder="请选择">
                <el-option
                    v-for="item in packOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :disabled="item.state == 2"
                >
                </el-option>
            </el-select>
            <el-button type="primary" size="small" @click="packConfirm">确 定</el-button>
        </el-dialog>
        <ShopDetailsStoreOut
            v-if="detailDrawer"
            :modalParams="modalParams"
            @close="detailDrawer = false"
            type="1"
        ></ShopDetailsStoreOut>
        <CheckAcceptPackDrawer
            v-if="CheckAccepting"
            :modalParams="modalParams"
            @close="closePackDrawer"
            @refetch="sureSharch"
        ></CheckAcceptPackDrawer>
    </div>
</template>

<script>
import { columnData, options } from './conFigtrans'
import SearchFormItem from '@/components/SearchFormItem/SearchFormItem'
import { marketAllotQueryMarketAllotList, erp2MarketAllotQueryInvoicePackage } from '@/libs/http/modules/erp.js'
import ShopDetailsStoreOut from '../components/ShopDetailsStoreOut.vue'
import CheckAcceptPackDrawer from './components/CheckAcceptPackDrawer.vue'
export default {
    name: '',
    components: {
        SearchFormItem,
        ShopDetailsStoreOut,
        CheckAcceptPackDrawer
    },
    data() {
        return {
            detailDrawer: false,
            modalParams: null,
            loading: false,
            packDialogShow: false,
            packageId: '',
            packOptions: [],
            checkedList: [],
            gridOption: {
                width: '80vw - 345px',
                height: '85.5vh',
                gridManagerName: 'transferSlipinputEnter',
                firstLoading: true,
                isCombSorting: true,
                supportCheckbox: true,
                supportAutoOrder: false,
                useTrFocus: true,
                lineHeight: '20px',
                supportPrint: false,
                checkboxConfig: {
                    fixed: 'left',
                    useRowCheck: true
                },
                columnData,
                ajaxData: this.fetch,
				ajaxError: this.ajaxError,
                supportAjaxPage: true,
                cellDblClick: this.rowdbClick,
                checkedAfter: this.checkedAfter,
                dataKey: 'items',
                totalsKey: 'totalItem'
            },
            options,
            queryKey: {},
            CheckAccepting: false
        }
    },
    created() {},
    methods: {
        checkedAfter(a) {
            this.checkedList = a
        },
        rowdbClick(row, rowIndex, colIndex) {
            if (colIndex == 1 || colIndex == 0) return
            this.showDetails(row)
        },
        displayRule(type) {
            let rule = true
            let list = this.checkedList
            if (list && list.length) {
                switch (type) {
                    case 'check':
                        rule = !(this.checkedList.length === 1 && list.every((i) => ['9', '11'].includes(i.state)))
                        break
                }
            }
            return rule
        },
        delvoidList() {
            let reqData = {
                id: this.checkedList[0].id
            }
            erp2MarketAllotQueryInvoicePackage(reqData)
                .then((data) => {
                    if (data.itemList.length > 1) {
                        this.packOptions = data.itemList
                        this.packDialogShow = true
                    } else {
                        this.modalParams = data.itemList[0]
                        this.CheckAccepting = true
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })

        },
        getFormState(e) {
            GridManager.resetLayout(this.gridOption.gridManagerName, e ? '80vw - 345px' : '80vw - 40px', '83vh')
        },
        refresh(e) {
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        showDetails(row) {
            this.detailDrawer = true
            this.modalParams = row
        },
        sureSharch(e) {
            this.queryKey = e
            GridManager.refreshGrid(this.gridOption.gridManagerName, true)
        },
        fetch(params) {
            GridManager.setCheckedData(this.gridOption.gridManagerName, [])
            this.checkedList = []
            let reqData = {
                page: params.pageData.cPage,
                pageSize: params.pageData.pSize,
                inMarketIds: [this.$cache.local.get('marketingUnitId')],
                source:'1'
            }
            if (!this.queryKey?.state?.length) {
                reqData['state'] = ['9', '10', '11']
            }
            for (let key in this.queryKey) {
                if (!['createTime', 'updateTime', 'invoicesNoList'].includes(key)) {
                    reqData[key] = this.queryKey[key]
                }

                if (this.queryKey.updateTime && this.queryKey.updateTime.length) {
                    let [srart, end] = this.queryKey.updateTime
                    reqData['updateStartTime'] = `${srart} 00:00:00`
                    reqData['updateEndTime'] = `${end} 23:59:59`
                }
                if (this.queryKey.createTime && this.queryKey.createTime.length) {
                    let [srart, end] = this.queryKey.createTime
                    reqData['createStartTime'] = `${srart} 00:00:00`
                    reqData['createEndTime'] = `${end} 23:59:59`
                }
                if (this.queryKey.invoicesNoList) {
                    reqData['invoicesNoList'] = this.queryKey.invoicesNoList.trim().split(/[ ]+/)
                }
            }
            this.$parent.quantiting()
            return marketAllotQueryMarketAllotList(reqData)
        },
		ajaxError(e){
			this.$message({type: 'error', message: e, duration: 2000, showClose: true})
		},
        packClose() {
            this.packDialogShow = false
            this.packageId = ''
        },
        closePackDrawer(e) {
            this.CheckAccepting = false
            if (e) {
                GridManager.refreshGrid(this.gridOption.gridManagerName, true)
            }
        },
        packConfirm() {
            if (!this.packageId) {
                this.$message.error('请选择包裹号')
                return
            }
            this.CheckAcceptPackDrawer = true
            this.modalParams = { packageId: this.packageId }
            this.packClose()
        }
    }
}
</script>

<style lang="scss" scoped>
.dept-retreat {
    display: flex;
    padding: 10px;
    .grid-box {
    }
    .right-box {
        display: flex;
        justify-content: space-between;
    }
    .search-form-r {
    }
}
</style>
