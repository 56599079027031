<template>
    <el-dialog
        title="扫码历史轨迹"
        width="60%"
        append-to-body
        top="8vh"
        :visible.sync="dialogVisible"
        @close="close">
        <div style='height: 770px;z-index: 1023000'>
            <el-table
                border
                :data="detailsList"
                :header-cell-style="{background:'#F3F4F7',color:'#555',padding: '5px 0'}"
                :cell-style="{padding: '5px 0'}" height='100%'>
                <el-table-column
                    label="序号"
                    type="index"
                    prop='goodsName'
                    align="center"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    label="扫码货品"
                    align="center"
                    width="300">
                </el-table-column>
                <el-table-column
                    prop="goodsNo"
                    label="货号"
                    align="center"
                    width="300">
                </el-table-column>
                <el-table-column
                    prop="color"
                    label="颜色"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="size"
                    label="尺码"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="scanNum"
                    label="扫码数量"
                    align="center">
                </el-table-column>
            </el-table>
        </div>

    </el-dialog>
</template>

<script>
export default {
    name: 'scatrajectory',
    props: ['detailsList'],
    data(){
        return{
            dialogVisible: false,
        }
    },
    created(){
        this.dialogVisible = true;
    },
    methods: {
        close(){
            this.dialogVisible = false;
            this.$emit('close')
        },

    }
}
</script>

<style lang="scss" scoped>
.track-info{
    display: flex;
    flex-wrap: wrap;
    margin-top: -20px;
    margin-bottom: 20px;
    div{
        color: #000;
        width: calc(100% / 3);
        display: flex;
        span{
            width:120px;
            color: #666;
        }
    }
}
</style>