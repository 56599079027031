// 店店调拨
export const dialogConfig = {
    editDialog: function (row) {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/invoicesCenter/components/dialogModulestroe').default,//需要渲染的dialog路径
        moduleObj.ref= 'dialogVisiableRef' + row.id,
        moduleObj.id= row.id,//判断唯一字段。参数名必须一致
        moduleObj.modalParams= row,//要传的参数
        moduleObj.isminimize= false,//判断是否是最小化
        moduleObj.tagName = '编辑店铺调拨单' + ':' + row.id,//最小化标签name
        moduleObj.attrs = { receiverUnitType:'1' , unitType:'2', invoiceType:'100010' } //要传的参数键值对
        return moduleObj
    },

    addDialog: function () {
        // row如果没有id则需要定义id并且赋值上唯一值
        let  moduleObj = {}
        moduleObj.path = require('@/views/invoicesCenter/components/dialogModulestroe').default,//需要渲染的dialog路径
        moduleObj.ref = 'dialogVisiableRef',
        moduleObj.id = '-1',//判断唯一字段。参数名必须一致 
        moduleObj.modalParams = null,//要传的参数
        moduleObj.isminimize= false,//判断是否是最小化
        moduleObj.tagName = '新增店铺调拨单',//最小化标签name
        moduleObj.attrs = { receiverUnitType:'1' ,unitType:'2',invoiceType:'100010'} //要传的参数键值对
        return moduleObj
    },
    detailsDialog: function (row) {
        // row如果没有id则需要定义id并且赋值上唯一值
        let  moduleObj = {}
        moduleObj.path = require('@/views/invoicesCenter/components/ShopDetailsStoreOut').default,//需要渲染的dialog路径
        moduleObj.ref = 'ShopDetailsStoreOut' + row.id,
        moduleObj.id = row.id,//判断唯一字段。参数名必须一致 
        moduleObj.modalParams= row,//要传的参数
        moduleObj.isminimize= false,//判断是否是最小化
        moduleObj.tagName = row.invoicesNo + ':' + row.senderInfoName,//最小化标签name
        moduleObj.attrs = { drawerType:'1' } //要传的参数键值对
        return moduleObj
    },

    // 店铺退仓
    RetreactEditDialog: function (row) {
        // row如果没有id则需要定义id并且赋值上唯一值
        let moduleObj = {}
        moduleObj.path = require('@/views/invoicesCenter/output/components/deptRetreatAddDialog').default,//需要渲染的dialog路径
            moduleObj.ref= 'dialogVisiableRef' + row.id,
            moduleObj.id= row.id,//判断唯一字段。参数名必须一致
            moduleObj.modalParams= row,//要传的参数
            moduleObj.isminimize= false,//判断是否是最小化
            moduleObj.tagName = '编辑店铺退仓单' + ':' + row.id,//最小化标签name
            moduleObj.attrs = {} //要传的参数键值对
        return moduleObj
    },
    RetreactAddDialog: function () {
        // row如果没有id则需要定义id并且赋值上唯一值
        let  moduleObj = {}
        moduleObj.path = require('@/views/invoicesCenter/output/components/deptRetreatAddDialog').default,//需要渲染的dialog路径
            moduleObj.ref = 'dialogVisiableRef',
            moduleObj.id = '-1',//判断唯一字段。参数名必须一致
            moduleObj.modalParams = null,//要传的参数
            moduleObj.isminimize= false,//判断是否是最小化
            moduleObj.tagName = '新增店铺退仓单',//最小化标签name
            moduleObj.attrs = {} //要传的参数键值对
        return moduleObj
    },

}