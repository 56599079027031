//新增配置
export const addColumns = [
    {
        prop: 'goodsName',
        label: '货品名称'
    },
    {
        prop: 'goodsNo',
        label: '货号 (点击查看图片)'
    },
    {
        prop: 'colorValue',
        label: '颜色'
    },
    {
        prop: 'sizeInfoList',
        width: '800px',
        boxWidth: '90px' //复选框宽度
    },
    {
        prop: 'total',
        label: '汇总',
        type: 'count',
        getTotal(row, _key) {
            return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
        }
    }
]
export const addSizeInfoLabel = [
    {
        prop: 'number', //复选框key//valueInfoList当前行对应key
        label: '总数', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    },
    {
        prop: 'checkNum', //复选框key//valueInfoList当前行对应key
        label: '已收', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true, //禁用
        colType: 'checkInput', //当前行是否为输入框
        checkInputDisabled: true, //是否禁用验收输入框
        getRowBgc(_row, row, list) {
            let total = 0
            let checkNumberTotal = 0
            list.forEach((item) => {
                let shipmentNumberAll = item.sizeInfoList.reduce((c, R) => c + Number(R.number || 0), 0)
                total += shipmentNumberAll
                // ------
                let checkNumberAll = item.sizeInfoList.reduce((c, R) => c + Number(R.checkNum || 0), 0)
                checkNumberTotal += checkNumberAll
            })
            // ------------
            let shipmentNumberAll = row.sizeInfoList.reduce((c, R) => c + Number(R.number || 0), 0)
            let checkNumberAll = row.sizeInfoList.reduce((c, R) => c + Number(R.checkNum || 0), 0)
            //-------------
            if (total === checkNumberTotal) {
                return 'row-bg-red'
            } else if (shipmentNumberAll === checkNumberAll) {
                return 'row-bg-blue'
            } else if (+_row.checkNum === +_row.number) {
                return 'row-bg-green'
            } else {
                return ''
            }
        }
    },
    {
        prop: 'noCheckNum', //复选框key//valueInfoList当前行对应key
        label: '未收', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    }
]
export const footerList = [
    {
        prop: 'checkGetNum', //验收
        label: '总件数',
        unit: '',
        formula(data) {
            let num = 0
            data.forEach((item) => {
                let all = item.sizeInfoList.reduce((c, R) => c + Number(R.number || 0), 0)
                num += all
            })
            return num
        }
    },
    {
        prop: 'ReceivedTotal',
        label: '已收',
        unit: '',
        formula(data) {
            let num = 0
            data.forEach((item) => {
                let all = item.sizeInfoList.reduce((c, R) => c + Number(R.checkNum || 0), 0)
                num += all
            })
            return num
        }
    },
    {
        prop: 'noReceivedTotal',
        label: '未收',
        unit: '',
        formula(data) {
            let num = 0
            data.forEach((item) => {
                let all = item.sizeInfoList.reduce((c, R) => c + Number(R.noCheckNum || 0), 0)
                num += all
            })
            return num
        }
    }
]
