<template>
    <!-- 退供 -->
    <el-drawer
        size="99%"
        :visible.sync="dialogVisible"
        :show-close="false"
        :wrapperClosable="false"
        v-loading="infoDetailLoading"
        :close-on-press-escape="false"
        @close="close"
    >
        <section slot="title" class="dialog-top">
            <p>新增店铺退供单</p>
            <div style="display: flex">
                <!-- <el-button type="success" @click="beforePublish(3)">确认提交</el-button> -->
                <div class="sub-box" @click="beforePublish(3)">
                    <svg-icon class-name="size-icon" icon-class="submit" /> 确认提交
                </div>
                <i class="el-icon-close" @click="dialogVisible = false"></i>
            </div>
        </section>

        <section class="content">
            <div class="content-form">
                <div class="content-title">请填写信息</div>
                <el-form ref="form" size="small" :model="form" :rules="rules">
                    <el-form-item label="收货方" prop="receiverInfoId">
                        <el-select
                            style="width: 100%"
                            v-model="form.receiverInfoId"
                            placeholder="请选择供应商"
                            filterable
                            @change="changeSupplier"
                            remote
                            @focus="fetchSupplier()"
                            :remote-method="
                                (val) => {
                                    fetchSupplier(val)
                                }
                            "
                            @clear="fetchSupplier('')"
                        >
                            <OptionSupplier
                                style="min-width: 450px"
                                :supplierList="supplierList"
                                :totalItem="totalItem"
                                :page="page"
                                @fatherMethod="changePage"
                            ></OptionSupplier>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                            placeholder="请填写备注信息"
                            type="textarea"
                            v-model="form.description"
                            rows="10"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content-add">
                <div class="content-title">
                    <span
                        style="
                            width: 4px;
                            height: 18px;
                            background: #067cf2;
                            border-radius: 2px;
                            margin-right: 4px;
                            margin-top: 4px;
                        "
                    ></span>
                    新增货品明细
                    <!-- <div style="margin-left: 20px">
                        <el-button size="small" native-type="submit" @click="searchList" type="primary"
                            >查 询</el-button
                        >
                        <el-button size="small" @click="resetForm('goodsForm')">重 置</el-button>
                    </div> -->
                </div>
                <section class="addTable">
                    <el-row :gutter="20" class="add-table-top">
                        <el-col :span="16"
                            ><erp-selector
                                type="goodsNo"
                                v-model="form.goodsNo"
                                modelKey="goodsNo"
                                placeholder="请选择货号"
                                @change="searchList"
                            ></erp-selector
                        ></el-col>
                        <el-col :span="8">
                            <el-button size="small" @click="searchList">查 询</el-button>
                        </el-col>
                    </el-row>
                    <!-- <el-form inline size="small" ref="goodsForm" @submit.native.prevent>
                        <el-row>
                            <el-form-item prop="goodsNo">
                                <el-input
                                    style="width: 200px"
                                    @keydown.native.enter="searchList"
                                    v-model="form.goodsNo"
                                    placeholder="请输入货号"
                                ></el-input>
                            </el-form-item>
                            <el-form-item prop="timeClassify">
                                <el-cascader
                                    placeholder="请选择时间分类"
                                    style="width: 200px"
                                    v-model="form.timeClassify"
                                    clearable
                                    collapse-tags
                                    filterable
                                    :options="categoryListTime"
                                    :props="{
                                        multiple: true,
                                        checkStrictly: true,
                                        emitPath: true,
                                        value: 'id',
                                        label: 'name',
                                        children: 'pojoList'
                                    }"
                                    :show-all-levels="false"
                                >
                                </el-cascader>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item prop="designClassify">
                                <el-cascader
                                    placeholder="请选择款式分类"
                                    style="width: 200px"
                                    v-model="form.designClassify"
                                    clearable
                                    collapse-tags
                                    filterable
                                    :options="categoryListType"
                                    :props="{
                                        multiple: true,
                                        checkStrictly: true,
                                        emitPath: true,
                                        value: 'id',
                                        label: 'name',
                                        children: 'pojoList'
                                    }"
                                    :show-all-levels="false"
                                >
                                </el-cascader>
                            </el-form-item>
                            <el-form-item prop="styleClassify">
                                <el-cascader
                                    placeholder="请选择风格分类"
                                    style="width: 200px"
                                    v-model="form.styleClassify"
                                    clearable
                                    collapse-tags
                                    filterable
                                    :options="categoryListStyle"
                                    :props="{
                                        multiple: true,
                                        checkStrictly: true,
                                        emitPath: true,
                                        value: 'id',
                                        label: 'name',
                                        children: 'pojoList'
                                    }"
                                    :show-all-levels="false"
                                >
                                </el-cascader>
                            </el-form-item>
                        </el-row>
                    </el-form> -->
                    <grid-manager :option="gridOption"></grid-manager>
                    <section
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border: 1px solid #eee;
                            border-top: none;
                            height: 6vh;
                            padding: 0 10px;
                        "
                    >
                        <div>
                            <el-checkbox v-model="stridePage" :disabled="!form.senderInfoId" @change="selectAll"
                                >跨页全选</el-checkbox
                            >
                        </div>
                        <el-button type="success" @click="addList" :loading="loading">确认添加</el-button>
                    </section>
                </section>
            </div>
            <div class="content-show" style="width: calc(100% - 750px)">
                <div class="content-title">已添加货品列表</div>
                <section>
                    <el-form inline size="small" @submit.native.prevent>
                        <el-form-item label="搜索货号">
                            <el-input placeholder="请输入货号" v-model="form.joinGoods"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <div style="display: flex">
                                <el-button native-type="submit" @click="searchJoin">查询</el-button>
                                <div class="clearAll-box" @click="clearAll">
                                    <svg-icon class-name="size-icon" icon-class="clear" style="font-size: 16px" />
                                    一键清空
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                    <SizeGroupTable
                        ref="sizeGroupTableIns"
                        :dataList="newAddedList"
                        :columns="newAddColumns"
                        :actions="addActions"
                        :filterText="filterText"
                        :sizeInfoLabels="newAddSizeInfoLabel"
                        border
                        @actionClick="actionClick"
                        @numberChange="numberChange"
                    >
                        <template v-slot="{ row, item }">
                            <el-input
                                v-if="item.prop === 'calloutType'"
                                v-model="row[item.prop]"
                                placeholder="请输入内容"
                            ></el-input>
                        </template>
                    </SizeGroupTable>
                </section>
            </div>
        </section>
    </el-drawer>
</template>

<script>
import svgIcon from '@/components/SvgIcon/index'
import PuhuoTable from './PuhuoTable.vue'
import OptionSupplier from '../../../components/OptionSupplier/index'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import erpSelector from '@/components/ErpSelector/index'
import { addColumnObj, addActions, addSizeInfoLabel } from './tableConfig.js'

import {
    erpSubmitInvoice,
    erpQueryUnitGoods,
    erpQueryGoodsInfo,
    queryOnceAddMax,
    queryWarehouseUnitInfoList,
    queryGoodsCategoryTree,
    erpQueryInvoicesInfo,
    supplierInfoQueryList,
    negativeStockAuthority
} from '@/libs/http/api'
export default {
    name: 'returnDialogModule',
    props: {
        modalParams: {
            type: Object,
            default: () => {}
        },
        storeType: {
            type: String,
            default: ''
        }
    },
    components: {
        PuhuoTable,
        OptionSupplier,
        SizeGroupTable,
        erpSelector,
        svgIcon
    },
    data() {
        return {
            addColumnObj,
            addActions,
            addSizeInfoLabel,
            filterText: '',
            newAddedList: [],
            dialogVisible: false,
            infoDetailLoading: false,
            form: {
                goodsNo: [], //查询列表搜索
                joinGoods: '', //已添加列表搜索
                senderInfoId: this.$cache.local.get('deptId'), //发出方
                receiverInfoId: '', //接收
                description: '' //描述
                // timeClassify: '', //时间
                // designClassify: '', //款式
                // styleClassify: '' //风格
            },
            rules: {
                senderInfoId: [{ required: true, message: '请选择', trigger: 'blur' }],
                receiverInfoId: [{ required: true, message: '请选择', trigger: 'blur' }]
            },
            gridOption: {
                gridManagerName: 'ArticleNumberList',
                firstLoading: false,
                isCombSorting: true,
                supportCheckbox: true,
                supportAutoOrder: false,
                useNoTotalsMode: false,
                lineHeight: '30px',
                columnData: [
                    { key: 'goodsNo', text: '货号' },
                    { key: 'goodsName', text: '货号名称' },
                    { key: 'usableQuantity', text: '可用库存' }
                ],
                ajaxData: this.fecthList,
                dataKey: 'items',
                totalsKey: 'totalItem',
                supportAjaxPage: true,
                rowDblClick: this.rowdbClick,

                height: '74vh',
                ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--选中信息-->
                    <div class="toolbar-info checked-info"></div>
                    <!--分页描述-->
                    <div class="toolbar-info page-info"></div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`
            },
            headerLeft: [
                { key: 'goodsName', name: '货品名称', type: 'tg' },
                { key: 'goodsNo', name: '货号' },
                { key: 'retailPrice', name: '零售价' },
                { key: 'color', name: '颜色' }

                // { key: "salesPrice", name: "铺货价" },
            ],
            checkIdList: [],
            stridePage: false,
            addedList: [],
            loading: false,
            onceMax: 0,
            entrepotList: [],
            warehousetotalItem: 0,
            warehousepage: 1,
            warehouseName: '',

            categoryListTime: [],
            categoryListStyle: [],
            categoryListType: [],
            totalItem: 0,
            page: 1,
            supplierName: '',
            supplierList: [],
            isAllowNegativeStocks: ''
        }
    },
    computed: {
        newAddColumns() {
            //铺货
            if (this.invoiceType == 100011) {
                //店店调拨
                return this.addColumnObj['DDDB']
            } else {
                return this.addColumnObj['OTHER']
            }
        },
        newAddSizeInfoLabel() {
            return this.addSizeInfoLabel.map((item) => {
                if (item.prop === 'number') {
                    item.label = '退供数'
                }
                return item
            })
        }
    },
    created() {
        this.dialogVisible = true
        if (this.modalParams) {
            this.fetchWarehouseUnit(this.modalParams.receiveInfoName)
            this.fetchDetail()
        }
        this.fetchQuerytree(0)
        this.fetchQuerytree(1)
        this.fetchQuerytree(2)
    },
    mounted() {
        // 绑定enter事件
        this.enterKeyup()
        this.fetchConfig()
    },
    destroyed() {
        // 销毁enter事件
        this.enterKeyupDestroyed()
    },
    methods: {
        getIsAllowNegativeStocks() {
            negativeStockAuthority().then((res) => {
                this.isAllowNegativeStocks = res
            })
        },
        enterKey(event) {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                const checkList = GridManager.getCheckedData(this.gridOption.gridManagerName)
                if (this.stridePage || checkList.length) {
                    this.addList()
                }
            }
        },
        enterKeyupDestroyed() {
            document.removeEventListener('keyup', this.enterKey)
        },
        enterKeyup() {
            document.addEventListener('keyup', this.enterKey)
        },
        close() {
            this.dialogVisible = false
            this.$emit('close')
        },

        rowdbClick(row) {
            this.addList(row, true)
        },
        fetchDetail() {
            this.infoDetailLoading = true
            erpQueryInvoicesInfo({ id: this.modalParams.invoicesId })
                .then((data) => {
                    if (data) {
                        for (let key in data) {
                            this.form[key] = data[key]
                        }
                        this.$nextTick(() => {
                            this.addedList = data.goodsInfoList
                            this.newAddedList = this.newAddedList.concat(
                                data.goodsInfoList.map((item) => {
                                    if (this.invoiceType == 100011) {
                                        return {
                                            ...item,
                                            calloutType: item.calloutType || ''
                                        }
                                    } else {
                                        return item
                                    }
                                })
                            )
                        })
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
                .finally(() => {
                    this.infoDetailLoading = false
                })
        },
        searchList() {
            GridManager.refreshGrid(this.gridOption.gridManagerName)
        },
        fecthList(params) {
            let reqData = {
                page: params.pageData.cPage,
                pageSize: params.pageData.pSize,
                unitId: this.form.senderInfoId,
                goodsNo: this.form.goodsNo.length ? this.form.goodsNo.join(' ') : '',
                needPage: 'Y',
                unitType: '1'
            }
            let classifyArr = []
            // if (this.form.timeClassify) {
            //     let list = this.form.timeClassify.join(',')
            //     let arr = list.split(',')
            //     if (arr[0] !== '') {
            //         classifyArr = classifyArr.concat(arr)
            //     }
            // }
            // if (this.form.designClassify) {
            //     let list = this.form.designClassify.join(',')
            //     let arr = list.split(',')
            //     if (arr[0] !== '') {
            //         classifyArr = classifyArr.concat(arr)
            //     }
            // }
            // if (this.form.styleClassify) {
            //     let list = this.form.styleClassify.join(',')
            //     let arr = list.split(',')
            //     if (arr[0] !== '') {
            //         classifyArr = classifyArr.concat(arr)
            //     }
            // }
            for (var i = 0; i < classifyArr.length; i++) {
                for (var j = i + 1; j < classifyArr.length; j++) {
                    if (classifyArr[i] == classifyArr[j]) {
                        classifyArr.splice(j, 1)
                        j--
                    }
                }
            }
            if (classifyArr.length && classifyArr[0] !== '') {
                reqData['categoryIds'] = classifyArr
            }
            return erpQueryUnitGoods(reqData)
        },
        fetchWarehouseUnit(name) {
            if (this.warehouseName != name) {
                this.warehousepage = 1
            }
            this.warehouseName = name

            let reqData = {
                page: this.warehousepage,
                pageSize: '20'
            }
            if (name) {
                reqData['title'] = name
            }
            queryWarehouseUnitInfoList(reqData)
                .then((data) => {
                    if (data && data.items && data['items'].length) {
                        this.entrepotList = data.items
                        this.warehousetotalItem = data.totalItem * 1
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        searchJoin() {
            //   this.$refs.puhuotab.queryList(this.form.joinGoods);
            this.filterText = this.form.joinGoods
        },
        selectAll(e) {
            if (e) {
                this.fecthAll()
            } else {
                this.checkIdList = []
            }
        },
        fecthAll() {
            erpQueryUnitGoods({
                unitId: this.form.senderInfoId,
                unitType: '1',
                goodsNo: this.form.goodsNo.length ? this.form.goodsNo.join(' ') : '',
                needPage: 'N'
            })
                .then((data) => {
                    if (data && data.items) {
                        this.checkIdList = data.items.map((item) => {
                            return item.spuInfoId
                        })
                    }
                })
                .catch((error) => {
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        getCheckOther() {
            const checkList = GridManager.getCheckedData(this.gridOption.gridManagerName)
            this.checkIdList = checkList.map((item) => {
                return item.spuInfoId
            })
        },
        fetchConfig() {
            queryOnceAddMax({ state: '1' })
                .then((data) => {
                    this.onceMax = data.items[0].allNumber || 0
                })
                .catch((error) => {
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        addList(row, state) {
            if (!this.stridePage) {
                this.getCheckOther()
            }
            if (!state) {
                if (!this.checkIdList.length) {
                    return this.$message.error('请选择货品再添加')
                }
                if (this.checkIdList.length > Number(this.onceMax)) {
                    return this.$message.error(`一次最多添加${this.onceMax}条记录`)
                }
            }
            this.loading = true
            erpQueryGoodsInfo({
                unitId: this.form.senderInfoId,
                // spuInfoIds: this.checkIdList,
                spuInfoIds: row && row.spuInfoId ? [row.spuInfoId] : this.checkIdList,
                priceType: 'A',
                unitType: '1'
            })
                .then((data) => {
                    this.addedList = [...this.addedList, ...data]
                    this.newAddedList = this.newAddedList.concat(
                        data.map((item) => {
                            if (this.invoiceType == 100011) {
                                return {
                                    ...item,
                                    calloutType: item.calloutType || ''
                                }
                            } else {
                                return item
                            }
                        })
                    )
                    this.loading = false
                    this.stridePage = false
                    this.checkIdList = []
                    GridManager.setCheckedData(this.gridOption.gridManagerName, [])
                })
                .catch((error) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        clearAll() {
            this.addedList = []
            //   this.$refs.puhuotab.clearAll();
            this.newAddedList = []
        },

        beforePublish(type) {
            if (!this.form.receiverInfoId) {
                return this.$message.error('请选择收货方')
            }
            //   let goodsInfo = this.$refs.puhuotab.getsendData();
            let goodsInfo = this.$refs.sizeGroupTableIns.getsendData(true)
            if (!goodsInfo.length) {
                return this.$message.error('请填写退供数')
            }
            let reqData = {
                type: type,
                goodsInfo: goodsInfo,
                description: this.form.description,
                typeCode: '100021',
                senderInfoId: this.form.senderInfoId,
                receiverInfoId: this.form.receiverInfoId
            }
            if (this.modalParams && this.modalParams.invoicesId) {
                reqData['invoicesId'] = this.modalParams.invoicesId
            }
            this.publishType(reqData)
        },
        publishType(reqData) {
            erpSubmitInvoice(reqData)
                .then((data) => {
                    this.dialogVisible = false
                    this.$emit('close', true)
                    this.$message({
                        type: 'success',
                        message: '操作成功',
                        duration: 2000,
                        showClose: true
                    })
                })
                .catch((error) => {
                    this.$message({
                        type: 'error',
                        message: error,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        changeEntrepotPage(e) {
            this.warehousepage = e
            this.fetchWarehouseUnit(this.warehouseName)
        },
        fetchQuerytree(type) {
            queryGoodsCategoryTree({
                queryType: type
            })
                .then((data) => {
                    if (data && data.length) {
                        if (type == 0) {
                            this.categoryListType = this.recursiveFilter(data)
                        }
                        if (type == 1) {
                            this.categoryListStyle = this.recursiveFilter(data)
                        }
                        if (type == 2) {
                            this.categoryListTime = this.recursiveFilter(data)
                        }
                    }
                    //   if (data && data.items && data["items"].length) {
                    //       this.entrepotList = data.items;
                    //       this.warehousetotalItem = data.totalItem * 1;
                    // }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        recursiveFilter(arr) {
            const data = arr
            data.forEach((item) => {
                if (item.pojoList && item.pojoList.length) {
                    this.recursiveFilter(item.pojoList)
                } else {
                    delete item.pojoList
                }
            })
            return data
        },
        fetchSupplier(name) {
            if (this.supplierName != name) {
                this.page = 1
            }
            if (name == '') {
                this.purchaseInvoicesIdStr = []
                this.purchaseList = []
            }
            this.supplierName = name
            let reqData = {
                name: name,
                page: this.page + '',
                pageSize: 50,
                delFlag: '0',
                type: 2
            }
            supplierInfoQueryList(reqData)
                .then((data) => {
                    this.supplierList = data.items || []
                    this.totalItem = data.totalItem - 0 || 0
                })
                .catch((error) => {
                    this.$message.error(error)
                })
        },
        changeSupplier(e) {
            this.addedList = []
            this.newAddedList = []
        },
        changePage(e) {
            this.page = e
            this.fetchSupplier(this.supplierName)
        },
        actionClick(event, row, rIndex) {
            if (event === 'del') {
                this.$confirm('是否删除该条记录', '提示')
                    .then(() => {
                        this.newAddedList = this.newAddedList.filter((item, index) => item.soleLabel !== row.soleLabel)
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500
                        })
                    })
                    .catch(() => {})
            }
        },
        numberChange(row, _row, _value, _key) {
            //   if (this.isAllowNegativeStocks === "N") {
            //     if (_value > _row.usableQuantity) {
            //       this.$set(_row, _key, 0);
            //       return this.$message.error("当前货品不允许负库存啦");
            //     }
            //   }
        }
        // resetForm(formName) {
        //     this.$refs[formName].resetFields()
        // }
    }
}
</script>

<style lang="scss" scoped>
.addTable .add-table-top {
    margin-bottom: 18px;
}
.dialog-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
        font-size: 18px;
        color: #333;
        font-weight: bold;
    }
    div i {
        cursor: pointer;
        font-size: 22px;
        margin-left: 20px;
        float: right;
        margin-top: 8px;
    }
}
.content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .content-title {
        display: flex;
        font-size: 18px;
        color: #333;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .content-form {
        width: 250px;
        padding: 0 20px;
        box-sizing: border-box;
    }
    .content-add {
        width: 550px;
    }
    .content-show {
        width: auto;
        margin-left: 20px;
        margin-right: 25px;
    }
}
.sub-box {
    color: black;
    margin: 10px 15px;
    padding: 10px 50px;
    background: #c6e7ff;
    display: flex;
    justify-content: center;
    align-content: center;
    cursor: pointer;
    border-radius: 3px;
    img {
        width: 20px;
        margin-right: 10px;
    }
}
.clearAll-box {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    background: #c6e7ff;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
    img {
        width: 15px;
        margin-right: 7px;
    }
}
.size-icon {
    margin-right: 5px;
    margin-top: 3px;
}
</style>
