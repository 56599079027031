<template>
  <div>
    <el-option
      v-for="(item) in supplierList"
      :key="item.id"
      :value="item.id"
      :label="item.simpleName ? item.simpleName : item.name"
      style="height:90px; display: flex; align-items: center;position: relative;padding: 0 10px;border-bottom: 1px solid #eee"
    >
      <div style="display: flex; align-items: center;">
        <div
          style="
            display: flex;
            flex-direction: column;
            color: #999999;
          "
        >
          <div style="display: flex; align-items: center">
            供应商名称：
            <span style="margin-right: 20px; font-size: 18px; color: #333333">{{
              item.simpleName
            }}</span>
            编号：<span style="font-size: 15px; color: #333333">{{ item.code }}</span>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              margin-top: -10px;
              font-weight: 400;
            "
          >
            供应商全称：<span>{{item.name}}</span>
          </div>
          <div style="display:flex;align-items:center;height:34px;margin-top: -10px;" v-if="item.typeItemList && item.typeItemList.length>=1">
            <el-tag
              v-for="(ele,index) in item.typeItemList"
              :key="index"
              type=""
              effect="plain"
              :style="{marginLeft: index>0?'5px':'',padding:'0px 5px',height:'20px',lineHeight:'20px'}">
              {{ele.type==1?'加工':ele.type==2?'成品':ele.type==3?'面料':ele.type==4?'辅料':ele.type== 5?'吊牌':ele.type == 6?'行政物料': ele.type == 7?'广告物料':'工服物料'}}
            </el-tag>
          </div>
        </div>
      </div>
    </el-option>
    <div style="height:30px;background:#FFFFFF"></div>
    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        position: absolute;
        bottom: 0;
        background: #FFFFFF;
        width:100%
      "
    >
      <el-pagination
        :current-page="page"
        :page-size="50"
        layout="total, prev, pager, next"
        :total="totalItem"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionSupplier",
  props: ["supplierList", "totalItem","page"],
  data() {
    return {
      // page: 1,
    };
  },
  methods: {
    currentChange(val) {
      this.$emit("fatherMethod", val);
    },
  },
};
</script>
<style>
</style>