<!-- 这里只有店铺退仓详情 -->
<template>
    <el-drawer size="90%" :visible.sync="dialogVisible" :wrapperClosable="false" @close="close" :show-close="false">
        <section slot="title" class="dialog-top">
            <p>{{ '单据详情' }}</p>
            <div style="display: flex">
                <div
                    class="sub-box"
                    @click="CheckAcceptModule = true"
                    v-if="type == 2 && [4, 5].includes(+modalParams.state)"
                >
                    <svg-icon class-name="size-icon" icon-class="picking" /> 开始拣货
                </div>
                <i class="el-icon-close" @click="dialogVisible = false"></i>
            </div>
        </section>
        <section v-loading="loading">
            <section class="step">
                <!-- <el-steps :active="scheduleState" finish-status="finish" process-status="wait" align-center>
                    <el-step
                        v-for="(item, index) in scheduleInfoList"
                        :key="item.sort"
                        :title="item.invoicesName"
                        :status="item.hasNowStep === 'N' ? 'wait' : ''"
                    >
                        <div slot="description" v-if="item.hasNowStep === 'Y'">
                            <div>{{ item.startTime }}</div>
                            <div v-if="item.consumingTime && index > 0" style="color: #999">
                                耗时：{{ item.consumingTime }}
                            </div>
                            <span v-if="item.timeout" style="color: red">超时：{{ item.overTime }}</span>
                        </div>
                    </el-step>
                </el-steps> -->
                <Timeline :timeList="scheduleInfoList"></Timeline>
            </section>
            <section class="section-card">
                <h3 class="section-title">
                    <span></span>
                    <div>单据信息</div>
                </h3>
                <div class="section-value" v-if="invoicesData">
                    <el-descriptions :column="4" size="small" border style="width: 100%">
                        <el-descriptions-item>
                            <template slot="label"> 创建人 </template>
                            {{ invoicesData.createUserName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 发货方 </template>
                            {{ invoicesData.marketName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 收货方 </template>
                            {{ invoicesData.warehouseName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 退货数量 </template>
                            {{ invoicesData.totalNum }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 出货数量 </template>
                            {{ invoicesData.outNum }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 入库数量 </template>
                            {{ invoicesData.inNum }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 出货差异数 </template>
                            {{ invoicesData.outNumDifference || 0 }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 入库差异数 </template>
                            <!-- {{ invoicesData.storageDifferenceCount }} -->
                            {{ invoicesData.inNumDifference || 0 }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 创建时间 </template>
                            {{ invoicesData.createTime }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 更新时间 </template>
                            {{ invoicesData.updateTime }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 最后修改人 </template>
                            {{ invoicesData.updateUserName }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 总耗时 </template>
                            {{ invoicesData.totalTime }}
                        </el-descriptions-item>
                        <el-descriptions-item v-if="invoicesData.goodsPrincipal && drawerType != 2">
                            <template slot="label"> 商品负责人 </template>
                            {{ invoicesData.goodsPrincipal }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 备注 </template>
                            {{ invoicesData.remark }}
                        </el-descriptions-item>
                    </el-descriptions>
                </div>
                <div class="detail-state" v-if="invoicesData">
                    <img v-if="invoicesData.state == 0" src="@/assets/images/erp/void.png" />
                    <img v-if="invoicesData.state == 4" src="@/assets/images/erp/sended.png" />
                    <img v-if="invoicesData.state == 5" src="@/assets/images/erp/orderpack.png" />
                    <img v-if="invoicesData.state == 6" src="@/assets/images/erp/orderpacked.png" />
                    <img v-if="invoicesData.state == 7" src="@/assets/images/erp/packing.png" />
                    <img v-if="invoicesData.state == 8" src="@/assets/images/erp/packed.png" />
                    <img v-if="invoicesData.state == 9" src="@/assets/images/erp/outed.png" />
                    <img v-if="invoicesData.state == 10" src="@/assets/images/erp/checked.png" />
                    <img v-if="invoicesData.state == 14" src="@/assets/images/erp/joined.png" />
                </div>
            </section>

            <section class="section-card">
                <h3 class="section-title">
                    <span></span>
                    <div>商品列表</div>
                </h3>
                <div>
                    <SizeGroupTable
                        ref="sizeGroupTableIns"
                        :dataList="dataList"
                        :columns="newColumns"
                        :isEmpty="false"
                        :sizeInfoLabels="newSizeInfoLabels"
                        :footerOptions="newFooterOptions"
                        border
                    >
                        <template v-slot="{ row, item }">
                            <el-popover v-if="item.prop === 'goodsNo'" placement="right-start" trigger="click">
                                <el-image
                                    v-if="row.imageList && row.imageList.length"
                                    style="width: 400px; height: 400px"
                                    :src="row.imageList[0]"
                                    :preview-src-list="row.imageList"
                                >
                                </el-image>
                                <span slot="reference" class="text-link">{{ row.goodsNo }} </span>
                            </el-popover>
                        </template>
                    </SizeGroupTable>
                </div>
            </section>

            <section class="section-card" v-if="logisticsList.length">
                <h3 class="section-title">
                    <span></span>
                    <div>发货信息</div>
                </h3>
                <div style="margin: 20px">
                    <el-table
                        border
                        :data="logisticsList"
                        :header-cell-style="{
                            background: '#F3F4F7',
                            color: '#555',
                            padding: '5px 0'
                        }"
                        :cell-style="{ padding: '5px 0' }"
                        :span-method="arraySpanMethod"
                    >
                        <el-table-column prop="invoicesNo" label="单号" align="center"> </el-table-column>
                        <el-table-column v-if="type != 2" prop="packageSn" label="包裹号" align="center">
                            <template slot-scope="scope">
                                <span
                                    @click="packageInfo(scope.row)"
                                    style="text-decoration: underline; color: #1890ff; cursor: pointer"
                                    >{{ scope.row.packageSn }}</span
                                >
                            </template>
                        </el-table-column>
                        <el-table-column v-if="type != 2" prop="goodsNum" label="货品数量" align="center">
                        </el-table-column>
                        <el-table-column v-if="type == 2" prop="goodsNum" label="货品数量" align="center">
                            <template slot-scope="scope">
                                <span
                                    @click="packageInfo(scope.row)"
                                    style="text-decoration: underline; color: #1890ff; cursor: pointer"
                                    >{{ scope.row.goodsNum }}</span
                                >
                            </template>
                        </el-table-column>
                        <el-table-column prop="senderInfoName" label="发货方" align="center"> </el-table-column>
                        <el-table-column prop="receiveInfoName" label="收货方" align="center"> </el-table-column>
                        <el-table-column prop="logisticsName" label="物流公司" align="center"> </el-table-column>
                        <el-table-column prop="expressNo" label="快递单号" align="center"> </el-table-column>
                        <el-table-column prop="state" label="状态" align="center">
<!--                            <template slot-scope="scope">-->
<!--                                <el-tag effect="dark" :type="expressState[scope.row.state].type">-->
<!--                                    {{ expressState[scope.row.state].label }}-->
<!--                                </el-tag>-->
<!--                            </template>-->
                          <template slot-scope="scope">
                            <el-tag effect="dark"
                                    v-if="scope.row.logisticsName.indexOf('京东') !== -1">
                              {{ scope.row.jdStateDesc ? scope.row.jdStateDesc : '待更新' }}
                            </el-tag>
                            <el-tag effect="dark" v-else :type="expressState[scope.row.state].type">
                              {{ scope.row.stateDesc }}
                            </el-tag>
                          </template>
                        </el-table-column>
                        <el-table-column prop="shipmentTime" label="发货时间" align="center"> </el-table-column>
                        <el-table-column prop="signTime" label="签收时间" align="center"> </el-table-column>
                        <el-table-column fixed="right" label="操作" width="150px" align="center">
                            <template slot-scope="scope" style="display: flex">
                                <div style="display: flex">
                                    <el-button @click="handleClick(scope.row)" type="text" size="small">
                                        <span style="text-decoration: underline">物流轨迹</span>
                                    </el-button>

                                    <el-button
                                        @click="amendClick(scope.row)"
                                        type="text"
                                        size="small"
                                        v-if="scope.row.expressNo && [1, 2].includes(+scope.row.state)"
                                    >
                                        <span style="text-decoration: underline">修改信息</span>
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section>
            <TrackDialog v-if="disloagModule" :expressNo="expressNo" @close="disloagModule = false"></TrackDialog>
            <CheckAcceptDrawer
                v-if="type == 2 && CheckAcceptModule"
                @close="checkClose"
                :modalParams="modalParams"
                :type="type"
                snType="2"
            ></CheckAcceptDrawer>
            <printDeliveryOrder
                v-if="printDialog"
                @close="printDialog = false"
                type="2"
                :modalParams="rowData"
                :isPrint="false"
            ></printDeliveryOrder>
            <entryCourierInfo
                v-if="entryCourierDialog"
                :modalParams="modalParams"
                :details="details"
                @close="entryCourier"
                type="TC"
            >
            </entryCourierInfo>
            <el-dialog
                :append-to-body="true"
                title="请选择包裹号"
                :visible.sync="packDialogShow"
                width="20%"
                :before-close="packClose"
                custom-class="pack-dialog"
            >
                <el-select v-model="packageId" size="small" placeholder="请选择">
                    <el-option
                        v-for="item in packOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="item.state == 2"
                    >
                    </el-option>
                </el-select>
                <el-button type="primary" size="small" @click="packConfirm">确 定</el-button>
            </el-dialog>
        </section>
    </el-drawer>
</template>

<script>
import Timeline from '@/components/Timeline/index.vue'

import svgIcon from '@/components/SvgIcon/index'
import TrackDialog from '../../components/trackDialog.vue'
import { erpPackageRecallJdExpress, queryIntoStockPackageList } from '@/libs/http/api.js'
import { erp2ErpMarketReturnQueryDetail, erp2OperatingInterceptExpress } from '@/libs/http/modules/erp.js'
import CheckAcceptDrawer from './CheckAcceptDrawer.vue'
import printDeliveryOrder from '../../components/printDeliveryOrder.vue'
import entryCourierInfo from '../../components/entryCourierInfo.vue'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import { columnObj, sizeInfoLabel, footerObj } from './tableConfig.js'

export default {
    name: 'ShopDetails',
    props: {
        modalParams: {
            type: Object,
            default: () => null
        },

        type: {
            type: String,
            default: '1'
        }
    },
    components: {
        TrackDialog,
        CheckAcceptDrawer,
        printDeliveryOrder,
        entryCourierInfo,
        SizeGroupTable,
        svgIcon,
        Timeline
    },
    data() {
        return {
            columnObj,
            sizeInfoLabel,
            footerObj,
            dialogVisible: false,
            CheckAcceptModule: false,
            entryCourierDialog: false,
            drawerType: 1,
            details: [],
            headerLeft: [
                { key: 'goodsName', name: '货品名称', type: 'ph' },
                { key: 'goodsNo', name: '货号' },
                { key: 'retailPrice', name: '零售价' }
                // {key: 'salesPrice', name: '铺货价'}
            ],
            dataList: [],
            invoicesData: null,
            invoicesSnType: '',
            logisticsList: [],
            courierInfoDialog: false,
            scheduleInfoList: [],
            scheduleState: 1,
            disloagModule: false,
            rowData: null,
            printDialog: false,
            expressNo: null,
            packDialogShow: false,
            loading: false,
            packageId: '',
            packOptions: [],
            drawerModalParams: {},
            expressState: {
                0: {
                    type: 'warning',
                    label: '未揽收'
                },
                1: {
                    type: 'warning',
                    label: '待发货'
                },
                2: {
                    type: 'info',
                    label: '运输中'
                },
                3: {
                    type: '',
                    label: '配送中'
                },
                4: {
                    type: 'success',
                    label: '已妥投'
                }
            }
        }
    },
    computed: {
        newSizeInfoLabels() {
            return this.formatLabels(this.sizeInfoLabel['TC'])
        },
        newFooterOptions() {
            return this.footerObj['TC']
        },
        newColumns() {
            return this.columnObj['TC']
        }
    },
    mounted() {
        this.fetchDetail()
    },
    methods: {
        formatLabels(arr) {
            return arr.map((item, index) => {
                if (index === 0) {
                    item.disabled = false
                    item.value = this.scheduleState >= 1 ? true : false
                } else if ([1, 2].includes(index)) {
                    item.disabled = this.scheduleState >= 2 ? false : true
                    item.value = this.scheduleState >= 2 ? true : false
                } else if ([3, 4].includes(index)) {
                    item.disabled = this.scheduleState > 3 ? false : true
                    item.value = this.scheduleState > 3 ? true : false
                }
                return item
            })
        },
        checkClose(e) {
            this.CheckAcceptModule = false
            if (e) {
                this.fetchDetail()
                this.modalParams.state = 112
            }
            this.$parent.refetch()
        },
        packageInfo(row) {
            this.printDialog = true
            row.invoicesId = this.modalParams.invoicesId
            this.rowData = row
        },
        close() {
            this.dialogVisible = false
            this.$emit('close')
        },
        fetchDetail() {
            this.loading = true
            this.dialogVisible = true
            erp2ErpMarketReturnQueryDetail({
                id: this.modalParams.id
            })
                .then((data) => {
                    this.loading = false
                    this.invoicesData = data.invoicesInfo
                    this.invoicesSnType = data.invoicesInfo.invoicesSn.slice(0, 2) || 'PH'
                    this.$nextTick(() => {
                        this.dataList = data.goodsInfoList || []
                    })
                    this.logisticsList = data.logisticsList || []
                    this.scheduleInfoList = data.scheduleInfoList || []
                    this.scheduleState = Number(data.scheduleState)
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        getSpanArr(data, spanKey) {
            let spanArr = [] //值
            let pos = '' //下标
            for (let i = 0; i < data.length; i++) {
                if (i === 0) {
                    spanArr.push(1)
                    pos = 0
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i][spanKey] === data[i - 1][spanKey]) {
                        spanArr[pos] += 1
                        spanArr.push(0)
                    } else {
                        spanArr.push(1)
                        pos = i
                    }
                }
            }
            return spanArr
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            let currindex = [0, 3, 4, 5, 6, 7, 8, 9, 10]
            if (currindex.indexOf(columnIndex) != -1) {
                let spanArr = this.getSpanArr(this.logisticsList, row.invoicesNo)
                const _row = spanArr[rowIndex]
                const _col = _row > 0 ? 1 : 0
                return {
                    rowspan: _row,
                    colspan: _col
                }
            }
        },
        handleClick(row) {
            row.invoicesId = this.modalParams.invoicesId
            this.expressNo = row
            this.disloagModule = true
        },

        amendClick(row) {
            if (row.type == '1') {
                this.$confirm(`是否取消快递单`, {
                    type: 'warning'
                })
                    .then(() => {
                        erp2OperatingInterceptExpress({
                            id: row.packageId
                        })
                            .then((data) => {
                                this.$message.success('取消成功')
                                this.$nextTick(() => {
                                    this.fetchDetail()
                                })
                                this.entryCourierDialog = true
                                this.details = { ...row, packageId: row.invoicesLogisticsInfoId }
                            })
                            .catch((err) => {
                                this.$message({
                                    type: 'error',
                                    message: err,
                                    duration: 2000,
                                    showClose: true
                                })
                            })
                    })
                    .catch(() => {})
            }
            if (row.type == '2') {
                this.entryCourierDialog = true
                this.details = { ...row, packageId: row.invoicesLogisticsInfoId }
            }
        },
        entryCourier(refetch) {
            this.entryCourierDialog = false
            if (refetch) {
                this.fetchDetail()
            }
        },

        checkGet() {
            if (this.type == 1) {
                this.getPackOptions()
            } else {
                this.CheckAcceptModule = true
            }
        },
        packClose() {
            this.packDialogShow = false
            this.packageId = ''
        },
        packConfirm() {
            if (!this.packageId) {
                this.$message.error('请选择包裹号')
                return
            }
            this.CheckAcceptModule = true
            this.drawerModalParams = { packageId: this.packageId }
            this.packClose()
        },
        getPackOptions() {
            let params = {
                invoicesId: this.modalParams.invoicesId,
                receiverType: '1'
            }
            queryIntoStockPackageList(params)
                .then((res) => {
                    if (res && res.items) {
                        if (res.items.length === 1) {
                            this.CheckAcceptModule = true
                            this.drawerModalParams = { packageId: res.items[0].packageId }
                            this.packClose()
                        } else if (res.items.length > 1) {
                            this.packOptions = res.items.map((i) => ({
                                label: i.packageNo,
                                value: i.packageId,
                                state: i.state
                            }))
                            this.packDialogShow = true
                        }
                    }
                })
                .catch((rej) => {
                    this.$message.error(rej)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 18px;
        color: #333;
    }

    div i {
        cursor: pointer;
        font-size: 22px;
        margin-left: 20px;
        float: right;
        margin-top: 8px;
    }

    .sub-box {
        color: black;
        margin: 10px 15px;
        padding: 10px 50px;
        background: #c6e7ff;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        border-radius: 3px;

        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}

.step {
    width: 100%;
    padding-bottom: 50px;
}

.section-card {
    position: relative;
    margin-bottom: 30px;
    .text-link {
        color: #1890ff;
        cursor: pointer;
    }

    .section-title {
        font-size: 18px;
        color: #333;
        font-weight: normal;
        display: flex;
        align-items: center;

        span {
            margin-top: -2px;
            width: 4px;
            height: 18px;
            background: #067cf2;
            margin-right: 16px;
            border-radius: 0 2px 2px 0;
        }
    }

    > div {
        margin: 20px;
    }

    .detail-state {
        position: absolute;
        right: 20px;
        top: -50px;

        img {
            width: 80px;
        }
    }

    .section-value {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;

        > div {
            width: calc(100% / 3);
            margin-bottom: 10px;
        }
    }
}

.size-icon {
    margin-right: 5px;
    margin-top: 3px;
}
</style>
