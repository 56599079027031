<template>
    <el-dialog
        width="1000px"
        append-to-body
        :title="'录入快递信息'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        @close="close"
    >
        <div class="form-card" v-loading="loading">
            <section class="carList" :key="show">
                <div class="carTitle">
                    单据号<span style="font-size: 22px; color: #3996f4; margin-left: 20px">{{ modalParams.sn }}</span>
                </div>
                <div class="carbut" style="display: flex; width: 100%; padding: 30px">
                    <div class="carlef">
                        <span class="cargoing-left" style="background-color: #ebf7ff; color: #1784f2">发出方</span>
                        <span class="cargoing-right">
                            <p style="font-size: 20px; font-weight: bold; color: #000">
                                {{ setList.senderName }}
                                <!-- <span class="clickxiu" @click="changeDialog(setList.senderName,'senderName')"> 修改</span> -->
                            </p>
                            <p style="font-size: 16px; font-weight: bold; color: #000">
                                {{ setList.senderMobile }}
                                <!-- <span class="clickxiu" @click="changeDialog(setList.senderMobile,'senderMobile')"> 修改</span> -->
                            </p>
                            <span> {{ setList.senderAddress }}</span>
                        </span>
                    </div>

                    <div class="carlef">
                        <span class="cargoing-left" style="background-color: #ffedeb; color: #f86175">收货方</span>
                        <span class="cargoing-right">
                            <p style="font-size: 20px; font-weight: bold; color: #000">
                                {{ setList.receiveName }}
                                <span class="clickxiu" @click="changeDialog(setList.receiveName, 'receiveName')">
                                    修改</span
                                >
                            </p>
                            <p style="font-size: 16px; font-weight: bold; color: #000">
                                {{ setList.receiveTel }}
                                <span class="clickxiu" @click="changeDialog(setList.receiveTel, 'receiveTel')">
                                    修改</span
                                >
                            </p>
                            <span> {{ setList.receiveAddress }} </span>
                        </span>
                    </div>
                </div>
            </section>
            <div style="font-size: 18px; color: #333; margin-bottom: 20px; margin-top: 10px">
                <span
                    >选择录入方式：
                    <el-radio-group v-model="radio" @change="changeRadio">
                        <el-radio :label="2" border>自动京东下单</el-radio>
                        <el-radio v-if="isHands === 'Y'" :label="1" border>手动下单</el-radio>
                        <el-radio v-if="allowNoExpress == 'Y'" :label="3" border>免快递出货</el-radio>
                    </el-radio-group>
                </span>
            </div>
            <section v-if="radio == 1">
                <div class="search-card">
                    <div class="search">
                        <i
                            class="iconfont icon-saoma"
                            style="font-size: 24px"
                            :style="{ color: isInput ? '#C56209' : '#067CF2' }"
                        ></i>
                        <div class="search-line" :style="{ background: isInput ? '#C56209' : '#067CF2' }"></div>
                        <input
                            type="text"
                            ref="searchCode"
                            placeholder="请扫描或输入快递单号"
                            v-model="form.expressNo"
                            @focus="isInput = true"
                            @blur="isInput = false"
                            v-focusInput="1"
                            @keyup.enter="keyup"
                        />
                        <div class="search-btn">确定</div>
                    </div>
                </div>
                <el-form ref="form" :model="form" label-position="right" style="width: 100%" label-width="auto">
                    <el-form-item label="快递公司" prop="logisticsCompany">
                        <el-select
                            v-model="form.logisticsCompany"
                            clearable
                            placeholder="请选择"
                            style="width: 100%"
                            filterable
                            @change="logisticsCompanyChange"
                        >
                            <el-option v-for="item in CompanyList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="现付/月结" prop="monthPay">
                        <el-select v-model="form.monthPay" clearable placeholder="请选择" style="width: 100%">
                            <el-option
                                v-for="item in saleQualityList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-row :gutter="24" v-if="form.monthPay == 2">
                        <el-col :span="24">
                            <el-form-item label="月结账号" prop="monthCode">
                                <el-select
                                    v-model="form.monthCode"
                                    clearable
                                    placeholder="请选择"
                                    style="width: 100%"
                                    filterable
                                >
                                    <el-option
                                        v-for="item in monthCodeList"
                                        :key="item.id"
                                        :label="item.monthlyAccount"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </section>
            <section v-else style="width: 650px">
              <div v-if="radio === 2">
                <div style="margin-bottom: 10px;">
                  预估重量：
                  <span>{{ weight || '0' }}公斤</span>
                  <span>（根据货品资料克重计算）</span>
                </div>
                <span style="border-radius: 4px;background: rgb(255,247,230);padding: 2px 5px;color: rgb(212,107,8);">
                  {{weight > 30 ? '当前重量>30公斤，将下单京东快运' : '当前重量<30公斤，将下京东快递'}}
                </span>
              </div>
            </section>
        </div>
        <div slot="footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" :loading="loading" @click="beforeSubmit">确 定</el-button>
        </div>
        <el-dialog
            :append-to-body="true"
            :title="titleName"
            :visible.sync="packDialogShow"
            width="20%"
            :before-close="packClose"
            custom-class="pack-dialog"
        >
            <div style='width: 300px; display: flex;justify-content: space-around'>
                <el-input v-model="setListItem" width="100%" :placeholder="placeholderValue"></el-input>
                <el-button type="primary" size="small" @click="packConfirm" style='margin-left: 20px'>确 定</el-button>
            </div>

        </el-dialog>
    </el-dialog>
</template>
<script>
import {
    erpPackageCallJdExpress,
    erpPackageBeforeCallJdExpress,
    logisticsCompanyList,
    queryMonthlyAccountInfoByLogistics
} from '@/libs/http/api.js'
import {
  erp2OperatingShipment,
  erp2OperatingExpressInfo,
  operatingUpdateExpress,
  queryNegativeManualExpress, getAllotOrderWeight, getReturnOrderWeight
} from '@/libs/http/modules/erp.js'
export default {
    props: {
        modalParams: {
            type: Object,
            default: () => {}
        },
        details: {//详情才需要---用来区分详情修改还是第一次填写
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dialogVisible: false,
            packDialogShow: false,
            loading: false,
            setListItem: '',
            contentType: '',
            radio: 2,
            flag: true,
            show: '1',
            setList: {},
            form: {
                expressNo: '',
                monthPay: '',
                monthCode: '',
                logisticsCompany: ''
            },
            saleQualityList: [
                { value: '1', label: '现付' },
                { value: '2', label: '月结' }
            ],
            rules: {
                logisticsCompany: [
                    {
                        required: true,
                        whitespace: true,
                        message: '请选择快递公司',
                        trigger: 'blur'
                    }
                ]
            },
            isInput: false,
            CompanyList: [],
            monthCodeList: [],
            titleName: '',
            placeholderValue: '',
            ldopWaybillReceiveReq:'',
            detaltype: true,
            allowNoExpress: 'Y',
            isHands: '',
            weight: null
        }
    },
    created() {
        this.dialogVisible = true
        if (this.details) {
            this.form.expressNo = this.details.expressNo
            if (this.details.paymentType) {
                this.form.monthPay = this.details.paymentType
                if (this.form.monthPay==2) {
                    this.logisticsCompanyChange(this.details.logisticsId)
                }
            }
            if (this.details.logisticsId) {
                this.form.logisticsCompany = this.details.logisticsId
            }
            if (this.details.paymentType == 2) {
                this.form.monthCode = this.details.monthlyAccount * 1
            }
        }
        this.ShopDetails()
        this.fetchCompanyList()
      this.queryHands()

      if(this.modalParams && this.modalParams.id){
        this.getWeight()
      }
        // this.automaticOrder()
    },
    methods: {
      getWeight(){
            if (this.type == 'DB') {
                getAllotOrderWeight({
                    id: this.modalParams.id
                }).then(res => {
                    if (res) {
                        this.weight = (res / 1000).toFixed(2)
                    }
                })
                if (this.weight) return
            }
            if (this.type == 'TC') {
                getReturnOrderWeight({
                    id: this.modalParams.id
                }).then(res => {
                    if (res) {
                        this.weight = (res / 1000).toFixed(2)
                    }
                })
            }
      },
      changeRadio(e){
        if(e === 1){
          this.$nextTick(() => {
            this.$refs.searchCode.focus()
          })
        }
      },
      queryHands(){
        queryNegativeManualExpress().then(res=>{
          this.isHands = res
        }).catch(error=>{})
      },
        ShopDetails() {
            this.loading = true
            let reqData = {
                typeCode: this.modalParams.typeCode,
                orderId: this.modalParams.id
            }
            erp2OperatingExpressInfo(reqData)
                .then((data) => {
                    this.loading = false
                    for (let key in data) {
                        if (key) {
                            this.setList[key] = data[key]
                        }
                    }
                    this.allowNoExpress = data.allowNoExpress
                    this.ldopWaybillReceiveReq=data.ldopWaybillReceiveReq||''
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        keyup() {
            let scanTip = new Audio(require('@/assets/audio/scan_tip.mp3'))
            scanTip.play()
        },
        close() {
            this.dialogVisible = false
            this.$emit('close')
        },
        changeDialog(content, type) {
            this.contentType = type
            this.setListItem = content
            this.packDialogShow = true
            if (type == 'receiveName') {
                this.titleName = '请修改收件人姓名'
                this.placeholderValue = '请输入收件人姓名'
            } else {
                this.titleName = '请修改收件人手机号'
                this.placeholderValue = '请输入收件人手机号'
            }
        },
        packConfirm() {
            this.$set(this.setList, `${this.contentType}`, this.setListItem)
            this.$set(this.ldopWaybillReceiveReq, `${this.contentType}`, this.setListItem)
            this.packDialogShow = false
        },
        packClose() {
            this.packDialogShow = false
        },
        beforeSubmit() {
            if (this.radio == 1) {
                if (!this.form.expressNo) {
                    return this.$message.error('请填写快递单号')
                }
                if (!this.form.monthPay) {
                    return this.$message.error('请选择是现付/月结')
                }
                if (this.form.monthPay == 2 && !this.form.monthCode) {
                    return this.$message.error('请填写月结账号')
                }}
                this.details?this.compileUpdate() :this.sureUpdate()
                // this.sureUpdate()
            //  else if (this.radio == 2) {
            //     // this.jdSubmite()
            //     this.sureUpdate()
            // } else if (this.radio == 3) {
            //     this.sureUpdate()
            // }
        },
        // 修改快递单号
        compileUpdate() {
            let reqData = {
                packageId:this.details.packageId
            }
            if (this.radio == 3) {
                reqData.type = '1'
            } else if(this.radio == 1) {
                reqData.type = '0'
            }else {
                reqData.type = '2'
            }
            if (this.form.expressNo && this.radio== 1) {
                reqData.expressNo = this.form.expressNo
            }
            if (this.form.monthPay && this.radio== 1) {
                reqData.paymentType = this.form.monthPay
            }
            if (this.form.monthCode && this.form.monthPay == 2 && this.radio== 1) {
                reqData.monthlyAccount = this.form.monthCode
            }
            if (this.form.logisticsCompany && this.radio== 1) {
                reqData['logisticsCompany'] = this.form.logisticsCompany
            }
            this.loading = true
            operatingUpdateExpress(reqData)
                .then((data) => {
                    this.$message.success('操作成功')
                    this.dialogVisible = false
                    this.$emit('close', true)
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })

        },
        sureUpdate() {
            let reqData = {
                typeCode: this.modalParams.typeCode,
                invoicesId: this.modalParams.invoicesId
            }
            if (this.radio == 3) {
                reqData.type = '1'
            } else if(this.radio == 1) {
                reqData.type = '0'
            }else {
                reqData.type = '2'
            }
            if (this.modalParams.packageSn && this.radio== 1) {
                reqData.packageSn = this.modalParams.packageSn
            }
            if (this.form.expressNo && this.radio== 1) {
                reqData.expressNo = this.form.expressNo
            }
            if (this.form.monthPay && this.radio== 1) {
                reqData.paymentType = this.form.monthPay
            }
            if (this.form.monthCode && this.form.monthPay == 2 && this.radio== 1) {
                reqData.monthlyAccount = this.form.monthCode
            }
            if (this.form.logisticsCompany && this.radio== 1) {
                reqData['logisticsCompany'] = this.form.logisticsCompany
            }
            this.loading = true
            erp2OperatingShipment(reqData)
                .then((data) => {
                    this.$message.success('操作成功')
                    this.dialogVisible = false
                    this.$emit('close', true)
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        jdSubmite() {
            this.loading = true
            let reqData = {}
            for (let key in this.setList) {
                if (key && key != 'orderId' && key != 'receiveTel') {
                    reqData[key] = this.setList[key]
                }
            }
            if (this.details) {
                reqData.saveType = '2'
            } else {
                reqData.saveType = '1'
            }
            if (!this.setList.receiveTel) {
                this.$message({
                    type: 'error',
                    message: '收件人电话号码不能为空',
                    duration: 2000,
                    showClose: true
                })
                this.loading = false
                return
            } else {
                reqData.receiveTel = this.setList.receiveTel
            }
            reqData.orderId = this.modalParams.invoicesId

            erpPackageCallJdExpress(reqData)
                .then((data) => {
                    this.$message.success('操作成功')
                    this.dialogVisible = false
                    this.$emit('close', true)
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        automaticOrder() {
            this.loading = true
            erpPackageBeforeCallJdExpress({
                orderId: this.modalParams.invoicesId
            })
                .then((data) => {
                    if (data) {
                        for (let key in data) {
                            if (key) {
                                this.setList[key] = data[key]
                            }
                        }
                        this.show = this.show + 1
                        this.loading = false
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                    this.loading = false
                })
            this.loading = false
        },

        fetchCompanyList() {
            logisticsCompanyList({
                page: 1,
                pageSize: 100,
                delState: 0
            })
                .then((data) => {
                    this.CompanyList = data.items || []
                    if (this.details && this.detaltype && this.details.paymentType == 2) {
                        this.logisticsCompanyChange(this.details?.logisticsId)
                        this.detaltype = false
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        logisticsCompanyChange(e) {
            if (e != this.details?.logisticsId) {
                this.form.monthCode = ''
                this.monthCodeList = []
            }
            let isMonth = false
            this.CompanyList.forEach((item) => {
                if (item.id == e && item.monthlyStatement == 'Y') {
                    return (isMonth = true)
                }
            })
            if (isMonth) {
                this.form.monthPay = '2'
                queryMonthlyAccountInfoByLogistics({
                    logisticsId: e,
                    onlyQueryBinding: 'Y'
                })
                    .then((data) => {
                        if (data.length) {
                            this.monthCodeList = data
                            data.forEach((item) => {
                                if (item.isDefaultAccount == 'Y') {
                                    this.form.monthCode = item.id
                                }
                            })
                        }
                    })
                    .catch((err) => {
                        this.$message({
                            type: 'error',
                            message: err,
                            duration: 2000,
                            showClose: true
                        })
                    })
            } else {
                this.form.monthPay = '1'
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.carList {
    width: 100%;
    border: 1px solid #edeff4;
    .carTitle {
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding-left: 10px;
        background-color: #f9fafc;
        padding: 0px 20px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
    }
    .carbut {
        .carlef {
            flex: 1;
            display: flex;
            margin-right: 20px;
            box-sizing: border-box;
            .cargoing-left {
                width: 100px;
                height: 90px;
                text-align: center;
                line-height: 90px;
                font-weight: bold;
                font-size: 21px;
                font-weight: bold;
                border-radius: 5px;
            }
            .cargoing-right {
                width: 230px;
                padding-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}
.form-card {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: 1px solid red($color: #000000);
}

.search-line {
    width: 1px;
    height: 15px;
    background: #999;
    margin: 0 10px;
}

.search-card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    .search {
        display: flex;
        align-items: center;
        padding: 0px 15px;
        height: 50px;
        width: 100%;
        border: 1px solid #dcdfe6;
        border-radius: 4px;

        .search-btn {
            width: 49px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background: #409eff;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
        }

        input {
            border: none;
            font-size: 20px;
            flex: 1;
            background: none;
            outline: none;
            color: #067cf2;
            border: none;
        }

        input::-webkit-input-placeholder {
            color: #067cf2;
        }

        input:focus {
            color: #c56209;
        }

        input:focus::-webkit-input-placeholder {
            color: #c56209;
        }
    }
}
.clickxiu {
    padding-left: 10px;
    color: #067cf2;
    cursor: pointer;
    font-size: 15px;
    text-decoration: underline;
}
</style>
