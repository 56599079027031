<template>
    <el-select
        class="select-user"
        popper-class="select-user-list"
        :value="value"
        filterable
        clearable
        remote
        @input="$emit('input', $event)"
        @change="handleChange"
        :remote-method="
            (val) => {
                fetchUserList(val)
            }
        "
        @clear="fetchUserList()"
        v-bind="$attrs"
    >
        <el-option
            v-for="item in userList"
            :key="item.id"
            :value="item.id"
            :label="item.simpleName || item.name"
            class="select-user-option"
        >
            <div class="option-row">
                <el-image v-if="item.avatar" :src="item.avatar" class="row-portrait" lazy fit="fill"> </el-image>
                <div v-else class="row-portrait text-portrait">
                    {{ item.name.substr(0, 1) }}
                </div>
                <div class="option-row-content">
                    <div class="content-top">
                        <span class="content-title">{{ item.name }}</span>
                        <span>{{ item.phone }}</span>
                    </div>
                    <div class="content-footer">
                        <span v-for="(ele, idx) in item.deptList" :key="idx">{{ ele.deptName }}</span>
                        <span class="separator">|</span>
                        <span>{{ item.roleName }}</span>
                    </div>
                </div>
            </div>
        </el-option>
        <div class="select-user-footer">
            <el-pagination
                :current-page="page"
                :page-size="50"
                layout="total, prev, pager, next"
                :total="totalItem"
                @current-change="currentChange"
            >
            </el-pagination>
        </div>
    </el-select>
</template>

<script>
import request from '@/libs/http'
export default {
    name: 'selectUser',
    inheritAttrs: false,
    props: ['value', 'defaultText', 'params'],
    data() {
        return {
            page: 1,
            totalItem: 0,
            userList: [],
            searchText: ''
        }
    },
    watch: {
        defaultText: {
            handler(val) {
                this.fetchUserList(val)
            },
            immediate: true
        }
    },
    created() {},
    methods: {
        currentChange(e) {
            this.page = e
            this.fetchUserList(this.searchText)
        },
        fetchUserList(name = '') {
            if (this.searchText != name) {
                this.page = 1
            }
            this.searchText = name
            let reqData = {
                name: name,
                page: this.page + '',
                pageSize: '50'
            }
            if (this.params && Object.keys(this.params).length) {
                Object.keys(this.params).forEach((key) => {
                    reqData[key] = this.params[key]
                })
            }

            request
                .post('/user/query-user-list', reqData)
                .then((data) => {
                    this.userList = data.items
                    this.totalItem = +data.totalItem || 0
                })
                .catch((error) => {
                    this.$message.error(error)
                })
        },
        handleChange(val) {
            let data = this.userList.find((item) => item.id === val)
            this.$emit('change', val, data)
        }
    }
}
</script>
<style lang="scss">
.select-user {
    width: 100%;
}
.select-user-list {
    position: relative;
}
.select-user-list .el-select-dropdown__list {
    padding-bottom: 40px;
}
.select-user-option {
    height: auto;
    display: flex;
    padding: 0 10px;
    min-width: 440px;
    border-bottom: 1px solid #eee;
    .option-row {
        display: flex;
        align-items: center;
        .row-portrait {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 20px;
        }
        .text-portrait {
            background: #eee;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
        }
        .option-row-content {
            display: flex;
            flex-direction: column;
            color: #999999;
            .content-top {
                display: flex;
                align-items: center;
                height: 30px;
                line-height: 30px;
            }
            .content-title {
                margin-right: 10px;
                font-size: 18px;
                color: #333333;
            }
            .content-footer {
                display: flex;
                align-items: center;
                font-weight: 400;
                height: 30px;
                line-height: 30px;
                .separator {
                    margin: 0 10px;
                }
            }
        }
    }
}
.select-user-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
}
</style>
