<template>
    <el-drawer
        size="90%"
        :visible.sync="drawerVisiable"
        :show-close="false"
        @close="close"
        append-to-body
        :wrapperClosable="false"
        :close-on-press-escape="false"
        custom-class="check-accept-drawer"
    >
        <div slot="title" class="drawer-top row align-center space-between">
            <div class="drawer-top-left">
                <h3 class="label">验收</h3>
                <div class="title-item">
                    <div class="title-label">
                        {{ '发货方：' }}
                    </div>
                    <div class="title-value">
                        {{ cardObj.senderName }}
                    </div>
                </div>
                <div class="title-item">
                    <div class="title-label">单据号</div>
                    <div class="title-value">{{ cardObj.invoicesSn }}</div>
                </div>
                <div class="title-item">
                    <div class="title-label">包裹号</div>
                    <div class="title-value">{{ cardObj.packageNo }}</div>
                </div>
            </div>
            <div class="drawer-top-right" style="display: flex; align-items: center">
<!--                <div class="sub-box" @click="oneKeyAcceptance" v-loading="loading">-->
<!--                    <svg-icon class-name="size-icon" icon-class="picking" style="font-size: 16px" /> 一键验收-->
<!--                </div>-->
                <basic-button
                    @click="oneKeyAcceptance"
                    type="primary"
                    :loading="loadingSubmit || beforeSubmitOne || loading"
                    icon-class="picking"
                    style="width: 200px; font-size: 16px"
                >
                    一键验收
                </basic-button>
                <basic-button
                    @click="beforeSubmit"
                    type="primary"
                    :loading="loadingSubmit || beforeSubmitOne || loading"
                    icon-class="save"
                    style="width: 200px; font-size: 16px"
                >
                    提交并完成
                </basic-button>
                <i
                    class="el-icon-close"
                    style="font-size: 20px; color: #000; font-weight: bold; margin-left: 20px; cursor: pointer"
                    @click="close"
                ></i>
            </div>
        </div>
        <div v-loading="loading">
            <div class="search-row">
                <div class="search-card">
                    <div class="search">
                        <i
                            class="iconfont icon-saoma"
                            style="font-size: 24px"
                            :style="{ color: isInput ? '#C56209' : '#067CF2' }"
                        ></i>
                        <div class="search-line" :style="{ background: isInput ? '#C56209' : '#067CF2' }"></div>
                        <input
                            type="text"
                            ref="searchCode"
                            placeholder="请扫码或输入条码"
                            @focus="isInput = true"
                            @blur="isInput = false"
                            v-focusInput="1"
                            @keyup.enter="searchCode($event)"
                            v-model="barCode"
                        />
                        <div class="search-btn" @click="searchCode()">确定</div>
                    </div>
                </div>
                <el-switch
                    size="small"
                    v-model="scanFlag"
                    inactive-color="#44DB5E"
                    active-color="#ff0000"
                    active-text="负扫"
                    inactive-text="正扫"
                    @change='control'
                    style="margin: 0 40px"
                ></el-switch>
                <el-input-number size="small" :min="1" v-model="scanNum" @change='control' :precision="0" style="margin: 0 40px"></el-input-number>
                <div style="margin: 0 40px">
                    <el-checkbox v-model="checked" border @change='control'>只显示未收</el-checkbox>
                </div>
                <div style="display: flex">
                    <el-input placeholder="请输入货号" @change='control' v-model="joinGoods" clearable @clear="searchJoin"></el-input>
                </div>
                <basic-button native-type="submit" type="primary" @click="searchJoin" style="margin-left: 20px">
                    查询
                </basic-button>
                <basic-button @click="clickScantraing" type="primary"> 扫码轨迹 </basic-button>
            </div>
            <div style="margin: 20px">
                <el-table
                    ref="dataOptions"
                    :data="[dataOptions[0]]"
                    tooltip-effect="dark"
                    class="tablues"
                    border
                    :header-cell-style="{
                        background: '#e8e8e8',
                        padding: '5px 0',
                        color: '#333',
                        fontSize: '14px',
                        height: '40px'
                    }"
                    :cell-style="{
                        padding: '0px 0',
                        fontSize: '14px',
                        height: '30px'
                    }"
                >
                    <el-table-column prop="goodsName" label="当前扫码货品" align="center"> </el-table-column>
                    <el-table-column prop="goodsNo" label="货号" align="center"> </el-table-column>
                    <el-table-column prop="color" label="颜色" align="center"> </el-table-column>
                    <el-table-column prop="size" label="尺码" align="center"></el-table-column>
                    <el-table-column prop="newNum" label="数量" align="center"></el-table-column>
                </el-table>
            </div>
            <div style="margin: 20px">
                <SizeGroupTable
                    ref="sizeGroupTableIns"
                    :dataList="newDataList"
                    :columns="addColumns"
                    :sizeInfoLabels="addSizeInfoLabel"
                    :footerOptions="footerList"
                    :updataList="updataList"
                    :updataIdx.sync="updataIdx"
                    :filterText="filterText"
                    :isEmpty="true"
                    :isShowRow="checked"
                    border
                    @checkNumberChange="checkNumberChange"
                >
                    <template v-slot="{ row, item }">
                        <el-popover v-if="item.prop === 'goodsNo'" placement="right-start" trigger="click">
                            <el-image
                                v-if="row.imageList && row.imageList.length"
                                style="width: 400px; height: 400px"
                                :src="row.imageList[0]"
                                :preview-src-list="row.imageList"
                            >
                            </el-image>
                            <span slot="reference" class="text-link">{{ row.goodsNo }} </span>
                        </el-popover>
                    </template>
                </SizeGroupTable>
                <endPack
                    v-if="endPackDialog"
                    :modalParams="endPackParams"
                    type="1"
                    @close="
                        (e) => {
                            endPackDialog = false
                            e ? submit() : ''
                        }
                    "
                ></endPack>
                <ScanTrajectory v-if="ScanTraDialog" :detailsList="dataOptions" @close="ScanTraclose"> </ScanTrajectory>
            </div>
        </div>
    </el-drawer>
</template>

<script>
import svgIcon from '@/components/SvgIcon/index'
import endPack from './endPack.vue'
import SizeGroupTable from '@/components/SizeGroupTable/index.vue'
import { addColumns, addSizeInfoLabel, footerList } from './CheckAcceptPackTableConfig.js'
import ScanTrajectory from '@/views/invoicesCenter/components/ScanTrajectory'

import {
    erp2OperatingCheck,
    erp2DistributeWebPackageDetail,
    erp2OperatingCheckComplete,
    erp2OperatingCheckPackageAll
} from '@/libs/http/modules/erp.js'
export default {
    components: {
        endPack,
        SizeGroupTable,
        svgIcon,
        ScanTrajectory
    },
    props: {
        modalParams: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            addColumns,
            addSizeInfoLabel,
            footerList,
            filterText: '',
            joinGoods: '',
            dataOptions: [], //当前扫码的货品列表
            ScanTraDialog: false,
            drawerVisiable: false,
            headerLeft: [
                { name: '货品名称', id: '1' },
                { name: '货号', id: '2' }
            ],
            cardObj: {},
            dataList: [],
            newDataList: [],
            updataList: [], //用来指定更新的数据
            updataIdx: '', //要更新的行
            barCode: '',
            checked: false,
            searchInNo: '',
            isInput: false,
            endPackDialog: false,
            endPackParams: null,
            scanNum: 1,
            scanFlag: false,
            loading: false,
            loadingSubmit: false,
            first: true, //判断是否是第一次进来
            refetchBtn: false,
            beforeSubmitOne:false
        }
    },
    created() {
        this.$nextTick(() => {
            this.$refs.searchCode.focus()
        })
        this.drawerVisiable = true
        this.loading = true
        this.fetchDetail()
    },
    methods: {
        control() {
            this.$nextTick(() => {
                this.$refs.searchCode.focus()
            })
        },
        searchJoin() {
            this.filterText = this.joinGoods
        },
        clickScantraing() {
            this.ScanTraDialog = true
        },
        ScanTraclose() {
            this.ScanTraDialog = false
        },
        beforeSubmit() {
            let obj = this.$refs.sizeGroupTableIns.totalObj
            this.endPackParams = {
                ...obj,
                distriNumTotal: obj.checkGetNum
            }
            if (this.endPackParams.noReceivedTotal == this.endPackParams.distriNumTotal) {
                return this.$message({
                    type: 'error',
                    message: `请至少验收一件`,
                    duration: 2000,
                    showClose: true
                })
            }
            if (this.endPackParams.noReceivedTotal > 0) {
                if (this.cardObj.isOffset == 'N') {
                    return this.$message({
                        type: 'error',
                        message: '不允许差异提交，请联系商品人员',
                        duration: 2000,
                        showClose: true
                    })
                } else {
                    this.endPackDialog = true
                }
            } else {
                this.submit()
            }
        },
        submit() {
            //验收
            this.loadingSubmit = true
            erp2OperatingCheckComplete({
                id: this.cardObj.packId
            })
                .then((data) => {
                    this.$message({
                        type: 'success',
                        message: '操作成功',
                        duration: 2000,
                        showClose: true
                    })
                    this.drawerVisiable = false
                    this.loadingSubmit = false
                    this.$emit('close', true)
                })
                .catch((err) => {
                    this.loadingSubmit = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        //一键验收
        oneKeyAcceptance() {
            this.beforeSubmitOne=true
            erp2OperatingCheckPackageAll({
                packageId: this.modalParams.packageId
            })
                .then((data) => {
                    this.refetchBtn = true
                    this.beforeSubmitOne = false
                    this.$message.success('操作成功')
                    this.fetchDetail('refresh')
                })
                .catch((err) => {
                    this.beforeSubmitOne = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        //验收查询
        fetchDetail(type) {
            this.loading=true
            erp2DistributeWebPackageDetail({
                id: this.modalParams.packageId
            })
                .then((data) => {
                    this.loading = false
                    this.$nextTick(() => {
                        // 判断是否是第一次渲染或者是超尺码拣货就进行赋值渲染
                        if (this.first || this.isOverScan) {
                            this.newDataList = data.goodsInfoList || []
                            this.first = false
                        }
                        //一键验收直接赋值 会闪动
                        if (type == 'refresh') {
                            this.newDataList = data.goodsInfoList || []
                        }
                        this.updataList = data.goodsInfoList
                    })
                    this.cardObj = data || {}
                })
                .catch((err) => {
                    this.loading = false
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },

        searchCode() {
            this.$nextTick(() => {
                this.$refs.searchCode.blur()
            })
            if (!this.barCode) {
                this.$nextTick(() => {
                    this.$refs.searchCode.focus()
                })
                return
            }
            this.setCheckGoods()
            this.searchInNo = this.barCode
        },
        setCheckGoods(params) {
            let reqData = params || {
                packageId: this.modalParams.packageId,
                barCode: this.barCode,
                scanType: this.scanFlag ? '-1' : '1',
                scanNumber: this.scanNum,
                invoiceId: this.cardObj.invoicesId
            }
            //根据扫描的条码来定位需要更新的行
            for (let i = 0; i < this.updataList.length; i++) {
                for (let j = 0; j < this.updataList[i].sizeInfoList.length; j++) {
                    if (this.barCode === this.updataList[i].sizeInfoList[j].barCode) {
                        this.updataIdx = this.updataList[i].spuInfoId + this.updataList[i].colorValue

                        break
                    }
                }
            }
            this.loading=true
            erp2OperatingCheck(reqData)
                .then((data) => {
                    this.loading=false
                    if (!params) {
                        data.scanNum = !this.scanFlag ? this.scanNum : this.scanNum * -1
                        this.dataOptions.unshift(data)
                    }
                    // this.$emit('refetch')
                    this.refetchBtn = true
                    this.fetchDetail()
                    this.barCode = ''
                    this.$nextTick(() => {
                        this.$refs.searchCode.focus()
                    })
                    this.$message({
                        type: 'success',
                        message: '操作成功',
                        duration: 2000,
                        showClose: true
                    })
                    let messageAudio = new Audio(require('@/assets/audio/scan_tip.mp3'))
                    messageAudio.play()
                })
                .catch((err) => {
                    this.loading=false
                    this.barCode = ''
                    this.$store.commit('playErrorAudio', err)
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                    this.$nextTick(() => {
                        this.$refs.searchCode.focus()
                    })
                    if (params) {
                        this.$refs.sizeGroupTableIns.restoreCheckValue()
                    }
                })
        },
        updateCheckNumber(row) {
            let data = {
                barCode: row.barCode,
                scanType: row.checkHistoryNumber * 1 - row.checkNum > 0 ? '-1' : '1',
                scanNumber: Math.abs(row.checkHistoryNumber - row.checkNum),
                packageId: this.modalParams.packageId,
                invoiceId: this.cardObj.invoicesId
            }
            this.setCheckGoods(data)
        },
        close() {
            if(this.loadingSubmit) return
            this.drawerVisiable = false
            this.$emit('close', this.refetchBtn)
        },
        checkNumberChange(row, _row, _value, _oldValue, _key) {
            if (_row.checkNum > _row.number) {
                this.$message.error(`可验收数量为${_row.number}`)
                this.$nextTick(() => {
                    this.$refs.searchCode.focus()
                })
                this.$set(_row, 'checkNum', _oldValue)
                this.$store.commit('playErrorAudio')
                return
            }
            this.updateCheckNumber(
                {
                    checkHistoryNumber: _oldValue,
                    ..._row
                },
                _key
            )
        }
    }
}
</script>
<style lang="scss" scoped>
.check-accept-drawer {
    .text-link {
        color: #1890ff;
        cursor: pointer;
    }
    .drawer-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .drawer-top-left {
            display: flex;
            justify-content: space-between;
            .label {
                margin-right: 20px;
                display: flex;
                align-items: center;
                color: #333;
            }
            .title-item {
                margin-right: 20px;
            }
            .title-label {
                color: #b0afaf;
                font-size: 16px;
            }
            .title-value {
                font-size: 18px;
                font-weight: 700;
            }
        }
        .sub-box {
            color: black;
            margin: 10px 15px;
            padding: 10px 50px;
            background: #c6e7ff;
            display: flex;
            justify-content: center;
            align-content: center;
            cursor: pointer;
            border-radius: 3px;
            img {
                width: 20px;
                margin-right: 10px;
            }
        }
    }
    .search-row {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        padding: 20px 20px 0;
    }
}
::v-deep .el-drawer__header {
    padding: 20px;
    margin-bottom: 0;
}
::v-deep .el-drawer__body {
    border-top: 5px solid #f5f5f5;
}
.search-line {
    width: 1px;
    height: 15px;
    background: #999;
    margin: 0 10px;
}
.search-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
        display: flex;
        align-items: center;
        padding: 0px 15px;
        height: 50px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        .search-btn {
            width: 49px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background: #409eff;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
        }
        input {
            border: none;
            font-size: 20px;
            width: 286px;
            background: none;
            outline: none;
            color: #067cf2;
            border: none;
        }
        input::-webkit-input-placeholder {
            color: #067cf2;
        }
        input:focus {
            color: #c56209;
        }
        input:focus::-webkit-input-placeholder {
            color: #c56209;
        }
    }
}
.size-icon {
    margin-right: 5px;
    margin-top: 3px;
}
</style>
