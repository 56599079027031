<template>
    <el-select
        class="select-supplier"
        popper-class="select-supplier-list"
        :value="value"
        filterable
        clearable
        remote
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
        :remote-method="
            (val) => {
                fetchSupplierList(val)
            }
        "
        @clear="fetchSupplierList()"
        v-bind="$attrs"
    >
        <el-option
            v-for="item in supplierList"
            :key="item.id"
            :value="item.id"
            :label="item.simpleName ? item.simpleName : item.name"
            class="select-supplier-option"
        >
            <div class="option-row">
                <div class="option-row-top">
                    <div>
                        <span class="option-label">供应商名称：</span>
                        <span class="option-value">{{ item.simpleName }}</span>
                    </div>
                    <div>
                        <span class="option-label">编号：</span>
                        <span>{{ item.code }}</span>
                    </div>
                </div>
                <div class="option-row-center">
                    <span class="option-label">供应商全称：</span>
                    <span class="option-label">{{ item.name }}</span>
                </div>
                <div class="option-tags" v-if="item.typeItemList && item.typeItemList.length">
                    <el-tag
                        v-for="(ele, index) in item.typeItemList"
                        :key="index"
                        type=""
                        effect="plain"
                        :style="{
                            marginLeft: index > 0 ? '5px' : '',
                            padding: '0px 5px',
                            height: '20px',
                            lineHeight: '20px'
                        }"
                    >
                        {{ types[ele.type] ? types[ele.type] : '工服物料' }}
                    </el-tag>
                </div>
            </div>
        </el-option>
        <div class="select-supplier-footer">
            <el-pagination
                :current-page="page"
                :page-size="50"
                layout="total, prev, pager, next"
                :total="totalItem"
                @current-change="currentChange"
            >
            </el-pagination>
        </div>
    </el-select>
</template>

<script>
import request from '@/libs/http'
export default {
    name: 'selectSupplier',
    inheritAttrs: false,
    props: ['value', 'supplierType', 'defaultText'],
    data() {
        return {
            types: {
                1: '加工',
                2: '成品',
                3: '面料',
                4: '辅料',
                5: '吊牌',
                6: '行政物料',
                7: '广告物料'
            },
            page: 1,
            totalItem: 0,
            supplierList: [],
            searchText: ''
        }
    },
    watch: {
        supplierType: {
            handler(val) {
                this.fetchSupplierList(this.defaultText || '')
            },
            immediate: true
        }
    },
    created() {
        // this.fetchSupplierList()
    },
    methods: {
        currentChange(e) {
            this.page = e
            this.fetchSupplierList(this.searchText)
        },
        fetchSupplierList(name = '') {
            if (this.searchText != name) {
                this.page = 1
            }
            this.searchText = name
            //供应商列表
            let reqData = {
                name: name,
                page: this.page + '',
                pageSize: 50,
                delFlag: '0',
                type: this.supplierType || ''
            }
            request
                .post('/erp/erp-supplier-info/query-list', reqData)
                .then((data) => {
                    this.supplierList = data.items || []
                    this.totalItem = +data.totalItem || 0
                })
                .catch((error) => {
                    this.$message.error(error)
                })
        }
    }
}
</script>
<style lang="scss">
.select-supplier {
    width: 100%;
}
.select-supplier-list {
    position: relative;
}
.select-supplier-list .el-select-dropdown__list {
    padding-bottom: 40px;
}
.select-supplier-option {
    height: auto;
    display: flex;
    padding: 0 10px;
    min-width: 480px;
    border-bottom: 1px solid #eee;
    .option-row {
        height: auto;
        line-height: 22px;
        width: 100%;
        padding: 5px 0;
        .option-row-top {
            display: flex;
            justify-content: space-between;
        }
        .option-label {
            color: #999999;
        }
        .option-value {
            font-size: 18px;
            color: #333333;
        }
        .option-row-center {
            display: flex;
        }
    }
}
.select-supplier-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
}
</style>
