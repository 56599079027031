<template>
    <div>
        <el-dialog
            append-to-body
            top="2vh"
            width="56%"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :show-close="false"
            @close="close"
        >
            <section slot="title" class="dialog-top">
                <div>
                    <!-- <span v-if="isPrint">{{
            type == 1 ? "打印铺货单" : "随货单信息"
          }}</span> -->
                    <span v-if="isPrint">{{ type == 1 ? '打印单据' : '随货单信息' }}</span>
                    <span v-else>包裹信息</span>
                </div>
                <div style="display: flex">
                    <!-- <el-button v-if="isPrint" type="warning" v-print="'#printInvoce'"
            >打印</el-button
          > -->
                    <!-- <el-button v-if="isPrint" type="primary" @click="print" 
            >打印</el-button
          > -->
                    <div class="sub-box" v-if="isPrint" @click="print">
                        <svg-icon class-name="size-icon" icon-class="print" /> 打印
                    </div>
                    <i class="el-icon-close" @click="dialogVisible = false"></i>
                </div>
            </section>
            <div
                class="print-page"
                id="printInvoce"
                ref="printInvoce"
                :style="isPrint ? 'border:1px solid #000;padding:20px;' : ''"
                v-loading="loading"
            >
                <template v-if="isPrint">
                    <div
                        class="page-top"
                        style="display: felx; align-items: center; text-align: center; justify-content: space-between"
                    >
                        <div>
                            <img id="barcode" />
                        </div>
                        <div style="color: #000; font-size: 30px; font-weight: bold">
                            <!-- {{ type == 1 ? "商品铺货单" : "随货单" }} -->
                            {{ title }}
                        </div>
                        <div v-if="dataInfo && dataInfo.createTime">单据日期：{{ dataInfo.createTime }}</div>
                    </div>
                    <div class="page-info" v-if="dataInfo && type == 1">
                        <div class="info-list">单号：{{ modalParams.invoicesNo }}</div>
                        <div class="info-list">发货方：{{ dataInfo.senderInfoName }}</div>
                        <div class="info-list">收货方：{{ dataInfo.receiveInfoName }}</div>
                    </div>
                    <div v-else class="page-info">
                        <div class="info-list">单号：{{ modalParams.invoicesNo }}</div>
                        <div class="info-list">包裹号：{{ modalParams.packageSn }}</div>
                        <div class="info-list">收货方：{{ modalParams.receiveInfoName }}</div>
                        <div class="info-list" v-if="modalParams.receiverDeptAlias">
                            店铺编号：{{ modalParams.receiverDeptAlias }}
                        </div>
                    </div>
                </template>
                <PuhuoTable
                    ref="puhuotab"
                    sizeGroupType="2"
                    sizeGroupWidth="620px"
                    calcDivNum="4"
                    outWidth="972"
                    :headerLeft="headerLeft"
                    :addedList="addedList"
                    :collectNumberList="collectNumberList"
                    :isAdd="false"
                    :headerRight="[{ key: 'num', name: '汇总' }]"
                ></PuhuoTable>
                <div style="margin-top: 10px; font-size: 12px; color: #333" v-if="1 == 2">
                    备注：<span style="font-size: 18px">这里是备注信息</span>
                    <span style="margin: 0 30px">预估重量：20kg</span>
                    <span>实际称重：18kg</span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import svgIcon from '@/components/SvgIcon/index'
import JsBarcode from 'jsbarcode'
import PuhuoTable from './PuhuoTables.vue'
import { erp2OperatingPrintOrder } from '@/libs/http/modules/erp.js'
export default {
    props: {
        modalParams: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            default: '1'
        },
        isPrint: {
            type: Boolean,
            default: true
        }
    },
    components: {
        PuhuoTable,
        svgIcon
    },
    data() {
        return {
            dialogVisible: false,
            headerLeft: [
                { key: 'goodsName', name: '货品名称' },
                { key: 'goodsNo', name: '货号' },
                { key: 'colorValue', name: '颜色' }
            ],
            dataInfo: null,
            addedList: [],
            collectNumberList: [],
            loading: false
        }
    },
    computed: {
        title() {
            let titles = {
                100010: '商品铺货单',
                100011: '店铺调出单',
                100020: '店铺退货单',
                100021: '店铺退供单'
            }
            let key = this.modalParams.typeCode ? this.modalParams.typeCode : ''
            return titles[key]
        }
    },
    created() {
        this.dialogVisible = true
        if (this.type == 1) {
            this.fetchDetail()
        } else {
            this.fetch()
        }
        if (this.isPrint) {
            this.getBarcode()
        }
    },
    methods: {
        print() {
            let innerHTML = document.querySelector('#printInvoce').innerHTML
            // ipc.send('renderNotifyPrint', innerHTML)
        },
        fetch() {
          console.log(this.modalParams.packageId);
          erp2OperatingPrintOrder({
                id: this.modalParams.packageId
            })
                .then((data) => {
                    this.$nextTick(() => {
                        this.addedList = data.goodsInfoList || []
                        this.collectNumberList = data.collectNumberList || []
                        this.dataInfo = data.invoicesInfo
                    })
                })
                .catch((err) => {
                    // this.$emit('close')
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        close() {
            this.dialogVisible = false
            this.$emit('close')
        },
        getBarcode() {
            let invoicesNo =
                this.modalParams.sn.match(/^[a-z|A-Z]+/gi) + ' ' + this.modalParams.sn.match(/\d+$/gi)
            this.$nextTick(() => {
                JsBarcode('#barcode', invoicesNo, {
                    height: 50,
                    width: 1,
                    fontSize: 16,
                    displayValue: false,
                    margin: 0
                })
            })
        },
        fetchDetail() {
            this.loading = true
            console.log(this.modalParams);
            erp2OperatingPrintOrder({
                id: this.modalParams.id
            })
                .then((data) => {
                    this.loading = false
                    this.$nextTick(() => {
                        if (data) {
                            this.addedList = data.goodsInfoList || []
                            this.collectNumberList = data.collectNumberList || []
                            this.dataInfo = data.invoicesInfo
                        }
                    })
                })
                .catch((err) => {
                    this.loading = false
                    // this.$emit("close");
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.print-page {
    margin: -20px auto 0;
    overflow: auto;
    .page-top {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: space-between;
    }
    .page-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 10px;
    }
}
.dialog-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-top: -1px;
    p {
        font-size: 18px;
        color: #333;
        font-weight: bold;
    }
    div i {
        cursor: pointer;
        font-size: 22px;
        margin-left: 20px;
        float: right;
        margin-top: 8px;
    }
    .sub-box {
        color: black;
        margin: 10px 15px;
        padding: 10px 50px;
        background: #c6e7ff;
        display: flex;
        justify-content: center;
        align-content: center;
        cursor: pointer;
        border-radius: 3px;
        img {
            width: 20px;
            margin-right: 10px;
        }
    }
}
.size-icon {
    margin-right: 5px;
    margin-top: 3px;
}
</style>
