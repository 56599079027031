<template>
    <el-select
        class="select-entrepot"
        popper-class="select-entrepot-list"
        :value="value"
        filterable
        clearable
        remote
        @input="$emit('input', $event)"
        @change="(val) => headelChange(val)"
        :remote-method="
            (val) => {
                fetchEntrepotList(val)
            }
        "
        @clear="fetchEntrepotList()"
        v-bind="$attrs"
    >
        <el-option
            v-for="item in entrepotList"
            :key="item.id"
            :value="item.id"
            :label="item.title"
            class="select-entrepot-option"
        >
            <div class="option-row">
                <div class="option-row-top">
                    <div>
                        <span class="option-label">名称：</span>
                        <span class="option-value">{{ item.title }}</span>
                    </div>
                    <div>
                        <span class="option-label">编号：</span>
                        <span>{{ item.code }}</span>
                    </div>
                </div>
                <div class="option-row-center">
                    <span class="option-label">地址：</span>
                    <span class="option-label">{{ item.addressName }}</span>
                </div>
            </div>
        </el-option>
        <div class="select-entrepot-footer">
            <el-pagination
                :current-page="page"
                :page-size="50"
                layout="total, prev, pager, next"
                :total="totalItem"
                @current-change="currentChange"
            >
            </el-pagination>
        </div>
    </el-select>
</template>

<script>
import request from '@/libs/http'
export default {
    name: 'selectEntrepot',
    inheritAttrs: false,
    props: ['value', 'warehouseTypes', 'defaultText', 'params'],
    data() {
        return {
            page: 1,
            totalItem: 0,
            entrepotList: [],
            searchText: ''
        }
    },
    watch: {
        warehouseTypes: {
            handler(val) {
                this.fetchEntrepotList(this.defaultText || '')
            },
            immediate: true
        },
        params: {
            handler(val) {
                this.fetchEntrepotList(this.defaultText || '')
            },
            deep: true
        }
    },
    created() {},
    methods: {
        currentChange(e) {
            this.page = e
            this.fetchEntrepotList(this.searchText)
        },
        fetchEntrepotList(name = '') {
            if (this.searchText != name) {
                this.page = 1
            }
            this.searchText = name
            //仓库列表
            let reqData = {
                title: name,
                page: this.page + '',
                pageSize: 50
            }
            if (this.warehouseTypes) {
                reqData.warehouseTypeList = this.warehouseTypes.split(',')
            }
            if (this.params && Object.keys(this.params).length) {
                Object.keys(this.params).forEach((key) => {
                    reqData[key] = this.params[key]
                })
            }
            request
                .post('/erp/query-warehouse-unit-info-list', reqData)
                .then((data) => {
                    this.entrepotList = data.items || []
                    this.totalItem = +data.totalItem || 0
                })
                .catch((error) => {
                    this.$message.error(error)
                })
        },
        headelChange(val) {
            let data = this.entrepotList.find((item) => item.id === val)
            this.$emit('change', val, data)
        }
    }
}
</script>
<style lang="scss">
.select-entrepot {
    width: 100%;
}
.select-entrepot-list {
    position: relative;
}
.select-entrepot-list .el-select-dropdown__list {
    padding-bottom: 40px;
}
.select-entrepot-option {
    height: auto;
    display: flex;
    padding: 0 10px;
    min-width: 300px;
    border-bottom: 1px solid #eee;
    .option-row {
        height: auto;
        line-height: 22px;
        width: 100%;
        padding: 5px 0;
        .option-row-top {
            display: flex;
            justify-content: space-between;
        }
        .option-label {
            color: #999999;
        }
        .option-value {
            font-size: 18px;
            color: #333333;
        }
        .option-row-center {
            display: flex;
        }
    }
}
.select-entrepot-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
}
</style>
