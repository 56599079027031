<template>
    <div>
        <el-dialog
            :title="type == 1 ? '完成验收' : '完成拣货'"
            :visible.sync="dialogVisible"
            @close="close"
            append-to-body
        >
            <div>
                <div style="width: 330px; margin-left: 255px; font-size: 16px; color: #333; text-align: center">
                    当前数据存在差异是否继续“提交{{ type == 1 ? '验收' : '拣货' }}”
                </div>
                <div
                    style="
                        width: 330px;
                        margin-left: 252px;
                        margin-top: 20px;
                        font-size: 16px;
                        color: #333;
                        text-align: center;
                    "
                >
                    <span>总数量：{{ modalParams.distriNumTotal }}</span>
                    <span style="margin: 0 30px"
                        >已{{ type == 1 ? '验收' : '拣货' }}：{{ modalParams.ReceivedTotal }}</span
                    >
                    <span style="color: #f8475f" v-if="modalParams.noReceivedTotal < 0"
                        >超{{ type == 1 ? '验收' : '拣货' }}：{{ Math.abs(modalParams.noReceivedTotal) }}</span
                    >
                    <span style="color: #f8475f" v-else
                        >未{{ type == 1 ? '验收' : '拣货' }}：{{ modalParams.noReceivedTotal }}</span
                    >
                </div>
            </div>
            <div slot="footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ['modalParams', 'type'],
    data() {
        return {
            dialogVisible: false
        }
    },
    created() {
        this.dialogVisible = true
    },
    methods: {
        close() {
            this.dialogVisible = false
            this.$emit('close')
        },
        submit() {
            this.dialogVisible = false
            this.$emit('close', true)
        }
    }
}
</script>
<style scoped></style>
