//新增配置
export const addColumn = [
    {
        prop: 'goodsName',
        label: '货品名称'
    },
    {
        prop: 'goodsNo',
        label: '货号'
    },
    {
        prop: 'colorValue',
        label: '颜色'
    },
    {
        prop: 'sizeInfoList',
        width: '800px',
        boxWidth: '90px' //复选框宽度
    },
    {
        prop: 'total',
        label: '汇总',
        type: 'count',
        getTotal(row, _key) {
            return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
        }
    }
]

export const addSizeInfoLabel = [
    {
        prop: 'usableQuantity', //复选框key//valueInfoList当前行对应key
        label: '可用库存', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true //禁用
    },
    {
        prop: 'number', //复选框key//valueInfoList当前行对应key
        label: '退货数', //复选框名称及行数据对应名称
        checkShow: false, //是否出现复选框
        value: true, //绑定值
        disabled: true, //禁用
        colType: 'addInput', //当前行是否为输入框
        getRowBgc(_row) {
            if (+_row.number !== 0) {
                return 'row-bg-green'
            } else {
                return ''
            }
        }
    }
]
export const addActions = {
    fixedWidth: 120,
    list: [
        {
            label: '删除',
            event: 'del',
            displayRule: (row) => true
        }
    ]
}
export const footerObj = [
    {
        prop: "totalAmount",
        label: "总款数",
        unit: "个",
        formula(data) {
            let sum = 0
            data.forEach((item) => {
                for (let i of item.sizeInfoList) {
                    if (+i.number > 0) {
                        sum++
                        break
                    }
                }
            })
            return sum
        },
    },
    {
        prop: 'tcGetNum',
        label: '退仓总件数',
        unit: '',
        formula(data) {
            let num = 0
            data.forEach((item) => {
                let all = item.sizeInfoList.reduce((c, R) => c + Number(R.number*1 || 0), 0)
                num += all
            })
            return num
        }
    },
   
]