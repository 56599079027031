var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-select',_vm._b({staticClass:"select-supplier",attrs:{"popper-class":"select-supplier-list","value":_vm.value,"filterable":"","clearable":"","remote":"","remote-method":(val) => {
            _vm.fetchSupplierList(val)
        }},on:{"input":function($event){return _vm.$emit('input', $event)},"change":function($event){return _vm.$emit('change', $event)},"clear":function($event){return _vm.fetchSupplierList()}}},'el-select',_vm.$attrs,false),[_vm._l((_vm.supplierList),function(item){return _c('el-option',{key:item.id,staticClass:"select-supplier-option",attrs:{"value":item.id,"label":item.simpleName ? item.simpleName : item.name}},[_c('div',{staticClass:"option-row"},[_c('div',{staticClass:"option-row-top"},[_c('div',[_c('span',{staticClass:"option-label"},[_vm._v("供应商名称：")]),_c('span',{staticClass:"option-value"},[_vm._v(_vm._s(item.simpleName))])]),_c('div',[_c('span',{staticClass:"option-label"},[_vm._v("编号：")]),_c('span',[_vm._v(_vm._s(item.code))])])]),_c('div',{staticClass:"option-row-center"},[_c('span',{staticClass:"option-label"},[_vm._v("供应商全称：")]),_c('span',{staticClass:"option-label"},[_vm._v(_vm._s(item.name))])]),(item.typeItemList && item.typeItemList.length)?_c('div',{staticClass:"option-tags"},_vm._l((item.typeItemList),function(ele,index){return _c('el-tag',{key:index,style:({
                        marginLeft: index > 0 ? '5px' : '',
                        padding: '0px 5px',
                        height: '20px',
                        lineHeight: '20px'
                    }),attrs:{"type":"","effect":"plain"}},[_vm._v(" "+_vm._s(_vm.types[ele.type] ? _vm.types[ele.type] : '工服物料')+" ")])}),1):_vm._e()])])}),_c('div',{staticClass:"select-supplier-footer"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-size":50,"layout":"total, prev, pager, next","total":_vm.totalItem},on:{"current-change":_vm.currentChange}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }