<template>
    <section class="container">
        <stockTopMenu curPosition="单据中心" windowId="invoicesCenter" module="invoice">
            <div slot="descript" class="row align-center space-between" style="margin-left: 100px"></div>
        </stockTopMenu>
        <div class="main">
            <div class="sidebar-box">
                <div class="sidebar-l">
                    <template v-for="item in sidebarList">
                        <div
                            :key="item.key"
                            :class="['sidebar-item', item.key === activeKey ? 'sidebar-active' : '']"
                            @click="sidebarSwitch(item.key)"
                        >
                            <span>{{ item.name }}</span>
                            <span class="msg-a" v-if='item.num*1'>{{ item.num }}</span>
                            <el-tooltip effect="dark" :content="item.prompt" placement="top-start">
                                <div class="prompt-box">?</div>
                            </el-tooltip>
                        </div>
                    </template>
                </div>
                <div class="sidebar-r">
                    <template v-for="item in childerList">
                        <div
                            :key="item.key"
                            :class="['childer-item', item.key === activeChilderKey ? 'childer-active' : '']"
                            @click="childerSwitch(item.key)"
                        >
                            <span>{{ item.name }}</span>
                            <span class="msg-a" v-if='item.num*1'>{{ item.num }}</span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="content-box">
                <component v-if="activeChilderKey" :ref="activeChilderKey" :is="activeChilderKey"></component>
            </div>
        </div>
    </section>
</template>
<script>
import stockTopMenu from '@/components/stockTopMenu.vue'
import distribution from './input/distribution.vue'
import transferSlipEnter from './input/transferSlipEnter.vue'
import deptRetreat from './output/deptRetreat.vue'
import transferSlipOut from './output/transferSlipOut.vue'
import { erp2PeratingQueryClientShowNumber } from '@/libs/http/modules/erp.js'
export default {
    name: 'invoicesCenter',
    components: {
        stockTopMenu,
        distribution,
        transferSlipEnter,
        deptRetreat,
        transferSlipOut
    },
    data() {
        return {
            activeKey: 'input',
            activeChilderKey: 'distribution',
            num: 1,
            sidebarList: [],
            numberList:[]
        }
    },
    created() {
            this.quantiting()
    },
    computed: {
        childerList() {
            return this.sidebarList.find((i) => i.key === this.activeKey)?.childer
        }
    },
    mounted() {},
    methods: {
        quantiting() {
            erp2PeratingQueryClientShowNumber({
            })
                .then((data) => {
                    this.numberList=data
                 this.sidebarList=[
             {
                name: '收货',
                prompt: '数字统计的是【未入库】单据的数量',
                num: this.numberList.inTotalNumber,
                key: 'input',
                childer: [
                    { componentName: 'distribution', key: 'distribution', name: '店铺收货单', num: this.numberList.warehouseDistributeNumber },
                    { componentName: 'transferSlipEnter', key: 'transferSlipEnter', name: '店铺调入单', num: this.numberList.marketInNumber }
                ]
            },
            {
                name: '出货',
                prompt: '数字统计的是【未出货】单据的数量',
                num: this.numberList.outTotalNumber,
                key: 'output',
                childer: [
                    { componentName: 'deptRetreat', key: 'deptRetreat', name: '店铺退仓单', num: this.numberList.marketReturnWarehouseNumber },
                    { componentName: 'transferSlipOut', key: 'transferSlipOut', name: '店铺调出单', num: this.numberList.marketOutNumber }
                ]
            }]
        
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        sidebarSwitch(key) {
            if (this.activeKey === key) {
                return
            }
            this.activeKey = key
            this.activeChilderKey = this.sidebarList.find((item) => {
                return item.key === key
            }).childer[0].key
        },
        childerSwitch(key) {
            this.activeChilderKey = key
        }
    }
}
</script>

<style lang="scss" scoped>
.main {
    display: flex;
    background-color: #f2f2f2;
    height: 94vh;
    box-sizing: border-box;
    border-top: 1px solid #eee;
    .sidebar-box {
        height: 100%;
        // width: 380px;
        width: 20vw;
        display: flex;
        box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
        font-size: 16px;
        font-weight: 600;
        .sidebar-l {
            // width: 180px;
            width: 9vw;
            height: 100%;
            background: #14131f;
        }
        .sidebar-r {
            // width: 200px;
            width: 11vw;
            height: 100%;
            background: #ffffff;
        }
        .sidebar-item {
            height: 50px;
            line-height: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #fff;
            padding: 0 30px;
            cursor: pointer;
        }
        .sidebar-active {
            color: #333;
            background: #fff;
        }

        .prompt-box {
            width: 20px;
            height: 20px;
            background: #6c6c6c;
            color: #fff;
            text-align: center;
            line-height: 20px;
            border-radius: 50%;
            margin-left: 10px;
        }
        .msg-a {
            width: 35px;
            border-radius: 3px;
            height: 18px;
            background: #f8475f;
            margin-left: 7px;
            color: white;
            font-size: 12px;
            text-align: center;
            line-height: 17px;
        }
        .childer-item {
            height: 50px;
            line-height: 50px;
            display: flex;
            align-items: center;
            //justify-content: childer-item;
            color: #333;
            padding: 0 30px;
            cursor: pointer;
        }
        .childer-active {
            background: #ffff00;
        }
    }
    .content-box {
        flex: 1;
        margin: 10px;
        background: #fff;
        overflow: hidden;
    }
}
</style>
