<template>
    <div class="time-box">
        <div class="time-List">
            <div class="list-order">
                <div class="item-box" v-for="(item, index) in timeList" :key="index">
                    <div class="item-box-item" v-if="index % 2 != 0">
                        <div class="line-box-top"></div>
                        <div
                            class="round-box-top"
                            :style="{ background: getItemColor(item.scheduleName, item.startTime) }"
                        ></div>
                        <div
                            class="square-box-top"
                            :style="{ background: getItemColor(item.scheduleName, item.startTime) }"
                        >
                            {{ item.scheduleName }}
                        </div>
                        <div class="text-box-top">{{ item.startTime ? item.startTime : '' }}</div>
                        <div class="take-box-top" :title="'耗时：' + item.consumingTime">
                            {{ item.consumingTime ? '耗时：' + item.consumingTime : '' }}
                        </div>
                    </div>
                    <div class="list-box">
                        <div class="box-small"></div>
                    </div>
                    <div class="item-box-item" v-if="index % 2 == 0">
                        <div class="line-box-bottom"></div>
                        <div
                            class="round-box-bottom"
                            :style="{ background: getItemColor(item.scheduleName, item.startTime) }"
                        ></div>
                        <div
                            class="square-box-bottom"
                            :style="{ background: getItemColor(item.scheduleName, item.startTime) }"
                        >
                            {{ item.scheduleName }}
                        </div>
                        <div class="text-box-bottom">{{ item.startTime ? item.startTime : '' }}</div>
                        <div class="take-box-bottom" :title="'耗时：' + item.consumingTime">
                            {{ item.consumingTime ? '耗时：' + item.consumingTime : '' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        timeList: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        getItemColor(itemName, itemTime) {
            if (itemName && itemTime && itemTime != '') {
                if (
                    itemName == '已下发' ||
                    itemName == '已出货' ||
                    itemName == '物流到店' ||
                    itemName == '物流到仓' ||
                    itemName == '已入库'
                ) {
                    return '#f8475f'
                } else {
                    return '#077cf2'
                }
            } else {
                return '#aaaaaa'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.time-box {
    width: 100%;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    .time-List {
        width: 97%;
        height: 2px;
        background: #077cf2;
        display: flex;
        align-items: center;
        .list-order {
            width: 83%;
            display: flex;
            align-items: center;
            margin-left: 50px;
            justify-content: space-between;
            .item-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                .list-box {
                    width: 17px;
                    height: 17px;
                    background: #aaaaaa;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .box-small {
                        width: 7px;
                        height: 7px;
                        background: #fff;
                        border-radius: 50%;
                    }
                }
                .item-box-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .line-box-bottom {
                        height: 27px;
                        width: 1px;
                        background: #aaaaaa;
                        position: absolute;
                        bottom: -27px;
                    }
                    .round-box-bottom {
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        position: absolute;
                        bottom: -32px;
                        background: #aaaaaa;
                    }
                    .square-box-bottom {
                        width: 80px;
                        height: 23px;
                        background: #f8475f;
                        position: absolute;
                        bottom: -57px;
                        color: #fff;
                        text-align: center;
                    }
                    .text-box-bottom {
                        width: 170px;
                        position: absolute;
                        bottom: -80px;
                        left: -32px;
                        color: black;
                    }
                    .take-box-bottom {
                        color: #f8475f;
                        width: 180px;
                        position: absolute;
                        bottom: -100px;
                        left: -32px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;
                    }
                    .line-box-top {
                        height: 27px;
                        width: 1px;
                        background: #aaaaaa;
                        position: absolute;
                        top: -27px;
                    }
                    .round-box-top {
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        position: absolute;
                        top: -32px;
                        background: #aaaaaa;
                    }
                    .square-box-top {
                        width: 80px;
                        height: 23px;
                        background: #077cf2;
                        position: absolute;
                        top: -57px;
                        color: #fff;
                        text-align: center;
                    }
                    .text-box-top {
                        width: 170px;
                        position: absolute;
                        top: -80px;
                        left: -32px;
                        color: black;
                    }
                    .take-box-top {
                        color: #f8475f;
                        width: 180px;
                        position: absolute;
                        top: -100px;
                        left: -32px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>
