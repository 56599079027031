<template>
	<el-dialog
		append-to-body
		top="10vh"
		:width="dialogWidth"
		:title="dialogTitle"
		:visible.sync="dialogVisible"
		:close-on-click-modal="false"
		@close="close"
	>
		<div style="margin-top:-20px;"></div>
		<template v-if="printBefore">
			<div>
				<div v-for="list in invoiceList" :key="list.invoiceNo" class="invoice_list">
					<div class="invoice_item">
						<span>单据编号：</span>
						{{ list.invoicesNo }}
					</div>
					<div class="invoice_item">
						<span>打印份数：</span>
						<el-input-number v-model="list.number" size="small" :min="0" :max="10"></el-input-number>
					</div>
				</div>
			</div>
			<div class="invoice_item">
				<span><i>*</i>打印机：</span>
				<el-select v-model="printerName" size="small" placeholder="请选择打印机">
					<el-option :value="item.deviceName" :label="item.deviceName" v-for="item in pluginList"
							   :key="item.deviceName"></el-option>
				</el-select>
			</div>
			<span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" :loading="loading" :disabled="loading" @click="surePrint">确定打印</el-button>
            </span>
		</template>
		
		<template v-else>
			<div v-if="invoiceList.length > 1" class="print_preview">
                <span
					v-for="(item, index) in invoiceList"
					:key="index"
					:style="{background: item.invoicesNo === priveSn ? '#067cf2' : '#aaa'}"
					@click="priveSn = item.invoicesNo">
                    {{ item.invoicesNo }}
                </span>
			</div>
			<div v-for="(item, index) in invoiceList" :key="index" style="overflow:hidden;">
				<webview
					style="width:100%;float:left;"
					:style="priveSn === item.invoicesNo && item.src ? 'height:500px;' : 'height:0;'"
					:src="item.src"></webview>
			</div>
		</template>
	</el-dialog>
</template>

<script>
import request from "@/libs/http";
import {ipc} from "@/utils/ipcRenderer";
import {ipcApiRoute} from "@/api/main";

export default {
	props: {
		modalParams: {//勾选单据列表
			type: Array,
			default: () => []
		},
		printKey: {//打印单id
			type: String,
			default: () => 'id'
		},
		printTemplate: {//打印模板
			type: String,
			default: () => 'DPTCD'
		},
		defaultCopies: {//默认打印份数
			type: String,
			default: () => '1'
		}
	},
	data() {
		return {
			loading: false,
			dialogVisible: true,
			printBefore: true,
			dialogTitle: '打印设置',
			priveSn: '',
			invoiceList: [],
			dialogWidth: '640px',
			printerName: '',
			pluginList: []
		}
	},
	created() {
		this.printerName = this.$cache.local.get('printerModel');
		ipc.invoke(ipcApiRoute.getPrinters, {}).then(list => {
			this.pluginList = list
		})
		this.priveSn = this.modalParams[0].sn;
		this.invoiceList = this.modalParams.map(item => {
			return {
				invoicesNo: item.sn,
				id: item[this.printKey],
				number: this.defaultCopies
			}
		})
	},
	methods: {
		close() {
			this.dialogVisible = false
			this.$emit('close')
		},
		surePrint() {
			if (!this.printerName) {
				return this.$message({type: 'error', message: '请选择打印机', duration: 2000, showClose: true});
			}
			this.loading = true;
			let rootUrl = `${request.printPreviewRoot}?printerName=${this.printerName}&templateCode=${this.printTemplate}&`
			let eggRootUrl = `${request.printEggPreviewRoot}?printerName=${this.printerName}&templateCode=${this.printTemplate}&`
			this.invoiceList.forEach(item => {
				let params = {
					id: item[this.printKey]
				}
				item.src = rootUrl + `printCount=0&data=${encodeURI(JSON.stringify(params))}&sn=${item['invoicesNo']}`
				const eggSrc = eggRootUrl + `printCount=${item.number}&data=${encodeURI(JSON.stringify(params))}&sn=${item['invoicesNo']}`;
				ipc.invoke(ipcApiRoute.print, {contentUrl: eggSrc, sn: item['invoicesNo']}).then(r => {})
			})
			this.printBefore = false;
			this.loading = false;
			this.dialogWidth = '400px';
			this.dialogTitle = '打印机正在打印';
		},
	}
}
</script>

<style lang="scss" scoped>
.invoice_list {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.invoice_item {
	display: flex;
	align-items: center;
	flex: 1;
	
	span {
		width: 100px;
		
		i {
			color: red;
			margin-right: 4px;
		}
	}
}

.print_preview {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	text-align: center;
	color: #fff;
	margin-bottom: 10px;
	
	span {
		height: 20px;
		background: #aaa;
		border-radius: 4px;
		margin-right: 10px;
		cursor: pointer;
		padding: 0 8px;
		margin-bottom: 5px;
	}
}
</style>
