//详情配置
export const columnObj = {
    PH: [
        {
            prop: 'goodsName',
            label: '货品名称'
        },
        {
            prop: 'goodsNo',
            label: '货号 (点击查看图片)'
        },
        {
            prop: 'retailPrice',
            label: '零售价'
        },
        // {
        //   prop: "price",
        //   label: "铺货价",
        // },
        {
          prop: "colorValue",
          label: "颜色",
        },
        {
            prop: 'sizeInfoList',
            width: '800px',
            boxWidth: '90px' //复选框宽度
        },
        {
            prop: 'total',
            label: '汇总',
            type: 'count',
            getTotal(row, _key) {
                return row.sizeInfoList.reduce((c, R) => c + Number(R[_key] ? R[_key] : 0), 0)
            }
        }
    ]
}
export const sizeInfoLabel = {
    PH: [
        {
            prop: 'number', //复选框key//valueInfoList当前行对应key
            label: '铺货数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true //禁用
        },
        {
            prop: 'shipmentNumber', //复选框key//valueInfoList当前行对应key
            label: '出货数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true, //禁用
            colBgc: '#fef8ef'
        },
        {
            prop: 'shipmentDifferenceNumber', //复选框key//valueInfoList当前行对应key
            label: '出货差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true, //禁用
            colBgc: '#fef8ef'
        },
        {
            prop: 'storageNumber', //复选框key//valueInfoList当前行对应key
            label: '入库数量', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true, //禁用
            colBgc: '#fafef2'
        },
        {
            prop: 'storageDifferenceNumber', //复选框key//valueInfoList当前行对应key
            label: '入库差异', //复选框名称及行数据对应名称
            checkShow: true, //是否出现复选框
            value: true, //绑定值
            disabled: true, //禁用
            colBgc: '#fafef2'
        }
    ]
}
export const footerObj = {
    PH: [
        'totalAmount',
        'getNum',
        // "phDistriPriceTotal",
        'sellPriceTotal',
        'chuhdNumTotal',
        'rukudNumTotal'
    ]
}
