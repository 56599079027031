<template>
    <section class="component">
        <div
            v-if="formShow"
            style="
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #eee;
                margin-left: 20px;
                height: 36px;
            "
        >
            <div style="display: flex">
                <span style="background: #fff200; width: 50px; text-align: center; line-height: 36px">筛选</span>
            </div>
            <span style="cursor: pointer" @click="changeFormState"
                >点击收起&nbsp;<i class="el-icon-d-arrow-right" style="margin-right: 17px"></i
            ></span>
        </div>
        <div
            v-if="!formShow"
            style="
                position: fixed;
                right: 0;
                top: 50%;
                width: 12px;
                height: 40px;
                line-height: 40px;
                border-radius: 10px 0 0 10px;
                text-align: right;
                background: #fff200;
                cursor: pointer;
                margin-top: -30px;
            "
            @click="changeFormState"
        >
            <i class="el-icon-arrow-left"></i>
        </div>
        <el-form
            v-if="formShow"
            class="search-form"
            :inline="false"
            :model="options"
            label-position="right"
            @keyup.enter.native="sureHandel"
            @submit.native.prevent
        >
            <el-form-item v-for="item in options.searchItem" :key="item.id" :label="item.label">
                <!-- cascader级联选择类型 -->
                <el-cascader
                    v-if="item.type == 'cascader'"
                    v-model="item.value"
                    style="width: 100%"
                    :size="item.size"
                    :placeholder="item.placeholder"
                    :filterable="item.filterable"
                    :clearable="item.clearable"
                    :show-all-levels="item.alllevels"
                    :options="item.list"
                    :props="item.props"
                    :separator="item.separator"
                ></el-cascader>
                <!-- input输入类型 -->
                <el-input
                    v-if="item.type == 'input'"
                    v-model="item.value"
                    :clearable="item.clearable"
                    :placeholder="item.placeholder"
                    :size="item.size"
                    :ref="item.resField"
                >
                </el-input>

                <!-- select选择类型 -->
                <el-select
                    v-if="item.type == 'select' && !item.remote"
                    :size="item.size"
                    v-model="item.value"
                    :clearable="item.clearable"
                    :placeholder="item.placeholder"
                    :filterable="item.filterable"
                    :multiple="item.multiple"
                    :collapse-tags="item.collapseTags"
                    style="width: 100%"
                >
                    <el-option v-for="item in item.list" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
                <!-- select选择类型 -->
                <el-select
                    v-if="item.type == 'select' && item.remote"
                    :size="item.size"
                    v-model="item.value"
                    :clearable="item.clearable"
                    :placeholder="item.placeholder"
                    :filterable="item.filterable"
                    :collapse-tags="item.collapseTags"
                    :multiple="item.multiple"
                    :remote="item.remote"
                    :remote-method="
                        (e) => {
                            search(e, item)
                        }
                    "
                    style="width: 100%"
                >
                    <el-option v-for="item in item.list" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
                <!-- 选择用户 -->
                <select-user
                    v-if="item.type == 'selectUser'"
                    v-model="item.value"
                    :size="item.size"
                    :clearable="item.clearable"
                    :placeholder="item.placeholder"
                    :filterable="item.filterable"
                    :remote="item.remote"
                ></select-user>

                <!-- 选择供应商 -->
                <select-supplier
                    v-if="item.type == 'selectSupplier'"
                    v-model="item.value"
                    :size="item.size"
                    :clearable="item.clearable"
                    :placeholder="item.placeholder"
                    :filterable="item.filterable"
                    :multiple="item.multiple"
                    :remote="item.remote"
                    :supplierType="item.supplierType || ''"
                ></select-supplier>
                <!-- 选择仓库 -->
                <select-entrepot
                    v-if="item.type == 'selectEntre'"
                    v-model="item.value"
                    :size="item.size"
                    :clearable="item.clearable"
                    :placeholder="item.placeholder"
                    :filterable="item.filterable"
                    :multiple="item.multiple"
                    :warehouseTypes="
                        item.selectEntreType && item.selectEntreType.length ? item.selectEntreType.join(',') : ''
                    "
                ></select-entrepot>
                <!-- 店铺选择器 -->
                <erp-selector
                    v-if="item.type == 'storeSelector'"
                    type="store"
                    v-model="item.value"
                    :multiple="item.multiple"
                    :modelKey="item.modelKey"
                    :placeholder="item.placeholder"
                ></erp-selector>
                <!-- 货号选择器 -->
<!--                <erp-selector-->
<!--                    v-if="item.type == 'goodsNoSelector'"-->
<!--                    type="goodsNo"-->
<!--                    v-model="item.value"-->
<!--                    :multiple="item.multiple"-->
<!--                    :modelKey="item.modelKey"-->
<!--                    :placeholder="item.placeholder"-->
<!--                ></erp-selector>-->
                <erp-selector
                    v-if="item.type == 'goodsNoSelector'"
                    type="goodsNo"
                    :slectShow="item.slectShow"
                    :inputShow="item.inputShow"
                    v-model="item.value"
                    :multiple="item.multiple"
                    :modelKey="item.modelKey"
                    :placeholder="item.placeholder"
                ></erp-selector>
                <erp-selector
                    v-if="item.type == 'warehouse'"
                    type="warehouse"
                    v-model="item.value"
                    :multiple="item.multiple"
                    :modelKey="item.modelKey"
                    :placeholder="item.placeholder"
                ></erp-selector>
                <!-- erp选择器 -->

                <!-- 选择用户 -->
                <!-- <el-select
                    v-if="item.type == 'selectUser'"
                    style="width: 100%"
                    v-model="item.value"
                    :size="item.size"
                    :clearable="item.clearable"
                    :placeholder="item.placeholder"
                    :filterable="item.filterable"
                    :remote="item.remote"
                    :remote-method="fetchUserList"
                >
                    <OptionUser
                        v-if="totalItem && userList.length"
                        :userList="userList"
                        :totalItem="totalItem"
                        @fatherMethod="changeUserPage"
                    >
                    </OptionUser>
                </el-select> -->
                <!-- month月份选择类型 -->
                <el-date-picker
                    v-if="item.type == 'month'"
                    v-model="item.value"
                    type="month"
                    :clearable="item.clearable"
                    :size="item.size"
                    :placeholder="item.placeholder"
                    format="yyyy-MM"
                    value-format="yyyy-MM"
                >
                </el-date-picker>

                <!--       天选择类型       -->
                <el-date-picker
                    v-if="item.type == 'day'"
                    v-model="item.value"
                    type="date"
                    :clearable="item.clearable"
                    :size="item.size"
                    :placeholder="item.placeholder"
                    format="yyyy-MM-dd"
                    value-format="yyyyMMdd"
                >
                </el-date-picker>

                <!-- daterange时间段选择 -->
                <el-date-picker
                    style="width: 100%"
                    v-if="item.type == 'daterange'"
                    v-model="item.value"
                    type="daterange"
                    :clearable="item.clearable"
                    :size="item.size"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    format="yyyy-MM-dd"
                    :value-format="item.valueFormat || 'yyyyMMdd'"
                >
                </el-date-picker>

                <!-- datetimerange日期时间段选择 -->
                <el-date-picker
                    style="width: 100%"
                    v-if="item.type == 'datetimerange'"
                    v-model="item.value"
                    type="datetimerange"
                    :clearable="item.clearable"
                    :size="item.size"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    format="yyyy-MM-dd HH:mm:ss"
                    :value-format="item.valueFormat || 'yyyy-MM-dd HH:mm:ss'"
                >
                </el-date-picker>

                <!-- radio单选 -->
                <el-radio-group v-if="item.type == 'radio'" v-model="item.value" :size="item.size">
                    <el-radio v-for="list in item.list" :key="list.name" :label="list.value">{{ list.name }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item v-if="options.sureBtnVisable || options.restBtnVisable" style="position: fixed; bottom: 25px">
                <el-button
                    @click="sureHandel"
                    v-if="options.sureBtnVisable"
                    :type="options.sureBtnType"
                    :size="options.sureBtnSize"
                    >{{ options.sureBtnText }}</el-button
                >
                <el-button
                    @click="resetHandel"
                    v-if="options.restBtnVisable"
                    :type="options.restBtnType"
                    :size="options.restBtnSize"
                    >{{ options.restBtnText }}</el-button
                >
            </el-form-item>
        </el-form>
    </section>
</template>

<script>
import selectUser from '@/components/selectUser/index'
import selectSupplier from '@/components/selectSupplier/index'
import selectEntrepot from '@/components/selectEntrepot/index'
import erpSelector from '@/components/ErpSelector/index'
export default {
    name: 'searchForm',
    components: {
        selectUser,
        selectSupplier,
        selectEntrepot,
        erpSelector
    },
    props: {
        options: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            selectList: {},
            formShow: true
            // page: 1,
            // totalItem: 0,
            // userName: '',
            // userList: []
        }
    },
    created() {
        this.$emit('getState', this.formShow)
    },
    methods: {
        changeFormState() {
            this.formShow = !this.formShow
            this.$emit('getState', this.formShow)
        },
        sureHandel() {
            this.selectList = {}
            this.options.searchItem.forEach((item) => {
                for (let key in item) {

                    // if (key == 'value' && item[key] != '' && (item[key] || item[key] == 0)) {
                    //     this.selectList[item.resField] = item.value
                    // }
                    if (key == 'value' && item[key] != '' && (item[key] || item[key] == 0)) {
                        if(item.type=='inputArray'||(item.type=='goodsNoSelector'&&item.inputShow)) {
                            if(item.value.trim().split(/[ ]+/)[0]!='') {
                                this.selectList[item.resField] = item.value.trim().split(/[ ]+/)
                            }
                        }else {
                            this.selectList[item.resField] = item.value
                        }
                    }
                }
            })
            this.$emit('searchHandel', this.selectList)
        },
        resetHandel() {
            this.options.searchItem.forEach((item) => {
                for (let key in item) {
                    if (key == 'value') {
                        item[key] = ''
                    }
                }
            })
        },
        search(e, item) {
            switch (item.remoteMethod) {
                case 'querySeriesInfoList':
                    this.fetchseriesList(e, item)
                    break
                default:
                    break
            }
        },
        fetchseriesList(e, item) {
            this.$http.post(
                this.$macro.api.querySeriesInfoList,
                {
                    page: '1',
                    pageSize: '20',
                    name: e
                },
                (data) => {
                    let arr = data.items || []
                    if (arr.length) {
                        arr.map((item) => {
                            item.value = item.id
                        })
                    }
                    this.$set(item, 'list', arr)
                },
                (errorDesc) => {
                    this.$message.error(errorDesc)
                },
                (error) => {
                    this.$message.error(error)
                },
                this
            )
        }
        // fetchUserList(name) {
        //     if (this.userName != name) {
        //         this.page = 1
        //     }
        //     this.userName = name
        //     let reqData = {
        //         name: name,
        //         page: this.page + '',
        //         pageSize: '20'
        //     }
        //     queryUserList(reqData)
        //         .then((data) => {
        //             if (data && data.items) {
        //                 this.userList = data.items || []
        //                 this.totalItem = parseInt(data.totalItem)
        //             }
        //         })
        //         .catch((err) => {
        //             this.loading = false
        //             this.$message({
        //                 type: 'error',
        //                 message: err,
        //                 duration: 2000,
        //                 showClose: true
        //             })
        //         })
        // },
        // changeUserPage(e) {
        //     this.page = e
        //     this.fetchUserList(this.userName)
        // }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor .el-range-separator {
    width: 10%;
}
.component {
    color: #333;
    font-size: 14px;
    /* margin-top: -36px; */
    background: #ffffff;
}
.search-form {
    background: #fff;
    width: 310px;
    margin-left: 20px;
    margin-top: 10px;
    overflow: auto;
    height: 75vh;
    padding-right: 20px;
}
.search-form .el-form-item {
    margin-bottom: 8px;
}
.search-form .el-input__inner {
    font-size: 14px;
}
.search-form .el-button {
    font-size: 14px;
    width: 140px;
}
.search-form .el-button--small,
.el-button--small.is-round {
    padding: 8px 15px;
}

.search-form .el-form-item--medium .el-form-item__label {
    line-height: 20px;
}
::v-deep .search-form  .el-form-item .el-form-item__content {
    line-height: 32px;
}
.search-form .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 290px;
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    border-radius: 10px;
    background-color: #fff;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    background-color: #cececeee;
}
</style>

<style>
.search-form .el-form-item__label {
    margin-bottom: -6px;
    color: #666666;
}
.search-form .el-button--primary {
    background: #fff200;
    border: 1px solid #fff200;
    color: #333;
}
</style>
