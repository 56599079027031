<template>
  <el-dialog
      title="物流轨迹"
      width="60%"
      append-to-body
      top="8vh"
      :visible.sync="dialogVisible"
      @close="close">
    <div class="track-info">
      <div>
        <span>发货方</span>
        {{expressNo.senderInfoName}}
      </div>
        <div>
            <span>联系方式</span>
            {{ expressNo.contact }}
        </div>
        <div>
            <span>发货地址</span>
            {{ expressNo.senderAddr }}
        </div>
      <div>
        <span>快递公司</span>
        {{expressNo.logisticsName}}
      </div>
      <div>
        <span>快递单号</span>
        {{expressNo.expressNo}}
      </div>
    </div>
    <el-table
        v-if="expressNo.queryType == 0"
        border
        :data="trackInfoList"
        :header-cell-style="{background:'#F3F4F7',color:'#555',padding: '5px 0'}"
        :cell-style="{padding: '5px 0'}">
      <el-table-column
          label="序号"
          type="index"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="opeTime"
          label="时间"
          align="center"
          width="300">
      </el-table-column>
      <el-table-column
          prop="opeRemark"
          label="物流轨迹"
          align="center">
      </el-table-column>
    </el-table>
    <webview v-else :src="src" style="width: 100%; height: 500px;"></webview>
  </el-dialog>
</template>

<script>
import {erp2OperatingReceiveTraceGet} from '@/libs/http/modules/erp.js'
export default {
  name: 'trackDialog',
  props: ['expressNo'],
  data(){
    return{
      dialogVisible: false,
      trackInfoList: [],
      receiverAddress: '',
      receiverPhone: '',
      src: ''
    }
  },
  created(){
    this.dialogVisible = true;
    if(this.expressNo.queryType == 0){
      this.fetchLogis();
    }else{
      this.src = `https://www.yto.net.cn/tracesimple.html/?orders=${this.expressNo.expressNo}`
    }
  },
  methods: {
    close(){
      this.dialogVisible = false;
      this.$emit('close');
    },
    fetchLogis(){
        erp2OperatingReceiveTraceGet({
            waybillCode: this.expressNo.expressNo,
            logisticsId: this.expressNo.logisticsId,
      }).then(data=>{
        if(data){
          this.trackInfoList = data.items || [];
        }
      }).catch(error=>{
        this.$message({ type: 'error', message: error, duration: 2000, showClose: true });
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.track-info{
  display: flex;
  flex-wrap: wrap;
  margin-top: -20px;
  margin-bottom: 20px;
  div{
    color: #000;
    width: calc(100% / 3);
    display: flex;
    span{
      width:120px;
      color: #666;
    }
  }
}
</style>