<template>
    <section class="table">
        <!-- 表头 -->
        <section ref="head" class="table-header">
            <div
                v-for="item in headerLeft"
                :key="item.key"
                :style="{
                    height: sizeGroupType == 3 ? '120px' : headHeight,
                    lineHeight: sizeGroupType == 3 ? '120px' : headHeight,
                    width: ['1', '3', '4', '5'].includes(sizeGroupType) ? autoWidth : ''
                }"
            >
                <span>{{ item.name }}</span>
            </div>
            <section
                class="size-group"
                v-if="sizeGroupType == 1"
                :style="{
                    height: headHeight,
                    width: sizeGroupWidth
                }"
            >
                <div
                    v-for="item in sizeGroup"
                    :key="item.valueGroupId"
                    :class="['groupOne', currentGroup == item.valueGroupId ? 'current-group' : '']"
                >
                    <span v-for="(size, index) in item.valueInfoList" :key="index">{{ size.value }}</span>
                </div>
            </section>
            <section
                class="size-group"
                v-if="sizeGroupType == 2"
                :style="{
                    height: headHeight,
                    width: sizeGroupWidth
                }"
            >
                <div
                    v-for="item in sizeGroup"
                    :key="item.valueGroupId"
                    :class="[currentGroup == item.valueGroupId ? 'current-group' : '']"
                >
                    <span v-for="(size, index) in item.valueInfoList" :key="index">{{ size.value }}</span>
                </div>
            </section>
            <section
                class="size-group"
                v-if="sizeGroupType == 3"
                :style="{
                    height: 120 + 'px',
                    width: sizeGroupWidth
                }"
            >
                <div class="groupThree">
                    <section>
                        <el-checkbox v-model="phNumber" disabled>
                            {{
                                headerLeft[0].type === 'ph'
                                    ? '铺货数量'
                                    : headerLeft[0].type === 'tc'
                                    ? '退仓数量'
                                    : '调拨数量'
                            }}
                        </el-checkbox>
                        <el-checkbox v-model="chNumber" :disabled="scheduleState >= 2 ? false : true"
                            >出货数量</el-checkbox
                        >
                        <el-checkbox v-model="chdNumber" :disabled="scheduleState >= 2 ? false : true"
                            >出货差异</el-checkbox
                        >
                        <el-checkbox v-model="rkNumber" :disabled="scheduleState > 3 ? false : true"
                            >入库数量</el-checkbox
                        >
                        <el-checkbox v-model="rkdNumber" :disabled="scheduleState > 3 ? false : true"
                            >入库差异</el-checkbox
                        >
                    </section>
                    <div>
                        <div
                            v-for="item in sizeGroup"
                            :key="item.valueGroupId"
                            :class="[currentGroup == item.valueGroupId ? 'current-group' : '']"
                        >
                            <span v-for="(size, index) in item.valueInfoList" :key="index">{{ size.value }}</span>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="size-group"
                v-if="sizeGroupType == 4 || sizeGroupType == 5"
                :style="{
                    height: headHeight,
                    width: sizeGroupWidth
                }"
            >
                <div class="groupThree">
                    <section></section>
                    <div>
                        <div
                            v-for="item in sizeGroup"
                            :key="item.valueGroupId"
                            :class="[currentGroup == item.valueGroupId ? 'current-group' : '']"
                        >
                            <span v-for="(size, index) in item.valueInfoList" :key="index">{{ size.value }}</span>
                        </div>
                    </div>
                </div>
            </section>
            <div
                :style="{
                    height: sizeGroupType == 3 ? '120px' : headHeight,
                    lineHeight: sizeGroupType == 3 ? '120px' : headHeight,
                    width: ['1', '3', '4', '5'].includes(sizeGroupType) ? autoWidth : ''
                }"
            >
                <span>汇总</span>
            </div>
            <div
                v-if="isEditor"
                :style="{
                    height: headHeight,
                    lineHeight: headHeight,
                    width: ['1', '3', '4', '5'].includes(sizeGroupType) ? autoWidth : ''
                }"
            >
                <span>操作</span>
            </div>
        </section>
        <!-- 内容区 -->
        <section
            ref="body"
            class="table-body"
            :style="{
                height: tabBodyHeight,
                maxHeight: tabBodyHeight,
                width: tableWidth
            }"
        >
            <template v-for="(item, index) in dataList">
                <div
                    v-if="(item.isVisiable && sizeGroupType != 4) || (sizeGroupType == 4 && item.show)"
                    :key="index"
                    @click="
                        currentGroup = item.valueGroupId
                        rowIndex = index
                    "
                    :class="['data-row', index == rowIndex && sizeGroupType != 4 ? 'current-row' : '']"
                >
                    <!-- 左侧数据 -->
                    <div
                        :style="{
                            width: ['1', '3', '4', '5'].includes(sizeGroupType) ? autoWidth : ''
                        }"
                    >
                        <span>{{ item.goodsName }}</span>
                    </div>
                    <div
                        :style="{
                            width: ['1', '3', '4', '5'].includes(sizeGroupType) ? autoWidth : ''
                        }"
                    >
                        <span>{{ item.goodsNo }}</span>
                    </div>
                    <div
                        :style="{
                            width: ['1', '3', '4', '5'].includes(sizeGroupType) ? autoWidth : ''
                        }"
                        v-if="headerLeft.length > 2 && headerLeft[2].key == 'retailPrice'"
                    >
                        <span>{{ item.retailPrice }}</span>
                    </div>
                    <div v-if="sizeGroupType == 1" :style="{ width: autoWidth || '' }">
                        <span>{{ item.colorValue }}</span>
                    </div>
                    <!-- <div :style="{width: sizeGroupType == 3 ? autoWidth : ''}" v-if="headerLeft.length > 2">
                    <span v-if="headerLeft[2].key == 'colorValue'">{{item.colorValue}}</span>
                    <input
                    v-else
                    v-model="item.price"
                    :disabled="!isEditor"
                    @input="checkValue(item)"
                    @focus="getInitPrice(item.price)"
                    @blur="getChangePrice(item)"
                    style="height:26px;line-height:26px;width:100%;border:none;outline:none;text-align:center;" :style="{background: !isEditor ? 'none' : ''}" />
                </div> -->

                    <section class="size-group" v-if="sizeGroupType == 1" :style="{ width: sizeGroupWidth }">
                        <div>
                            <div style="height: 30px; line-height: 30px; border-right: 1px solid #e6ebf5; width: 60px">
                                可用库存
                            </div>
                            <div
                                style="
                                    height: 30px;
                                    line-height: 30px;
                                    border-top: 1px solid #e6ebf5;
                                    border-right: 1px solid #e6ebf5;
                                    width: 60px;
                                "
                            >
                                {{
                                    headerLeft[0].type === 'tg'
                                        ? '退供数'
                                        : headerLeft[0].type === 'th'
                                        ? '退货数'
                                        : '调拨数'
                                }}
                            </div>
                        </div>
                        <!-- 尺码组数据 -->
                        <div v-for="(size, s) in item.sizeInfoList" :key="s">
                            <div style="width: 40px; height: 30px; line-height: 30px; border-right: 1px solid #e6ebf5">
                                {{ size.usableQuantity }}
                            </div>
                            <div
                                style="
                                    width: 40px;
                                    height: 30px;
                                    line-height: 30px;
                                    border-right: 1px solid #e6ebf5;
                                    border-top: 1px solid #e6ebf5;
                                "
                            >
                                <input
                                    style="
                                        border: none;
                                        outline: none;
                                        width: 36px;
                                        height: 20px;
                                        line-height: 20px;
                                        text-align: center;
                                        background: rgb(250, 205, 145);
                                    "
                                    :disabled="!isEditor"
                                    type="text"
                                    @focus="getinitVla(size.number)"
                                    @blur="getchangeVla(item, size)"
                                    v-model="size.number"
                                />
                            </div>
                        </div>
                    </section>
                    <section class="size-group" v-if="sizeGroupType == 2" :style="{ width: sizeGroupWidth }">
                        <!-- 尺码组数据 -->
                        <div v-for="(size, s) in item.sizeInfoList" :key="s">
                            <div
                                style="width: 40px; height: 30px; line-height: 30px; border-right: 1px solid #e6ebf5"
                                :style="{
                                    background: size.number < 0 ? 'rgb(244,180,187)' : ''
                                }"
                            >
                                {{ size.number }}
                            </div>
                        </div>
                    </section>
                    <section
                        class="size-group group-three"
                        v-if="sizeGroupType == 3"
                        :style="{ width: sizeGroupWidth }"
                    >
                        <div class="group-label">
                            <div v-if="phNumber">
                                {{
                                    headerLeft[0].type === 'ph'
                                        ? '铺货数量'
                                        : headerLeft[0].type === 'tc'
                                        ? '退仓数量'
                                        : '调拨数量'
                                }}
                            </div>
                            <div v-if="chNumber" style="background: rgb(254, 248, 239)">出货数量</div>
                            <div v-if="chdNumber" style="background: rgb(254, 248, 239)">出货差异</div>
                            <div v-if="rkNumber" style="background: rgb(250, 254, 242)">入库数量</div>
                            <div v-if="rkdNumber" style="background: rgb(250, 254, 242)">入库差异</div>
                        </div>
                        <div class="group-value">
                            <div v-if="phNumber">
                                <span
                                    v-for="(size, s) in item.sizeInfoList"
                                    :key="s"
                                    :style="size.number < 0 ? 'background:#F4B4BB;color:#fff' : ''"
                                    >{{ size.number }}</span
                                >
                            </div>
                            <div v-if="chNumber" style="background: rgb(254, 248, 239)">
                                <span
                                    v-for="(size, s) in item.sizeInfoList"
                                    :key="s"
                                    :style="size.shipmentNumber < 0 ? 'background:#F4B4BB;color:#fff' : ''"
                                    >{{ size.shipmentNumber }}</span
                                >
                            </div>
                            <div v-if="chdNumber" style="background: rgb(254, 248, 239)">
                                <span
                                    v-for="(size, s) in item.sizeInfoList"
                                    :key="s"
                                    :style="size.shipmentDifferenceNumber < 0 ? 'background:#F4B4BB;color:#fff' : ''"
                                    >{{ size.shipmentDifferenceNumber }}</span
                                >
                            </div>
                            <div v-if="rkNumber" style="background: rgb(250, 254, 242)">
                                <span
                                    v-for="(size, s) in item.sizeInfoList"
                                    :key="s"
                                    :style="size.storageNumber < 0 ? 'background:#F4B4BB;color:#fff' : ''"
                                    >{{ size.storageNumber }}</span
                                >
                            </div>
                            <div v-if="rkdNumber" style="background: rgb(250, 254, 242)">
                                <span
                                    v-for="(size, s) in item.sizeInfoList"
                                    :key="s"
                                    :style="size.storageDifferenceNumber < 0 ? 'background:#F4B4BB;color:#fff' : ''"
                                    >{{ size.storageDifferenceNumber }}</span
                                >
                            </div>
                        </div>
                    </section>
                    <section
                        class="size-group group-three"
                        v-if="sizeGroupType == 4 || sizeGroupType == 5"
                        :style="{ width: sizeGroupWidth }"
                    >
                        <div class="group-label">
                            <div style="background: #fff">总</div>
                            <div style="background: #fff" v-if="sizeGroupType == 4">已</div>
                            <div style="background: #fff" v-if="sizeGroupType == 4">未</div>
                            <div style="background: #fff" v-if="sizeGroupType == 5">已</div>
                            <div style="background: #fff" v-if="sizeGroupType == 5">未</div>
                        </div>
                        <div class="group-value">
                            <div v-if="sizeGroupType == 4">
                                <span v-for="(size, s) in item.sizeInfoList" :key="s">{{ size.shipmentNumber }}</span>
                            </div>
                            <div v-else>
                                <span v-for="(size, s) in item.sizeInfoList" :key="s">{{ size.shouldPickNumber }}</span>
                            </div>
                            <div v-if="sizeGroupType == 4" class="group-value-row">
                                <!-- <span v-for="(size, s) in item.sizeInfoList" :key="s" :style="size.barCode == searchInNo?'background:#067CF2;fontWeight:bold;color:#fff':''">{{size.checkNumber}}</span> -->
                                <template v-for="(size, s) in item.sizeInfoList">
                                    <input
                                        v-model="size.checkNumber"
                                        :key="s"
                                        class="input-item"
                                        :class="[getRowBgc(size), size.shipmentNumber == 0 ? 'is-disabled' : '']"
                                        :disabled="size.shipmentNumber == 0"
                                        @input="
                                            () => {
                                                size.checkNumber = +size.checkNumber.toString().replace(/[^\d]/g, '')
                                            }
                                        "
                                        @change="checkNumberChange(size, 'checkNumber')"
                                        @focus="getCheckNumber(size, 'checkNumber')"
                                    />
                                </template>
                            </div>
                            <div v-else>
                                <!-- <span
                  v-for="(size, s) in item.sizeInfoList"
                  :key="s"
                  :style="
                    size.barCode == searchInNo
                      ? 'background:#067CF2;fontWeight:bold;color:#fff'
                      : ''
                  "
                  >{{ size.actualPickNumber }}</span
                > -->
                                <template v-for="(size, s) in item.sizeInfoList">
                                    <input
                                        v-model="size.actualPickNumber"
                                        :key="s"
                                        class="input-item"
                                        :class="[getRowBgc(size), size.shipmentNumber == 0 ? 'is-disabled' : '']"
                                        :disabled="size.shipmentNumber == 0"
                                        @input="
                                            () => {
                                                size.actualPickNumber = +size.actualPickNumber
                                                    .toString()
                                                    .replace(/[^\d]/g, '')
                                            }
                                        "
                                        @change="checkNumberChange(size, 'actualPickNumber')"
                                        @focus="getCheckNumber(size, 'actualPickNumber')"
                                    />
                                </template>
                            </div>
                            <div v-if="sizeGroupType == 4">
                                <span
                                    v-for="(size, s) in item.sizeInfoList"
                                    :key="s"
                                    :style="size.surplusNumber > 0 ? 'color:#F8475F;fontWeight:bold' : ''"
                                    >{{ size.surplusNumber }}</span
                                >
                            </div>
                            <div v-else>
                                <span
                                    v-for="(size, s) in item.sizeInfoList"
                                    :key="s"
                                    :style="size.notPickNumber > 0 ? 'color:#F8475F;fontWeight:bold' : ''"
                                    >{{ size.notPickNumber }}</span
                                >
                            </div>
                        </div>
                    </section>
                    <div
                        v-if="sizeGroupType == 1"
                        style="display: flex; flex-direction: column"
                        :style="{ width: autoWidth || '' }"
                    >
                        <div style="line-height: 30px; width: 100%">
                            {{ item.stockAll }}
                        </div>
                        <div style="line-height: 30px; width: 100%; border-top: 1px solid #e6ebf5">
                            {{ item.salesAll }}
                        </div>
                    </div>
                    <div
                        v-if="sizeGroupType == 2"
                        style="display: flex; flex-direction: column"
                        :class="[sizeGroupType == 2 && dataList.length < 8 ? '' : 'change-width']"
                    >
                        <div style="height: 30px; line-height: 30px; width: 100%">
                            {{ item.numberAll }}
                        </div>
                    </div>
                    <div
                        v-if="sizeGroupType == 3"
                        style="display: flex; flex-direction: column"
                        :class="[sizeGroupType == 3 && dataList.length < 8 ? '' : 'change-width']"
                        :style="{
                            width: ['1', '3', '4', '5'].includes(sizeGroupType) ? autoWidth : ''
                        }"
                    >
                        <div v-if="phNumber" style="line-height: 30px; width: 100%">
                            {{ item.phAll }}
                        </div>
                        <div
                            v-if="chNumber"
                            style="
                                line-height: 30px;
                                width: 100%;
                                border-top: 1px solid #e6ebf5;
                                background: rgb(254, 248, 239);
                            "
                        >
                            {{ item.chAll }}
                        </div>
                        <div
                            v-if="chdNumber"
                            style="
                                line-height: 30px;
                                width: 100%;
                                border-top: 1px solid #e6ebf5;
                                background: rgb(254, 248, 239);
                            "
                        >
                            {{ item.chdAll }}
                        </div>
                        <div
                            v-if="rkNumber"
                            style="
                                line-height: 30px;
                                width: 100%;
                                border-top: 1px solid #e6ebf5;
                                background: rgb(250, 254, 242);
                            "
                        >
                            {{ item.rkAll }}
                        </div>
                        <div
                            v-if="rkdNumber"
                            style="
                                line-height: 30px;
                                width: 100%;
                                border-top: 1px solid #e6ebf5;
                                background: rgb(250, 254, 242);
                            "
                        >
                            {{ item.rkdAll }}
                        </div>
                    </div>
                    <div
                        v-if="sizeGroupType == 4 || sizeGroupType == 5"
                        style="display: flex; flex-direction: column"
                        :class="[
                            sizeGroupType == 4 || (sizeGroupType == 5 && dataList.length < 8) ? '' : 'change-width'
                        ]"
                        :style="{
                            width: autoWidth
                        }"
                    >
                        <div style="line-height: 30px; width: 100%; border-top: 1px solid #e6ebf5; background: #fff">
                            {{ item.chAll }}
                        </div>
                        <div style="line-height: 30px; width: 100%; border-top: 1px solid #e6ebf5; background: #fff">
                            {{ item.chdAll }}
                        </div>
                        <div style="line-height: 30px; width: 100%; border-top: 1px solid #e6ebf5; background: #fff">
                            {{ item.rkAll }}
                        </div>
                    </div>
                    <div
                        v-if="sizeGroupType == 1"
                        :class="[sizeGroupType == 1 && dataList.length < 8 ? '' : 'change-width']"
                        :style="{ width: autoWidth || '' }"
                    >
                        <el-button type="text" @click="delRow(index)">删除</el-button>
                    </div>
                </div>
            </template>
        </section>
        <!-- 合计行 -->
        <section
            class="total-row"
            v-if="sizeGroupType == 2 || sizeGroupType == 3 || sizeGroupType == 4 || sizeGroupType == 5"
            :style="{ width: tableWidth }"
        >
            <div
                style="display: flex; align-items: center; font-size: 14px; font-weight: bold"
                v-if="collectNumberList.length"
            >
                <div style="width: 80px">合计</div>
                <div style="margin-left: 140px; display: flex">
                    <div
                        v-for="(item, index) in collectNumberList"
                        :key="index"
                        style="border-left: 1px solid #e6ebf5; width: 40px; text-align: center"
                    >
                        {{ item }}
                    </div>
                    <div style="width: 80px; text-align: center; border-left: 1px solid #e6ebf5; margin-left: 10px">
                        {{ totalNum }}
                    </div>
                </div>
            </div>
            <template v-else>
                合计
                <span
                    >总款数：<span style="color: #02a7f0; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >{{ totalAmount }}个</span
                    ></span
                >
                <span v-if="sizeGroupType != 4 && sizeGroupType != 5"
                    >零售总价：<span style="color: #02a7f0; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >¥{{ totalObj.sellPriceTotal }}</span
                    ></span
                >
                <!-- <span v-if="sizeGroupType != 4 && sizeGroupType != 5"
          >总价：<span
            style="
              color: #02a7f0;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >¥{{ totalObj.distriPriceTotal }}</span
          ></span
        > -->
                <span
                    >总件数：<span style="color: #02a7f0; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >{{ totalObj.distriNumTotal }}件</span
                    ></span
                >
                <span v-if="sizeGroupType == 4 || sizeGroupType == 5"
                    >已：<span style="color: #02a7f0; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >{{ totalObj.ReceivedTotal }}件</span
                    ></span
                >
                <span v-if="sizeGroupType == 4 || sizeGroupType == 5"
                    >未：<span style="color: #f8475f; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >{{ totalObj.noReceivedTotal }}件</span
                    ></span
                >
                <span v-if="scheduleState >= 2"
                    >出货差异总数：<span style="color: #02a7f0; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >{{ totalObj.chuhdNumTotal }}件</span
                    ></span
                >
                <span v-if="scheduleState > 3"
                    >入库差异总数：<span style="color: #02a7f0; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >{{ totalObj.rukudNumTotal }}件</span
                    ></span
                >
            </template>
        </section>
    </section>
</template>

<script>
import { erpQuerySizeInfo } from '@/libs/http/api.js'
export default {
    name: 'puhuoTable',
    props: {
        headerLeft: {
            //表格尺码组左侧数据
            type: Array,
            default: () => []
        },
        addedList: {
            //表格数据
            type: Array,
            default: () => []
        },
        tabBodyHeight: {
            //表格高度
            type: String,
            default: 'auto'
        },
        isEditor: {
            //单元格是否可编辑，如铺货价、铺货数量
            type: Boolean,
            default: true
        },
        sizeGroupType: {
            //尺码组类型，控制不同显示样式
            type: String,
            default: '1'
        },
        sizeGroupWidth: {
            //尺码组宽度
            type: String,
            default: '450px'
        },
        collectNumberList: {
            //尺码列汇总
            type: Array,
            default: () => []
        },
        scheduleState: {
            //控制各种数量是否显示
            type: String,
            default: '1'
        },
        searchInNo: {
            //当前操作的是哪个条码
            type: String,
            default: ''
        },
        isShowRow: {
            //控制这行是否显示，sizeGroupType = 4
            type: Boolean,
            default: false
        },
        calcDivNum: {
            //设置自动宽的div个数
            type: String,
            default: '5'
        },
        outWidth: {
            //表格总宽度
            type: String,
            default: '1100'
        }
    },
    emites: ['updateCheckNumber'],
    data() {
        return {
            tableWidth: '',
            rowIndex: null,
            currentGroup: '',
            sizeGroup: [],
            dataList: [],
            curSizeVal: '',
            curPriceVal: '',
            phNumber: true,
            chNumber: false,
            chdNumber: false,
            rkNumber: false,
            rkdNumber: false,
            totalObj: {
                sellPriceTotal: 0, //零售总价
                distriPriceTotal: 0, //铺货总价
                distriNumTotal: 0, //铺货总数量
                chuhNumTotal: 0, //出货总数量
                chuhdNumTotal: 0, //出货差异总数量
                rukuNumTotal: 0, //入库总数量
                rukudNumTotal: 0, //入库差异总数量
                ReceivedTotal: 0, //已收总数量
                noReceivedTotal: 0 //已收总数量
            },
            totalNum: 0,
            checkHistoryNumber: 0,
            valueGroupIdList: []
        }
    },
    watch: {
        addedList() {
            this.dataList = []
            this.editDataHandel()
        },
        collectNumberList() {
            this.totalNum = this.collectNumberList.reduce((c, R) => c + Number(R), 0)
        },
        isShowRow() {
            this.dataList = []
            this.editDataHandel()
        },
        dataList() {
            this.valueGroupIdList = []
            this.dataList.forEach((item) => {
                if (item.valueGroupId) {
                    this.valueGroupIdList.push(item.valueGroupId)
                }
            })
            if (this.valueGroupIdList.length) {
                this.fetchSizeInfo()
            } else {
                this.sizeGroup = []
            }
        }
    },
    computed: {
        autoWidth() {
            let groupwidth = this.sizeGroupWidth.slice(0, this.sizeGroupWidth.length - 2)
            return (this.outWidth - groupwidth) / this.calcDivNum + 'px'
        },
        //总款数
        totalAmount() {
            let list = []
            this.dataList.forEach((item) => {
                if (!list.includes(item.spuInfoId)) {
                    list.push(item.spuInfoId)
                }
            })
            return list.length
        },
        headHeight() {
            if (this.sizeGroup.length) {
                return `${26 * this.sizeGroup.length + 10}px`
            } else {
                return '36px'
            }
        }
    },
    created() {
        // this.fetchSizeInfo();
        this.$nextTick(() => {
            this.tableWidth = this.$refs.head.offsetWidth + 'px'
        })
        if (this.scheduleState >= 2) {
            this.chNumber = true
            this.chdNumber = true
        }
        if (this.scheduleState > 3) {
            this.rkNumber = true
            this.rkdNumber = true
        }
    },
    methods: {
        fetchSizeInfo() {
            erpQuerySizeInfo({ valueGroupIds: this.valueGroupIdList })
                .then((data) => {
                    if (data && data.length) {
                        this.sizeGroup = data
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        editDataHandel() {
            const list = JSON.parse(JSON.stringify(this.addedList))
            this.totalObj.distriNumTotal = 0
            this.totalObj.ReceivedTotal = 0
            this.totalObj.noReceivedTotal = 0
            list.map((item) => {
                item.sizeInfoList.forEach((size) => {
                    if (!size.number) {
                        this.$set(size, 'number', '0')
                    }
                })
                const stockAll = item.sizeInfoList.reduce((c, R) => c + Number(R.usableQuantity), 0)
                const salesAll = item.sizeInfoList.reduce((c, R) => c + Number(R.number), 0)
                const numberAll = item.sizeInfoList.reduce((c, R) => c + Number(R.number), 0)
                this.$set(item, 'stockAll', stockAll)
                this.$set(item, 'salesAll', salesAll)
                this.$set(item, 'numberAll', numberAll)
                this.$set(item, 'isVisiable', true)
                if (this.sizeGroupType == 3) {
                    const phAll = item.sizeInfoList.reduce((c, R) => c + Number(R.number), 0)
                    const chAll = item.sizeInfoList.reduce((c, R) => c + Number(R.shipmentNumber), 0)
                    const chdAll = item.sizeInfoList.reduce((c, R) => c + Number(R.shipmentDifferenceNumber), 0)
                    const rkAll = item.sizeInfoList.reduce((c, R) => c + Number(R.storageNumber), 0)
                    const rkdAll = item.sizeInfoList.reduce((c, R) => c + Number(R.storageDifferenceNumber), 0)
                    this.$set(item, 'phAll', phAll)
                    this.$set(item, 'chAll', chAll)
                    this.$set(item, 'chdAll', chdAll)
                    this.$set(item, 'rkAll', rkAll)
                    this.$set(item, 'rkdAll', rkdAll)
                    this.totalObj.distriNumTotal = Number(this.totalObj.distriNumTotal) + Number(item.phAll)
                    this.totalObj.chuhNumTotal = Number(this.totalObj.chuhNumTotal) + Number(item.chAll)
                    this.totalObj.chuhdNumTotal = Number(this.totalObj.chuhdNumTotal) + Number(item.chdAll)
                    this.totalObj.rukuNumTotal = Number(this.totalObj.rukuNumTotal) + Number(item.rkAll)
                    this.totalObj.rukudNumTotal = Number(this.totalObj.rukudNumTotal) + Number(item.rkdAll)
                    this.totalObj.sellPriceTotal = (
                        Number(this.totalObj.sellPriceTotal) +
                        Number(item.retailPrice) * item.phAll
                    ).toFixed(2)
                    this.totalObj.distriPriceTotal = (
                        Number(this.totalObj.distriPriceTotal) +
                        Number(item.price) * item.phAll
                    ).toFixed(2)
                }
                if (this.sizeGroupType == 4) {
                    const chAll = item.sizeInfoList.reduce((c, R) => c + Number(R.shipmentNumber), 0)
                    const chdAll = item.sizeInfoList.reduce((c, R) => c + Number(R.checkNumber), 0)
                    const rkAll = item.sizeInfoList.reduce((c, R) => c + Number(R.surplusNumber), 0)
                    this.$set(item, 'chAll', chAll)
                    this.$set(item, 'chdAll', chdAll)
                    this.$set(item, 'rkAll', rkAll)
                    this.$set(item, 'show', true)
                    this.totalObj.distriNumTotal = Number(this.totalObj.distriNumTotal) + Number(item.chAll)
                    this.totalObj.ReceivedTotal = Number(this.totalObj.ReceivedTotal) + Number(item.chdAll)
                    this.totalObj.noReceivedTotal = Number(this.totalObj.noReceivedTotal) + Number(item.rkAll)
                    if (this.isShowRow && rkAll <= 0) {
                        this.$set(item, 'show', false)
                    }
                }
                if (this.sizeGroupType == 5) {
                    const chAll = item.sizeInfoList.reduce(
                        (c, R) => c + Number(R.shouldPickNumber ? R.shouldPickNumber : 0),
                        0
                    )
                    const chdAll = item.sizeInfoList.reduce(
                        (c, R) => c + Number(R.actualPickNumber ? R.actualPickNumber : 0),
                        0
                    )
                    const rkAll = item.sizeInfoList.reduce(
                        (c, R) => c + Number(R.notPickNumber ? R.notPickNumber : 0),
                        0
                    )
                    this.$set(item, 'chAll', chAll)
                    this.$set(item, 'chdAll', chdAll)
                    this.$set(item, 'rkAll', rkAll)
                    this.$set(item, 'isVisiable', true)
                    this.totalObj.distriNumTotal = Number(this.totalObj.distriNumTotal) + Number(item.chAll)
                    this.totalObj.ReceivedTotal = Number(this.totalObj.ReceivedTotal) + Number(item.chdAll)
                    this.totalObj.noReceivedTotal = Number(this.totalObj.noReceivedTotal) + Number(item.rkAll)
                    if (this.isShowRow && rkAll <= 0) {
                        this.$set(item, 'isVisiable', false)
                    }
                }

                // 设置唯一标识字段用于去重
                if (this.sizeGroupType == 7 || this.sizeGroupType == 9 || this.sizeGroupType == 11) {
                    let soleLabel = item.spuInfoId + item.batchNo + item.invoicesNo + item.colorValue
                    this.$set(item, 'soleLabel', soleLabel)
                } else if (this.sizeGroupType == 10) {
                    let soleLabel = item.upstreamNo + item.spuInfoId + item.invoicesNo + item.colorValue //解决采购单尺码规格一样被去重问题(设置key为单号)
                    this.$set(item, 'soleLabel', soleLabel)
                } else {
                    if (item.colorValue) {
                        let soleLabel = item.spuInfoId + item.colorValue
                        this.$set(item, 'soleLabel', soleLabel)
                        if (this.headerLeft[0].uniqueId === 'deptId') {
                            this.$set(item, 'soleLabel', index)
                        }
                    } else {
                        this.$set(item, 'soleLabel', item.spuInfoId)
                    }
                }
            })
            this.dataList = this.uniqueFunc(this.dataList.concat(list), 'soleLabel')
        },
        uniqueFunc(arr, uniId) {
            const res = new Map()
            return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1))
        },
        queryList(No) {
            this.dataList.forEach((item) => {
                if (item.goodsNo.includes(No)) {
                    item.isVisiable = true
                } else {
                    item.isVisiable = false
                }
            })
        },
        getsendData() {
            let sendArr = []
            this.dataList.forEach((item) => {
                item.sizeInfoList.forEach((size) => {
                    let state =
                        this.headerLeft[0].type === 'tc' || (this.headerLeft[0].type !== 'tc' && size.number > 0)
                    if (state) {
                        sendArr.push({
                            spu: item.spu,
                            price: item.price,
                            goodsNo: item.goodsNo,
                            spuInfoId: item.spuInfoId,
                            retailPrice: item.retailPrice,
                            sku: size.sku,
                            barCode: size.barCode,
                            skuInfoId: size.skuInfoId,
                            number: size.number
                        })
                    }
                })
            })
            return sendArr
        },
        delRow(index) {
            this.$confirm('是否删除该条记录', '提示')
                .then(() => {
                    this.dataList.splice(index, 1)
                    this.$message.success('操作成功')
                })
                .catch(() => {})
        },
        getinitVla(val) {
            this.curSizeVal = val
        },
        getchangeVla(item, size) {
            this.$set(size, 'number', +size.number)
            if (size.number == this.curSizeVal) return
            if (!/(^[0-9]\d*$)/.test(size.number)) {
                size.number = this.curSizeVal
                return this.$message.error('请输入大于或者等于0的整数')
            }
            //   退仓不再限制数量
            if (this.headerLeft[0].type == 'db') {
                if (Number(size.number) > Number(size.usableQuantity)) {
                    size.number = this.curSizeVal
                    return this.$message.error('调拨数不能大于可以库存')
                }
            }

            item.salesAll = item.sizeInfoList.reduce((c, R) => c + Number(R.number), 0)
            this.$message.success('操作成功')
        },
        getInitPrice(val) {
            this.curPriceVal = val
        },
        clearAll() {
            this.$confirm('是否将记录清空', '提示')
                .then(() => {
                    this.dataList = []
                    this.$message.success('操作成功')
                })
                .catch(() => {})
        },
        checkValue(item) {
            item.price = item.price.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
        },
        getChangePrice(item) {
            if (item.price === this.curPriceVal) return
            if (isNaN(item.price) && !item.price) {
                item.price = this.curPriceVal
                return this.$message.error('请输入有效值')
            }
            this.$message.success('操作成功')
        },
        getRowBgc(size) {
            if (+this.totalObj.noReceivedTotal === 0) {
                return 'row-bg-red'
            }
            if (
                (this.sizeGroupType == 4 && +size.surplusNumber === 0) ||
                (this.sizeGroupType == 5 && +size.notPickNumber === 0)
            ) {
                return 'row-bg-green'
            }
        },
        checkNumberChange(row, key) {
            if (row[key] > row.shipmentNumber) {
                this.$message.error(`可验收数量为${row.shipmentNumber}`)
                this.$set(row, key, this.checkHistoryNumber)
                return
            }
            //   this.$set(row, "bgShow", row.checkNumber > 0);
            this.$emit(
                'updateCheckNumber',
                {
                    checkHistoryNumber: this.checkHistoryNumber,
                    ...row
                },
                key
            )
        },
        getCheckNumber(row, key) {
            this.checkHistoryNumber = row[key]
        }
    }
}
</script>

<style lang="scss" scoped>
.row-bg-red {
    background-color: #f8475f !important;
}
.row-bg-green {
    background-color: #43d268 !important;
}
.row-bg-blue {
    background-color: #067cf2 !important;
}
.table {
    width: 100%;
    // overflow:auto;
    box-sizing: border-box;
    font-size: 12px;
}
.table-header {
    background: #fafafa;
    font-size: 14px;
    border: 1px solid #e6ebf5;
    overflow: hidden;
    display: inline-flex;
    > div {
        text-align: center;
        border-right: 1px solid #e6ebf5;
        float: left;
        // width: 80px;
        &:last-child {
            border: none;
        }
    }
    .size-group {
        border-right: 1px solid #e6ebf5;
        padding: 5px 0;
        float: left;
        div {
            text-align: left;
            span {
                border: 1px solid #eee;
                background: #fff;
                width: 40px;
                height: 26px;
                line-height: 26px;
                display: inline-block;
                text-align: center;
            }
        }
        .current-group {
            span {
                background: rgb(194, 234, 252);
            }
        }
        .groupOne {
            margin-left: 60px;
        }
        .groupTwo {
            display: flex;
            align-items: center;
        }
        .groupThree {
            display: flex;
            align-items: center;
            section {
                width: 90px;
                padding-left: 5px;
            }
        }
    }
}

.table-body {
    border: 1px solid #e6ebf5;
    border-top: none;
    overflow: auto;
    .current-row {
        background: #ebf7ff;
        div {
            background: #ebf7ff !important;
        }
    }
    .data-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e6ebf5;
        overflow: hidden;
        > div {
            //   width: 80px;
            text-align: center;
            border-right: 1px solid #e6ebf5;
            display: flex;
            align-self: stretch;
            align-items: center;
            justify-content: center;
            &:last-child {
                border: none;
            }
        }
        .change-width {
            width: 74px;
        }
        .size-group {
            display: flex;
            text-align: center;
            border-right: 1px solid #e6ebf5;
        }
        .group-three {
            .group-label {
                width: 90px;
                div {
                    height: 31px;
                    line-height: 31px;
                    border-bottom: 1px solid #e6ebf5;
                    border-right: 1px solid #e6ebf5;
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
            .group-value {
                div {
                    span {
                        display: inline-block;
                        width: 40px;
                        height: 31px;
                        line-height: 31px;
                        border-right: 1px solid #e6ebf5;
                        border-bottom: 1px solid #e6ebf5;
                    }
                    & > .input-item {
                        border: none;
                        text-align: center;
                        display: inline-block;
                        width: 40px;
                        height: 31px;
                        line-height: 31px;
                        border-right: 1px solid #e6ebf5;
                        border-bottom: 1px solid #e6ebf5;
                        background-color: #facd91;
                    }
                    .is-disabled {
                        cursor: not-allowed;
                        // background-color: #f5f7fa;
                    }
                    //   .is-updated {
                    //     background-color: #43d268;
                    //   }
                    &:last-child span {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
.total-row {
    height: 34px;
    line-height: 34px;
    display: flex;
    background: #f3f4f7;
    padding: 0 20px;
    span {
        margin-left: 20px;
    }
}

.table-body::-webkit-scrollbar {
    width: 6px !important;
    background: #eee;
}
.table-body {
    -ms-overflow-style: none;
}
.table-body {
    overflow: -moz-scrollbars-none;
}
.table-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: #78b4b4;
}
</style>
<style>
.size-group .el-checkbox__label {
    padding-left: 5px;
}
</style>
