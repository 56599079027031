<template>
  <section class="table">
    <section ref="head" class="table-header">
      <!-- 左侧动态数组 -->
      <div
        v-for="item in headerLeft"
        :key="item.key"
        :style="{
          height:
            sizeGroupType == 3 || sizeGroupType == 11
              ? 120 + 'px'
              : sizeGroupType == 10
              ? 80 + 'px'
              : 26 * (sizeGroup.length || 1) + 10 + 'px',
          lineHeight:
            sizeGroupType == 3 || sizeGroupType == 11
              ? 120 + 'px'
              : sizeGroupType == 10
              ? 80 + 'px'
              : 26 * (sizeGroup.length || 1) + 10 + 'px',
          width:
            sizeGroupType == 2 ||
            sizeGroupType == 15 ||
            sizeGroupType == 3 ||
            sizeGroupType == 4
              ? autoWidth
              : '',
          minHeight:
            sizeGroupType == 3 || sizeGroupType == 11
              ? 120 + 'px'
              : sizeGroupType == 10
              ? 80 + 'px'
              : '',
        }"
      >
        <span>{{ item.name }}</span>
      </div>
      <!-- 表头尺码组列 通过sizeGroupType控制不同尺码组数据内容 -->
      <template v-if="valueGroupIdList.length <= 0">
        <section
          class="size-group"
          :style="{ height: 26 * 1 + 10 + 'px', width: sizeGroupWidth }"
        >
          <div
            v-for="item in 1"
            :key="item"
            style="text-align: center; line-height: 30px"
          >
            尺码组
          </div>
        </section>
      </template>
      <template v-else>
        <section
          class="size-group"
          v-if="
            sizeGroupType == 1 ||
            sizeGroupType == 5 ||
            sizeGroupType == 7 ||
            sizeGroupType == 9 ||
            (sizeGroupType == 12 && headerLeft[0].ofType == 2)
          "
          :style="{
            height: 26 * sizeGroup.length + 10 + 'px',
            width: sizeGroupWidth,
          }"
        >
          <div
            v-for="item in sizeGroup"
            :key="item.valueGroupId"
            :class="[currentGroup == item.valueGroupId ? 'current-group' : '']"
            style="margin-left: 60px"
          >
            <span v-for="(size, index) in item.valueInfoList" :key="index">{{
              size.value
            }}</span>
          </div>
        </section>
        <section
          class="size-group"
          v-if="
            sizeGroupType == 2 ||
            sizeGroupType == 15 ||
            sizeGroupType == 6 ||
            sizeGroupType == 8 ||
            (sizeGroupType == 12 && headerLeft[0].ofType != 2)
          "
          :style="{
            height: 26 * sizeGroup.length + 10 + 'px',
            width: sizeGroupWidth,
          }"
        >
          <div
            v-for="item in sizeGroup"
            :key="item.valueGroupId"
            :class="[currentGroup == item.valueGroupId ? 'current-group' : '']"
          >
            <span v-for="(size, index) in item.valueInfoList" :key="index">{{
              size.value
            }}</span>
          </div>
        </section>
        <section
          class="size-group"
          v-if="
            sizeGroupType == 3 ||
            sizeGroupType == 4 ||
            sizeGroupType == 10 ||
            sizeGroupType == 11 ||
            sizeGroupType == 14 ||
            sizeGroupType == 13
          "
          :style="{
            height: 26 * sizeGroup.length + 10 + 'px',
            width: sizeGroupWidth,
            minHeight:
              sizeGroupType == 3 || sizeGroupType == 11
                ? 120 + 'px'
                : sizeGroupType == 10
                ? 80 + 'px'
                : '',
          }"
        >
          <div style="display: flex; align-items: center">
            <section style="width: 90px; padding-left: 5px">
              <template v-if="sizeGroupType == 3">
                <el-checkbox v-model="phNumber" disabled>
                  {{
                    headerLeft[0].type === "ph"
                      ? "铺货数量"
                      : headerLeft[0].type === "tc"
                      ? "退仓数量"
                      : "调拨数量"
                  }}
                </el-checkbox>
                <el-checkbox
                  v-model="chNumber"
                  :disabled="scheduleState >= 2 ? false : true"
                  >出货数量</el-checkbox
                >
                <el-checkbox
                  v-model="chdNumber"
                  :disabled="scheduleState >= 2 ? false : true"
                  >出货差异</el-checkbox
                >
                <el-checkbox
                  v-model="rkNumber"
                  :disabled="scheduleState > 3 ? false : true"
                  >入库数量</el-checkbox
                >
                <el-checkbox
                  v-model="rkdNumber"
                  :disabled="scheduleState > 3 ? false : true"
                  >入库差异</el-checkbox
                >
              </template>
              <template v-if="sizeGroupType == 10">
                <el-checkbox v-model="phNumber" disabled
                  >{{
                    headerLeft[0].type === "th" ? "提货" : "退供"
                  }}数量</el-checkbox
                >
                <el-checkbox
                  v-model="chNumber"
                  :disabled="scheduleState >= 2 ? false : true"
                  >{{
                    headerLeft[0].type === "th" ? "入库" : "出货"
                  }}数量</el-checkbox
                >
                <el-checkbox
                  v-model="chdNumber"
                  :disabled="scheduleState >= 2 ? false : true"
                  >{{
                    headerLeft[0].type === "th" ? "入库" : "出货"
                  }}差异</el-checkbox
                >
              </template>
              <template v-if="sizeGroupType == 11">
                <el-checkbox v-model="phNumber" disabled>采购数量</el-checkbox>
                <el-checkbox
                  v-model="chNumber"
                  :disabled="scheduleState >= 2 ? false : true"
                  >已提数量</el-checkbox
                >
                <el-checkbox
                  v-model="chdNumber"
                  :disabled="scheduleState >= 2 ? false : true"
                  >未提数量</el-checkbox
                >
                <el-checkbox
                  v-if="!supplierType"
                  v-model="chdNumber"
                  :disabled="scheduleState >= 2 ? false : true"
                  >入库数量</el-checkbox
                >
                <el-checkbox
                  v-model="chdNumber"
                  :disabled="scheduleState >= 2 ? false : true"
                  >退供数量</el-checkbox
                >
              </template>
            </section>
            <div>
              <div
                v-for="item in sizeGroup"
                :key="item.valueGroupId"
                :class="[
                  currentGroup == item.valueGroupId ? 'current-group' : '',
                ]"
              >
                <span
                  v-for="(size, index) in item.valueInfoList"
                  :key="index"
                  >{{ size.value }}</span
                >
              </div>
            </div>
          </div>
        </section>
      </template>

      <!-- 表头右侧列 -->
      <div
        v-for="item in headerRight"
        :key="item.key"
        :style="{
          height:
            sizeGroupType == 3 || sizeGroupType == 11
              ? 120 + 'px'
              : sizeGroupType == 10
              ? 80 + 'px'
              : 26 * (sizeGroup.length || 1) + 10 + 'px',
          lineHeight:
            sizeGroupType == 3 || sizeGroupType == 11
              ? 120 + 'px'
              : sizeGroupType == 10
              ? 80 + 'px'
              : 26 * (sizeGroup.length || 1) + 10 + 'px',
          width:
            sizeGroupType == 2 ||
            sizeGroupType == 15 ||
            sizeGroupType == 3 ||
            sizeGroupType == 4
              ? autoWidth
              : sizeGroupType == 12
              ? '120px'
              : item.width
              ? item.width
              : '',
          minHeight:
            sizeGroupType == 3 || sizeGroupType == 11
              ? 120 + 'px'
              : sizeGroupType == 10
              ? 80 + 'px'
              : '',
        }"
      >
        <span>{{ item.name }}</span>
      </div>
    </section>

    <!-- 表格内容区 width: !isAdd ? tableWidth : ''-->
    <section
      ref="body"
      class="table-body"
      :style="{
        height: tabBodyHeight,
        maxHeight: tabBodyHeight,
        width: tableWidth,
      }"
    >
      <template v-for="(item, index) in dataList">
        <div
          v-if="
            (item.isVisiable && sizeGroupType != 4) ||
            (sizeGroupType == 4 && item.show)
          "
          :key="index"
          @click="
            currentGroup = item.valueGroupId;
            rowIndex = index;
          "
          :class="['data-row', index == rowIndex ? 'current-row' : '']"
        >
          <!-- 左侧动态数组对应列 -->
          <template v-if="sizeGroupType == 1 || sizeGroupType == 3">
            <el-tooltip
              effect="dark"
              :content="item.goodsName"
              placement="top-start"
            >
              <div :style="{ width: !isAdd ? autoWidth : '' }">
                <span>{{ item.goodsName }}</span>
              </div>
            </el-tooltip>
            <div :style="{ width: !isAdd ? autoWidth : '' }">
              <span>{{ item.goodsNo }}</span>
            </div>
            <div :style="{ width: !isAdd ? autoWidth : '' }">
              <span>{{ item.retailPrice }}</span>
            </div>
            <div
              v-if="headerLeft.length > 4"
              :style="{ width: !isAdd ? autoWidth : '' }"
            >
              <span v-if="!isAdd">{{ item.price }}</span>
              <input
                v-else
                v-model="item.price"
                @input="checkValue(item)"
                @focus="getInitPrice(item.price)"
                @blur="getChangePrice(item)"
                style="
                  height: 26px;
                  line-height: 26px;
                  width: 100%;
                  border: none;
                  outline: none;
                  text-align: center;
                "
              />
            </div>
            <div :style="{ width: !isAdd ? autoWidth : '' }">
              <span>{{ item.colorValue }}</span>
            </div>
          </template>
          <template v-if="sizeGroupType == 2 || sizeGroupType == 15">
            <!-- <div
              :style="{ width: autoWidth }"
              v-if="headerLeft[0].type !== 'print'"
            >
              <span>{{ item.deptName }}</span>
            </div> -->
            <div :style="{ width: autoWidth }">
              <span>{{ item.goodsName }}</span>
            </div>
            <div :style="{ width: autoWidth }">
              <span>{{ item.goodsNo }}</span>
            </div>
            <div :style="{ width: autoWidth }">
              <span>{{
                headerLeft[2].key == "retailPrice"
                  ? item.retailPrice
                  : item.colorValue
              }}</span>
            </div>
          </template>
          <template v-if="sizeGroupType == 4">
            <div :style="{ width: !isAdd ? autoWidth : '' }">
              <span>{{ item.goodsName }}</span>
            </div>
            <div :style="{ width: !isAdd ? autoWidth : '' }">
              <span>{{ item.goodsNo }}</span>
            </div>
          </template>
          <template v-if="sizeGroupType == 5">
            <div>
              <span>{{ item.goodsName }}</span>
            </div>
            <div>
              <span>{{ item.goodsNo }}</span>
            </div>
            <div>
              <span>{{ item.colorValue }}</span>
            </div>
            <div>
              <span>{{ item.goodsCode }}</span>
            </div>
            <div>
              <span>{{ item.retailPrice }}</span>
            </div>
          </template>
          <template v-if="sizeGroupType == 6 || sizeGroupType == 12">
            <div v-if="sizeGroupType == 12 && !isAdd">
              <span>{{ item.deptName }}</span>
            </div>
            <div>
              <span>{{ item.goodsName }}</span>
            </div>
            <div>
              <span>{{ item.goodsNo }}</span>
            </div>
            <div>
              <span>{{ item.colorValue }}</span>
            </div>
            <div v-if="sizeGroupType != 12">
              <span>{{ item.retailPrice }}</span>
            </div>
            <div v-if="sizeGroupType != 12">
              <span>{{ item.purchasePrice }}</span>
            </div>
          </template>
          <template
            v-if="
              sizeGroupType == 7 || sizeGroupType == 9 || sizeGroupType == 10
            "
          >
            <div
              style="word-break: break-all"
              v-if="headerLeft[0].key != 'goodsName'"
            >
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.invoicesNo || item.upstreamNo"
                placement="top-start"
              >
                <span>{{ item.invoicesNo || item.upstreamNo }}</span>
              </el-tooltip>
            </div>
            <div v-if="headerLeft[0].key != 'goodsName'">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.batchNo"
                placement="top-start"
              >
                <span>{{ item.batchNo }}</span>
              </el-tooltip>
            </div>
            <div>
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.goodsName"
                placement="top-start"
              >
                <span>{{ item.goodsName }}</span>
              </el-tooltip>
            </div>
            <div>
              <span>{{ item.goodsNo }}</span>
            </div>
            <div>
              <span>{{ item.colorValue }}</span>
            </div>
            <div>
              <span>{{ item.retailPrice }}</span>
            </div>
            <div>
              <span>{{ item.purchasePrice }}</span>
            </div>
          </template>
          <template v-if="sizeGroupType == 8 || sizeGroupType == 11">
            <div v-if="sizeGroupType == 11">
              <span>{{ item.batchNo }}</span>
            </div>
            <div v-if="!supplierType">
              <span>{{ item.goodsName }}</span>
            </div>
            <div v-if="!supplierType">
              <span>{{ item.goodsNo }}</span>
            </div>
            <div v-if="supplierType">
              <span>{{ item.goodsCode }}</span>
            </div>
            <div v-if="sizeGroupType != 11">
              <span>{{ item.colorValue }}</span>
            </div>
            <div v-if="supplierType">
              <span>{{ item.colorValue }}</span>
            </div>
            <div v-if="sizeGroupType == 11">
              <span>{{ item.purchasePrice }}</span>
            </div>
            <div v-if="sizeGroupType == 11 && !supplierType">
              <span>{{ item.colorValue }}</span>
            </div>
            <div
              v-else-if="!supplierType"
              style="
                width: 80px;
                height: 40px;
                line-height: 40px;
                border-right: 1px solid #e6ebf5;
              "
            >
              <input
                style="
                  border: none;
                  outline: none;
                  width: 74px;
                  height: 20px;
                  line-height: 20px;
                  text-align: center;
                  background: rgb(250, 205, 145);
                  margin-top: -2px;
                "
                type="text"
                v-model="item.purchasePrice"
                @change="purchasePriceChange(item)"
              />
            </div>
          </template>
          <template v-if="sizeGroupType == 13">
            <div style="word-break: break-all">
              <span>{{ item.goodsName }}</span>
            </div>
            <div>
              <span>{{ item.goodsNo }}</span>
            </div>
            <div>
              <span>{{ item.goodsCode }}</span>
            </div>
            <div>
              <span>{{ item.colorValue }}</span>
            </div>
            <div>
              <span>{{
                item.invoicesProperty === "1"
                  ? "成品首单"
                  : item.invoicesProperty === "2"
                  ? "成品补单"
                  : item.invoicesProperty === "3"
                  ? "加工首单"
                  : item.invoicesProperty === "4"
                  ? "加工补单"
                  : item.invoicesProperty === "5"
                  ? "面辅料首单"
                  : ""
              }}</span>
            </div>
            <div>
              <span>{{ item.season }}</span>
            </div>
          </template>
          <template v-if="sizeGroupType == 14">
            <div style="word-break: break-all">
              <span>{{ item.goodsName }}</span>
            </div>
            <div>
              <span>{{ item.goodsCode }}</span>
            </div>
            <div>
              <span>{{ item.colorValue }}</span>
            </div>
          </template>
          <template v-if="sizeGroupType == 20">
            <div>
              <span>{{ item.batchNo }}</span>
            </div>
            <div>
              <span>{{ item.goodsNo }}</span>
            </div>
            <div>
              <span>{{ item.retailPrice }}</span>
            </div>
            <div>
              <span>{{ item.purchasePrice }}</span>
            </div>
          </template>
          <!-- 尺码组数据 -->
          <section
            class="size-group"
            v-if="sizeGroupType == 1 || sizeGroupType == 5"
            :style="{ width: sizeGroupWidth }"
          >
            <div>
              <div
                style="
                  height: 30px;
                  line-height: 30px;
                  border-right: 1px solid #e6ebf5;
                  width: 60px;
                "
              >
                {{
                  stockType == "0"
                    ? "可用库存"
                    : stockType == "1"
                    ? "占用库存"
                    : "在途库存"
                }}
              </div>
              <div
                style="
                  height: 30px;
                  line-height: 30px;
                  border-top: 1px solid #e6ebf5;
                  border-right: 1px solid #e6ebf5;
                  width: 60px;
                "
              >
                {{
                  headerLeft[0].type === "ph"
                    ? "铺货数"
                    : headerLeft[0].type === "tc"
                    ? "退仓数"
                    : headerLeft[0].type === "db"
                    ? "调拨数"
                    : "调整为"
                }}
              </div>
            </div>
            <div v-for="(size, s) in item.sizeInfoList" :key="s">
              <div
                style="
                  width: 60px;
                  height: 30px;
                  line-height: 30px;
                  border-right: 1px solid #e6ebf5;
                "
              >
                {{
                  stockType == "0"
                    ? size.usableQuantity
                    : stockType == "1"
                    ? size.occupiedQuantity
                    : size.intransitQuantity
                }}
              </div>
              <div
                style="
                  width: 60px;
                  height: 30px;
                  line-height: 30px;
                  border-right: 1px solid #e6ebf5;
                  border-top: 1px solid #e6ebf5;
                "
              >
                <input
                  style="
                    border: none;
                    outline: none;
                    width: 56px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                  "
                  :style="{
                    background: size.isError
                      ? 'red'
                      : size.modify
                      ? '#95F205'
                      : 'rgb(250,205,145)',
                  }"
                  :disabled="!isAdd"
                  type="text"
                  @focus="getinitVla(size.number)"
                  @blur="getchangeVla(item, size)"
                  v-model="size.number"
                />
              </div>
            </div>
          </section>
          <section
            class="size-group"
            v-if="
              sizeGroupType == 2 ||
              sizeGroupType == 15 ||
              sizeGroupType == 6 ||
              sizeGroupType == 8
            "
            :style="{ width: sizeGroupWidth }"
          >
            <div v-for="(size, s) in item.sizeInfoList" :key="s">
              <div
                v-if="sizeGroupType != 2 && sizeGroupType != 15"
                style="
                  width: 60px;
                  height: 40px;
                  line-height: 40px;
                  border-right: 1px solid #e6ebf5;
                "
              >
                <input
                  style="
                    border: none;
                    outline: none;
                    width: 56px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                  "
                  :style="{
                    background: size.isError
                      ? 'red'
                      : size.number > 0
                      ? '#43D268'
                      : 'rgb(250,205,145)',
                  }"
                  :disabled="!isAdd"
                  type="text"
                  @focus="getinitVla(size.number)"
                  @blur="getchangeVla(item, size)"
                  v-model="size.number"
                />
              </div>
              <div
                v-else-if="sizeGroupType == 2"
                style="
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  border-right: 1px solid #e6ebf5;
                "
                :style="{
                  background: size.number < 0 ? 'rgb(244,180,187)' : '',
                }"
              >
                {{ size.number == "0" ? "" : size.number }}
              </div>
              <div
                v-else-if="sizeGroupType == 15"
                style="
                  width: 60px;
                  height: 40px;
                  line-height: 40px;
                  border-right: 1px solid #e6ebf5;
                "
                :style="{
                  background:
                    size.takeGoodsNumber < 0 ? 'rgb(244,180,187)' : '',
                }"
              >
                {{ size.takeGoodsNumber }}
              </div>
            </div>
          </section>
          <section
            class="size-group group-three"
            v-if="
              sizeGroupType == 3 ||
              sizeGroupType == 10 ||
              sizeGroupType == 11 ||
              sizeGroupType == 13 ||
              sizeGroupType == 14
            "
            :style="{ width: sizeGroupWidth }"
          >
            <div class="group-label">
              <template v-if="sizeGroupType == 3">
                <div v-if="phNumber">
                  {{
                    headerLeft[0].type === "ph"
                      ? "铺货数量"
                      : headerLeft[0].type === "tc"
                      ? "退仓数量"
                      : "调拨数量"
                  }}
                </div>
                <div v-if="chNumber" style="background: rgb(254, 248, 239)">
                  出货数量
                </div>
                <div v-if="chdNumber" style="background: rgb(254, 248, 239)">
                  出货差异
                </div>
                <div
                  v-if="rkNumber && !supplierType"
                  style="background: rgb(250, 254, 242)"
                >
                  入库数量
                </div>
                <div v-if="rkdNumber" style="background: rgb(250, 254, 242)">
                  入库差异
                </div>
              </template>
              <template v-else-if="sizeGroupType == 10">
                <div>
                  {{
                    headerLeft[0].type === "th" ||
                    headerLeft[0].key != "invoicesNo"
                      ? "提货"
                      : "退供"
                  }}数量
                </div>
                <div style="background: rgb(254, 248, 239)">
                  {{
                    headerLeft[0].type === "th" ||
                    headerLeft[0].key != "invoicesNo"
                      ? "入库"
                      : "出货"
                  }}数量
                </div>
                <div style="background: rgb(250, 254, 242)">
                  {{
                    headerLeft[0].type === "th" ||
                    headerLeft[0].key != "invoicesNo"
                      ? "入库"
                      : "出货"
                  }}差异
                </div>
              </template>
              <template v-else-if="sizeGroupType == 13">
                <div style="height: 30px; line-height: 30px">
                  下单数量(成衣)
                </div>
                <div style="height: 30px; line-height: 30px">完成数量</div>
                <div style="height: 30px; line-height: 30px">差异</div>
              </template>
              <template v-else-if="sizeGroupType == 14">
                <div>原在途库存</div>
                <div>调整为</div>
              </template>
              <template v-else>
                <div>采购数量</div>
                <div style="background: rgb(254, 248, 239)">已提数量</div>
                <div style="background: rgb(254, 248, 239)">未提数量</div>
                <div
                  style="background: rgb(250, 254, 242)"
                  v-if="!supplierType"
                >
                  入库数量
                </div>
                <div style="background: rgb(250, 254, 242)">退供数量</div>
              </template>
            </div>
            <div class="group-value">
              <template v-if="sizeGroupType == 3">
                <div v-if="phNumber">
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.number < 0 ? 'background:#F4B4BB;color:#fff' : ''
                    "
                    >{{ size.number }}</span
                  >
                </div>
                <div v-if="chNumber" style="background: rgb(254, 248, 239)">
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.shipmentNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    >{{ size.shipmentNumber }}</span
                  >
                </div>
                <div v-if="chdNumber" style="background: rgb(254, 248, 239)">
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.shipmentDifferenceNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    >{{ size.shipmentDifferenceNumber }}</span
                  >
                </div>
                <div v-if="rkNumber" style="background: rgb(250, 254, 242)">
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.storageNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    >{{ size.storageNumber }}</span
                  >
                </div>
                <div v-if="rkdNumber" style="background: rgb(250, 254, 242)">
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.storageDifferenceNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    >{{ size.storageDifferenceNumber }}</span
                  >
                </div>
              </template>
              <template v-else-if="sizeGroupType == 10">
                <div>
                  <template
                    v-if="
                      headerLeft[0].type == 1 &&
                      headerLeft[0].key == 'invoicesNo'
                    "
                  >
                    <span
                      v-for="(size, s) in item.sizeInfoList"
                      :key="s"
                      :style="
                        size.number < 0 ? 'background:#F4B4BB;color:#fff' : ''
                      "
                      >{{ size.number || 0 }}</span
                    >
                  </template>
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.takeGoodsNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    v-else
                    >{{ size.takeGoodsNumber || 0 }}</span
                  >
                </div>
                <div style="background: rgb(254, 248, 239)">
                  <template
                    v-if="
                      headerLeft[0].type == 1 &&
                      headerLeft[0].key == 'invoicesNo'
                    "
                  >
                    <span
                      v-for="(size, s) in item.sizeInfoList"
                      :key="s"
                      :style="
                        size.shipmentNumber < 0
                          ? 'background:#F4B4BB;color:#fff'
                          : ''
                      "
                      >{{ size.shipmentNumber || 0 }}</span
                    >
                  </template>
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.intoStockNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    v-else
                    >{{ size.intoStockNumber || 0 }}</span
                  >
                </div>
                <div style="background: rgb(250, 254, 242)">
                  <template
                    v-if="
                      headerLeft[0].type == 1 &&
                      headerLeft[0].key == 'invoicesNo'
                    "
                  >
                    <span
                      v-for="(size, s) in item.sizeInfoList"
                      :key="s"
                      :style="
                        size.shipmentDifferenceNumber < 0
                          ? 'background:#F4B4BB;color:#fff'
                          : ''
                      "
                      >{{ size.shipmentDifferenceNumber || 0 }}</span
                    >
                  </template>
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.intoStockNumDiversity < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    v-else
                    >{{ size.intoStockNumDiversity || 0 }}</span
                  >
                </div>
              </template>
              <template v-else-if="sizeGroupType == 13">
                <div>
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    style="height: 30px; line-height: 30px"
                    >{{ size.orderNumber || 0 }}</span
                  >
                </div>
                <div style="display: flex">
                  <div v-for="(size, s) in item.sizeInfoList" :key="s">
                    <div
                      style="
                        width: 60px;
                        height: 30px;
                        line-height: 30px;
                        border-right: 1px solid #e6ebf5;
                        border-bottom: 1px solid #e6ebf5;
                      "
                    >
                      <input
                        style="
                          border: none;
                          outline: none;
                          width: 56px;
                          height: 20px;
                          line-height: 20px;
                          text-align: center;
                        "
                        :style="{
                          background: size.isError
                            ? 'red'
                            : size.number > 0
                            ? '#43d268'
                            : 'rgb(250,205,145)',
                        }"
                        :disabled="!isAdd"
                        type="text"
                        @focus="getinitVla(size.number)"
                        @blur="getchangeVla(item, size)"
                        v-model="size.number"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    style="height: 30px; line-height: 30px"
                    :style="{
                      color:
                        size.number - 0 - (size.orderNumber || 0 - 0) != 0
                          ? ' #F8475F'
                          : '',
                    }"
                    >{{ size.number - 0 - (size.orderNumber || 0 - 0) }}</span
                  >
                </div>
              </template>
              <template v-else-if="sizeGroupType == 14">
                <div>
                  <span v-for="(size, s) in item.sizeInfoList" :key="s">{{
                    size.count || 0
                  }}</span>
                </div>
                <div>
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.number != size.count
                        ? 'background:#43D268;color:#fff'
                        : ''
                    "
                    >{{ size.number || 0 }}</span
                  >
                </div>
              </template>
              <template v-else>
                <div>
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.number < 0 ? 'background:#F4B4BB;color:#fff' : ''
                    "
                    >{{ size.number || 0 }}</span
                  >
                </div>
                <div style="background: rgb(254, 248, 239)">
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.shipmentNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    >{{ size.takeGoodsNumber || 0 }}</span
                  >
                </div>
                <div style="background: rgb(254, 248, 239)">
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.shipmentNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    >{{ size.notTakeGoodsNumber || 0 }}</span
                  >
                </div>
                <div
                  style="background: rgb(250, 254, 242)"
                  v-if="!supplierType"
                >
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.shipmentDifferenceNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    >{{ size.intoStockNumber || 0 }}</span
                  >
                </div>
                <div style="background: rgb(250, 254, 242)">
                  <span
                    v-for="(size, s) in item.sizeInfoList"
                    :key="s"
                    :style="
                      size.shipmentDifferenceNumber < 0
                        ? 'background:#F4B4BB;color:#fff'
                        : ''
                    "
                    >{{ size.returnGoodsNumber || 0 }}</span
                  >
                </div>
              </template>
            </div>
          </section>
          <section
            class="size-group group-three"
            v-if="sizeGroupType == 4"
            :style="{ width: sizeGroupWidth }"
          >
            <div class="group-label">
              <div>总数</div>
              <div>已收</div>
              <div>未收</div>
            </div>
            <div class="group-value">
              <div>
                <span v-for="(size, s) in item.sizeInfoList" :key="s">{{
                  size.shipmentNumber
                }}</span>
              </div>
              <div>
                <!-- :style="
                    size.barCode == searchInNo
                      ? 'background:#067CF2;fontWeight:bold;color:#fff'
                      : ''
                  " -->
                <!-- <span
                  v-for="(size, s) in item.sizeInfoList"
                  :key="s"
                  :class="[getRowBgc(size)]"
                  >{{ size.checkNumber }}</span
                > -->
                <template v-for="(size, s) in item.sizeInfoList">
                  <input
                    v-model="size.checkNumber"
                    :key="s"
                    class="input-item"
                    :class="[
                      getRowBgc(size),
                      size.shipmentNumber == 0 ? 'is-disabled' : '',
                    ]"
                    :disabled="size.shipmentNumber == 0"
                    @input="
                      () => {
                        size.checkNumber = +size.checkNumber
                          .toString()
                          .replace(/[^\d]/g, '');
                      }
                    "
                    @change="checkNumberChange(size)"
                    @focus="getCheckNumber(size)"
                  />
                </template>
              </div>
              <div>
                <span
                  v-for="(size, s) in item.sizeInfoList"
                  :key="s"
                  :style="
                    size.surplusNumber > 0
                      ? 'color:#F8475F;fontWeight:bold'
                      : ''
                  "
                  >{{ size.surplusNumber }}</span
                >
              </div>
            </div>
          </section>
          <section
            class="size-group"
            v-if="sizeGroupType == 7 || sizeGroupType == 9"
            :style="{ width: sizeGroupWidth }"
          >
            <div>
              <div
                style="
                  height: 30px;
                  line-height: 30px;
                  border-right: 1px solid #e6ebf5;
                  width: 60px;
                "
              >
                采购数
              </div>
              <div
                style="
                  height: 30px;
                  line-height: 30px;
                  border-top: 1px solid #e6ebf5;
                  border-right: 1px solid #e6ebf5;
                  width: 60px;
                "
              >
                {{ sizeGroupType == 7 ? "入库数" : "已提数" }}
              </div>
              <div
                style="
                  height: 30px;
                  line-height: 30px;
                  border-top: 1px solid #e6ebf5;
                  border-right: 1px solid #e6ebf5;
                  width: 60px;
                "
                v-if="sizeGroupType == 7"
              >
                退供数
              </div>
              <template v-else>
                <div
                  style="
                    height: 30px;
                    line-height: 30px;
                    border-top: 1px solid #e6ebf5;
                    border-right: 1px solid #e6ebf5;
                    width: 60px;
                  "
                >
                  未提数
                </div>
                <div
                  style="
                    height: 30px;
                    line-height: 30px;
                    border-top: 1px solid #e6ebf5;
                    border-right: 1px solid #e6ebf5;
                    width: 60px;
                  "
                >
                  本次提货
                </div>
              </template>
            </div>
            <div v-for="(size, s) in item.sizeInfoList" :key="s">
              <div
                style="
                  width: 60px;
                  height: 30px;
                  line-height: 30px;
                  border-right: 1px solid #e6ebf5;
                "
              >
                {{ size.purchaseNumber }}
              </div>
              <div
                style="
                  width: 60px;
                  height: 30px;
                  line-height: 30px;
                  border-right: 1px solid #e6ebf5;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{
                  sizeGroupType == 7
                    ? size.intoStockNumber
                    : size.takeGoodsNumber
                }}
              </div>
              <div
                style="
                  width: 60px;
                  height: 30px;
                  line-height: 30px;
                  border-right: 1px solid #e6ebf5;
                  border-top: 1px solid #e6ebf5;
                "
                v-if="sizeGroupType == 7"
              >
                <input
                  style="
                    border: none;
                    outline: none;
                    width: 56px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                  "
                  :style="{
                    background: size.isError
                      ? 'red'
                      : size.number > 0
                      ? '#43d268'
                      : 'rgb(250,205,145)',
                  }"
                  :disabled="!isAdd"
                  type="text"
                  @focus="getinitVla(size.number)"
                  @blur="getchangeVla(item, size)"
                  v-model="size.number"
                />
              </div>
              <template v-else>
                <div
                  style="
                    width: 60px;
                    height: 30px;
                    line-height: 30px;
                    border-right: 1px solid #e6ebf5;
                    border-top: 1px solid #e6ebf5;
                  "
                >
                  {{ size.notTakeGoodsNumber }}
                </div>
                <div
                  style="
                    width: 60px;
                    height: 30px;
                    line-height: 30px;
                    border-right: 1px solid #e6ebf5;
                    border-top: 1px solid #e6ebf5;
                  "
                >
                  <input
                    style="
                      border: none;
                      outline: none;
                      width: 56px;
                      height: 20px;
                      line-height: 20px;
                      text-align: center;
                    "
                    :style="{
                      background: size.isError
                        ? 'red'
                        : size.number > 0
                        ? '#43d268'
                        : 'rgb(250,205,145)',
                    }"
                    :disabled="!isAdd"
                    type="text"
                    @focus="getinitVla(size.number)"
                    @blur="getchangeVla(item, size)"
                    v-model="size.number"
                  />
                </div>
              </template>
            </div>
          </section>
          <section
            class="size-group group-three"
            v-if="sizeGroupType == 12"
            :style="{ width: sizeGroupWidth }"
          >
            <div
              class="group-label"
              style="width: 60px; height: 60px; font-size: 11px"
              v-if="headerLeft[0].ofType == 2"
            >
              <div>{{ headerLeft[2].text }}</div>
              <div>调整为</div>
            </div>
            <div
              v-for="(size, s) in item.sizeInfoList"
              :key="s"
              class="group-value"
            >
              <template v-if="isAdd">
                <div
                  style="
                    width: 60px;
                    height: 30px;
                    line-height: 30px;
                    border-right: 1px solid #e6ebf5;
                  "
                >
                  {{ size.adjustmentPriceValue || 0 }}
                </div>
                <div
                  style="
                    width: 60px;
                    height: 30px;
                    line-height: 30px;
                    border-right: 1px solid #e6ebf5;
                    border-top: 1px solid #e6ebf5;
                  "
                >
                  <input
                    style="
                      border: none;
                      outline: none;
                      width: 56px;
                      height: 20px;
                      line-height: 20px;
                      text-align: center;
                    "
                    :style="{
                      background: size.bgShow ? '#43D268' : 'rgb(250,205,145)',
                    }"
                    :disabled="!isAdd"
                    type="text"
                    @focus="getinitVla(size.number)"
                    @blur="getChangePriceVla(item, size)"
                    @input="priceInput(size)"
                    v-model="size.number"
                  />
                </div>
              </template>
              <template v-else>
                <div
                  style="
                    width: 60px;
                    height: 30px;
                    line-height: 30px;
                    border-right: 1px solid #e6ebf5;
                  "
                >
                  {{ size.oldPrice || 0.0 }}
                </div>
                <div
                  style="
                    width: 60px;
                    height: 30px;
                    line-height: 30px;
                    border-right: 1px solid #e6ebf5;
                    border-top: 1px solid #e6ebf5;
                  "
                >
                  {{ size.newPrice || 0.0 }}
                </div>
              </template>
            </div>
          </section>
          <!-- 尺码组右侧列数据 -->
          <template v-if="sizeGroupType == 1 || sizeGroupType == 5">
            <div
              style="display: flex; flex-direction: column"
              v-if="headerLeft[2].key !== 'cruuentPrice'"
            >
              <div style="line-height: 29px; width: 100%">
                {{ item.stockAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.salesAll }}
              </div>
            </div>
            <div :class="[!bodyScroll ? '' : 'last-cell-width']">
              <el-button type="text" @click="delRow(index)">删除</el-button>
            </div>
          </template>
          <template v-if="sizeGroupType == 2 || sizeGroupType == 15">
            <div
              style="display: flex; flex-direction: column"
              :style="{ width: autoWidth }"
              :class="[!bodyScroll ? '' : 'last-cell-width']"
            >
              <div
                v-if="sizeGroupType == 2"
                style="height: 30px; line-height: 30px; width: 100%"
              >
                {{ item.numberAll }}
              </div>
              <div v-else style="height: 30px; line-height: 30px; width: 100%">
                {{ item.takeGoodsNumber }}
              </div>
            </div>
          </template>
          <template v-if="sizeGroupType == 3">
            <div
              style="display: flex; flex-direction: column"
              :style="{ width: sizeGroupType == 3 ? autoWidth : '' }"
              :class="[!bodyScroll ? '' : 'last-cell-width']"
            >
              <div v-if="phNumber" style="line-height: 30px; width: 100%">
                {{ item.phAll }}
              </div>
              <div
                v-if="chNumber"
                style="
                  line-height: 30px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                  background: rgb(254, 248, 239);
                "
              >
                {{ item.chAll }}
              </div>
              <div
                v-if="chdNumber"
                style="
                  line-height: 30px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                  background: rgb(254, 248, 239);
                "
              >
                {{ item.chdAll }}
              </div>
              <div
                v-if="rkNumber"
                style="
                  line-height: 30px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                  background: rgb(250, 254, 242);
                "
              >
                {{ item.rkAll }}
              </div>
              <div
                v-if="rkdNumber"
                style="
                  line-height: 30px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                  background: rgb(250, 254, 242);
                "
              >
                {{ item.rkdAll }}
              </div>
            </div>
          </template>
          <template v-if="sizeGroupType == 4">
            <div
              style="display: flex; flex-direction: column"
              :style="{ width: !isAdd ? autoWidth : '' }"
              :class="[!bodyScroll ? '' : 'last-cell-width']"
            >
              <div style="line-height: 30px; width: 100%">{{ item.chAll }}</div>
              <div
                style="
                  line-height: 30px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.chdAll }}
              </div>
              <div
                style="
                  line-height: 30px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.rkAll }}
              </div>
            </div>
          </template>
          <template v-if="sizeGroupType == 6">
            <div style="height: 40px; line-height: 40px">
              {{ item.salesAll }}
            </div>
            <div style="height: 40px; line-height: 40px">
              {{ item.totalPrice || "0.00" }}
            </div>
            <div :class="[!bodyScroll ? '' : 'last-cell-width']">
              <el-button type="text" style="padding: 0" @click="delRow(index)"
                >删除</el-button
              >
            </div>
          </template>
          <template
            v-if="
              sizeGroupType == 7 || sizeGroupType == 10 || sizeGroupType == 14
            "
          >
            <div
              style="display: flex; flex-direction: column"
              v-if="sizeGroupType == 14"
            >
              <div style="line-height: 29px; width: 100%">
                {{ item.chAll || 0 }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.numberAll }}
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column"
              v-if="sizeGroupType != 14"
            >
              <div style="line-height: 29px; width: 100%">
                {{ item.chAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.chdAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.salesAll }}
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column"
              v-if="sizeGroupType != 14"
            >
              <div style="line-height: 29px; width: 100%">
                {{ item.PriceAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.isPriceAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.stockAll }}
              </div>
            </div>
            <div
              :class="[!bodyScroll ? '' : 'last-cell-width']"
              v-if="isAdd && sizeGroupType != 14"
            >
              <el-button type="text" @click="delRow(index)">删除</el-button>
            </div>
          </template>
          <template v-if="sizeGroupType == 8 || sizeGroupType == 11">
            <template v-if="sizeGroupType == 11">
              <div style="display: flex; flex-direction: column">
                <div style="line-height: 29px; width: 100%">
                  {{ item.salesAll }}
                </div>
                <div
                  style="
                    line-height: 29px;
                    width: 100%;
                    border-top: 1px solid #e6ebf5;
                  "
                >
                  {{ item.chAll }}
                </div>
                <div
                  style="
                    line-height: 29px;
                    width: 100%;
                    border-top: 1px solid #e6ebf5;
                  "
                >
                  {{ item.chdAll }}
                </div>
                <div
                  v-if="!supplierType"
                  style="
                    line-height: 29px;
                    width: 100%;
                    border-top: 1px solid #e6ebf5;
                  "
                >
                  {{ item.rkAll }}
                </div>
                <div
                  style="
                    line-height: 29px;
                    width: 100%;
                    border-top: 1px solid #e6ebf5;
                  "
                >
                  {{ item.returnNum }}
                </div>
              </div>
              <div style="display: flex; flex-direction: column">
                <div style="line-height: 29px; width: 100%">
                  {{ item.purchaseTotalPrice }}
                </div>
              </div>
            </template>
            <div style="display: flex; flex-direction: column; width: 110px">
              <el-date-picker
                v-model="item.firstDeliveryTime"
                type="date"
                placeholder="选择日期"
                :clearable="false"
                class="time"
                :class="[!isAdd ? 'timeBg' : 'time']"
                size="mini"
                :disabled="!isAdd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <div style="display: flex; flex-direction: column; width: 110px">
              <el-date-picker
                v-model="item.finalDeliveryTime"
                type="date"
                placeholder="选择日期"
                :clearable="false"
                class="time"
                :class="[!isAdd ? 'timeBg' : 'time']"
                size="mini"
                :disabled="!isAdd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
            <template v-if="sizeGroupType == 8">
              <div style="display: flex; flex-direction: column">
                <div style="line-height: 29px; width: 100%">
                  {{ item.salesAll }}
                </div>
              </div>
              <div style="display: flex; flex-direction: column">
                <div style="line-height: 29px; width: 100%">
                  {{ item.stockAll }}
                </div>
              </div>
            </template>
            <div
              style="display: flex; flex-direction: column"
              v-if="!isAdd && sizeGroupType == 8"
            >
              <div style="line-height: 29px; width: 100%">
                {{ item.takeGoodsNumber }}
              </div>
            </div>
            <div
              style="display: flex; flex-direction: column"
              v-if="!isAdd && sizeGroupType == 8"
            >
              <div style="line-height: 29px; width: 100%">
                {{ item.notTakeGoodsNumber }}
              </div>
            </div>
            <div
              :class="[!bodyScroll ? '' : 'last-cell-width']"
              v-if="isAdd && sizeGroupType == 8"
            >
              <span
                style="font-size: 12px; color: #1890ff; cursor: pointer"
                @click="delRow(index)"
                >删除</span
              >
            </div>
          </template>
          <template v-if="sizeGroupType == 9">
            <div style="display: flex; flex-direction: column">
              <div style="line-height: 29px; width: 100%">{{ item.chAll }}</div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.chdAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.rkAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.salesAll }}
              </div>
            </div>
            <div style="display: flex; flex-direction: column">
              <div style="line-height: 29px; width: 100%">
                {{ item.PriceAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.isPriceAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.noPriceAll }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.stockAll }}
              </div>
            </div>
            <div :class="[!bodyScroll ? '' : 'last-cell-width']">
              <el-button type="text" @click="delRow(index)">删除</el-button>
            </div>
          </template>
          <template v-if="sizeGroupType == 12">
            <div :style="{ width: !bodyScroll ? '120px' : '110px' }">
              <div
                style="display: flex; flex-direction: column; width: 100%"
                v-if="!isAdd"
              >
                <div style="line-height: 29px; width: 100%">
                  {{ item.oldPrice || 0 }}
                </div>
                <div
                  style="
                    line-height: 29px;
                    width: 100%;
                    border-top: 1px solid #e6ebf5;
                  "
                >
                  {{ item.newPrice || 0 }}
                </div>
              </div>
              <template v-else>
                <el-button type="text" style="padding: 0" @click="delRow(index)"
                  >删除</el-button
                >
                <el-button
                  type="text"
                  style="padding: 0"
                  @click="
                    dialogVisible = true;
                    curChangePrice = item;
                  "
                  >批量修改</el-button
                >
              </template>
            </div>
          </template>
          <template v-if="sizeGroupType == 13">
            <div style="display: flex; flex-direction: column">
              <div style="line-height: 29px; width: 100%">
                {{ item.chAll || 0 }}
              </div>
              <div
                style="
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ item.numberAll || 0 }}
              </div>
              <div
                style="
                  height: 29px;
                  line-height: 29px;
                  width: 100%;
                  border-top: 1px solid #e6ebf5;
                "
              >
                {{ "" }}
              </div>
            </div>
          </template>
        </div>
      </template>
    </section>

    <!-- 表尾合计行 -->
    <section
      class="total-row"
      v-if="!isAdd && dataList.length"
      :style="{ width: tableWidth }"
    >
      <div
        style="
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
        "
        v-if="collectNumberList.length"
      >
        <div
          :style="{
            width: parseInt(this.autoWidth) * headerLeft.length + 'px',
          }"
        >
          <div :style="{ width: this.autoWidth, textAlign: 'center' }">
            合计
          </div>
        </div>
        <div
          style="display: flex"
          :style="{
            width: sizeGroupWidth,
          }"
        >
          <div
            v-for="(item, index) in collectNumberList"
            :key="index"
            style="
              border-right: 1px solid #e6ebf5;
              border-left: 1px solid #e6ebf5;
              width: 40px;
              text-align: center;
            "
          >
            {{ item ? item : "" }}
          </div>
        </div>
        <div
          style="text-align: center; border-left: 1px solid #e6ebf5"
          :style="{ width: this.autoWidth }"
        >
          {{ totalNum }}
        </div>
      </div>
      <template v-else>
        <div
          :style="{
            width: parseInt(this.autoWidth) * headerLeft.length + 'px',
          }"
        >
          合计
        </div>
        <span
          >总款数：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalAmount }}个</span
          ></span
        >

        <span v-if="totalObj.distriNumTotal"
          >总件数：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ getNum }}件</span
          ></span
        >
        <span v-if="totalObj.distriPriceTotal"
          >总价：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >¥{{ totalObj.distriPriceTotal }}</span
          ></span
        >
        <span v-if="totalObj.sellPriceTotal"
          >零售总价：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >¥{{ totalObj.sellPriceTotal }}</span
          ></span
        >
        <span v-if="sizeGroupType == 4"
          >已收：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalObj.ReceivedTotal }}件</span
          ></span
        >
        <span v-if="sizeGroupType == 4"
          >未收：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalObj.noReceivedTotal }}件</span
          ></span
        >
        <span v-if="totalObj.chuhdNumTotal && scheduleState >= 2"
          >出货差异总数：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalObj.chuhdNumTotal }}件</span
          ></span
        >
        <span v-if="totalObj.rukudNumTotal && scheduleState > 3"
          >入库差异总数：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalObj.rukudNumTotal }}件</span
          ></span
        >
        <span v-if="totalObj.adjustTotal"
          >调整总件数：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalObj.adjustTotal }}件</span
          ></span
        >
      </template>
      <template v-if="sizeGroupType == 11">
        <span
          >已提数：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalObj.tackNum }}件</span
          ></span
        >
        <span
          >未提数：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalObj.notackNum }}件</span
          ></span
        >
        <span v-if="!supplierType"
          >入库数：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalObj.intoNum }}件</span
          ></span
        >
        <span
          >退供数：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >{{ totalObj.returnNum }}件</span
          ></span
        >
        <span
          >退供金额：<span
            style="
              color: red;
              font-size: 16px;
              font-weight: bold;
              margin-left: 0px;
            "
            >￥{{ totalObj.returnPrice }}</span
          ></span
        >
      </template>
    </section>
    <el-dialog
      title="批量调整零售价"
      :visible.sync="dialogVisible"
      width="20%"
      append-to-body
      :close-on-click-modal="false"
      @close="
        dialogVisible = false;
        form.retailPrice = '';
      "
    >
      <div>
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="调整为">
            <el-input
              type="number"
              v-model="form.retailPrice"
              placeholder="请输入"
              @input="retailPriceInput"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="retailPriceSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { erpQuerySizeInfo } from "@/libs/http/api.js";
export default {
  name: "puhuoTable",
  props: {
    headerLeft: {
      //表格尺码组左侧数据
      type: Array,
      default: () => [],
    },
    headerRight: {
      //表格尺码组右侧数据
      type: Array,
      default: () => [],
    },
    addedList: {
      //表格数据
      type: Array,
      default: () => [],
    },
    stockType: {
      //库存类型
      type: String,
      default: "0",
    },
    tabBodyHeight: {
      //表格高度
      type: String,
      default: "auto",
    },
    isAdd: {
      //区分新增和详情
      type: Boolean,
      default: true,
    },
    sizeGroupType: {
      //尺码组类型，控制不同显示样式
      type: String,
      default: "1",
    },
    sizeGroupWidth: {
      //尺码组宽度
      type: String,
      default: "450px",
    },
    collectNumberList: {
      //尺码列汇总
      type: Array,
      default: () => [],
    },
    scheduleState: {
      //控制各种数量是否显示
      type: String,
      default: "1",
    },
    searchInNo: {
      //当前操作的是哪个条码
      type: String,
      default: "",
    },
    isShowRow: {
      //控制这行是否显示，sizeGroupType = 4
      type: Boolean,
      default: false,
    },
    calcDivNum: {
      //设置自动宽的div个数
      type: String,
      default: "5",
    },
    outWidth: {
      //表格总宽度
      type: String,
      default: "1100",
    },
    isAllowNegativeStocks: {
      //判断店铺是否可负库存出货
      type: String,
      default: "N",
    },
    takeGoodsNum: {
      type: String,
      default: "0",
    },
    invoicesData: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "",
    },
  },
  emites: ["updateCheckNumber"],
  data() {
    return {
      tableWidth: "",
      rowIndex: null,
      currentGroup: "",
      sizeGroup: [],
      dataList: [],
      curSizeVal: "",
      curPriceVal: "",
      phNumber: true,
      chNumber: false,
      chdNumber: false,
      rkNumber: false,
      rkdNumber: false,
      supplierType: true,
      totalObj: {
        sellPriceTotal: 0, //零售总价
        distriPriceTotal: 0, //铺货总价
        distriNumTotal: 0, //铺货总数量
        chuhNumTotal: 0, //出货总数量
        chuhdNumTotal: 0, //出货差异总数量
        rukuNumTotal: 0, //入库总数量
        rukudNumTotal: 0, //入库差异总数量
        ReceivedTotal: 0, //已收总数量
        noReceivedTotal: 0, //未收总数量
        adjustTotal: 0, //调整总件数
        tackNum: 0, //已提数
        notackNum: 0, //未提数
        intoNum: 0, //已提数
        returnNum: 0, //已提数
        returnPrice: 0, //已提数
      },
      totalNum: 0,
      valueGroupIdList: [],
      bodyScroll: false, //表格内容是否滚动

      curChangePrice: null, //新增调价时需要
      dialogVisible: false,
      form: {
        retailPrice: "",
      },
      checkHistoryNumber: 0,
    };
  },
  watch: {
    addedList() {
      if (this.sizeGroupType == 4 || this.sizeGroupType == 11) {
        this.dataList = [];
      }
      this.editDataHandel();
    },
    collectNumberList() {
      this.totalNum = this.collectNumberList.reduce((c, R) => c + Number(R), 0);
    },
    isShowRow() {
      this.dataList = [];
      this.editDataHandel();
    },
    dataList() {
      this.valueGroupIdList = [];
      this.dataList.forEach((item) => {
        if (item.valueGroupId) {
          this.valueGroupIdList.push(item.valueGroupId);
        }
      });
      if (this.valueGroupIdList.length) {
        this.fetchSizeInfo();
      } else {
        this.sizeGroup = [];
      }
    },
  },
  computed: {
    //根据不同状态返回不同数据进行渲染
    getNum() {
      const a = this.takeGoodsNum || this.totalObj.distriPriceTotal;
      if (this.type == "1") {
        return this.invoicesData;
      } else if (this.type == "2") {
        return a;
      } else {
        return this.totalObj.distriNumTotal;
      }
    },
    autoWidth() {
      let groupwidth = this.sizeGroupWidth.slice(
        0,
        this.sizeGroupWidth.length - 2
      );
      return (this.outWidth - groupwidth) / this.calcDivNum + "px";
    },
    //总款数
    totalAmount() {
      let list = [];
      this.dataList.forEach((item) => {
        if (!list.includes(item.spuInfoId)) {
          list.push(item.spuInfoId);
        }
      });
      return list.length;
    },
  },
  created() {
    // this.fetchSizeInfo();
    // this.supplierType = this.$cache.session.get(
    //   this.$macro.cache.KEY_SUPPLIER_ID
    // )
    //   ? true
    //   : false;
    this.setTableWidth();
    if (this.scheduleState >= 2) {
      this.chNumber = true;
      this.chdNumber = true;
    }
    if (this.scheduleState > 3) {
      this.rkNumber = true;
      this.rkdNumber = true;
    }
  },
  methods: {
    setTableWidth() {
      this.$nextTick(() => {
        this.tableWidth = this.$refs.head.offsetWidth + "px";
      });
    },
    purchasePriceChange(item) {
      item.purchasePrice = item.purchasePrice.replace(
        /^(\-)*(\d+)\.(\d\d).*$/,
        "$1$2.$3"
      );
    },
    priceInput(size) {
      size.number = size.number.replace(
        /^(\-)*(\d+)\.(\d\d\d\d).*$/,
        "$1$2.$3"
      );
    },
    retailPriceInput() {
      this.form.retailPrice = this.form.retailPrice.replace(
        /^(\-)*(\d+)\.(\d\d\d\d).*$/,
        "$1$2.$3"
      );
    },
    retailPriceSubmit() {
      if (!this.form.retailPrice) {
        return this.$message.error("请输入调整的数字");
      }
      this.curChangePrice.sizeInfoList.forEach((item) => {
        item.number = this.form.retailPrice;
        this.$set(item, "modify", true);
        item.bgShow = true;
      });
      this.form.retailPrice = "";
      this.dialogVisible = false;
    },
    fetchSizeInfo() {
      erpQuerySizeInfo({ valueGroupIds: this.valueGroupIdList })
        .then((data) => {
          if (data && data.length) {
            this.sizeGroup = data;
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
            duration: 2000,
            showClose: true,
          });
        });
    },
    editDataHandel() {
      const list = JSON.parse(JSON.stringify(this.addedList));
      if (this.sizeGroupType == 11) {
        //采购单详情
        this.totalObj.distriNumTotal = 0;
        this.totalObj.distriPriceTotal = 0;
        this.totalObj.tackNum = 0;
        this.totalObj.notackNum = 0;
        this.totalObj.intoNum = 0;
        this.totalObj.returnNum = 0;
        this.totalObj.returnPrice = 0;
      }
      if (this.sizeGroupType == 4) {
        this.totalObj.distriNumTotal = 0;
        this.totalObj.ReceivedTotal = 0;
        this.totalObj.noReceivedTotal = 0;
      }
      list.map((item, index) => {
        item.sizeInfoList.forEach((size) => {
          if (!size.number && size.number != 0) {
            this.$set(size, "number", "0");
          }
          // if(this.sizeGroupType == 5){
          //     this.$set(size, 'modify', true)
          // }

          if (
            this.sizeGroupType != 8 &&
            this.sizeGroupType != 5 &&
            this.sizeGroupType != 13 &&
            Number(size.number) > 0
          ) {
            this.$set(size, "modify", true);
          }
          if (this.sizeGroupType == 5) {
            if (this.stockType == "0") {
              this.$set(size, "number", size.usableQuantity);
            }
            if (this.stockType == "1") {
              this.$set(size, "number", size.occupiedQuantity);
            }
            if (this.stockType == "2") {
              this.$set(size, "number", size.intransitQuantity);
            }
          }
        });
        if (this.stockType == "0") {
          const stockAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.usableQuantity),
            0
          );
          this.$set(item, "stockAll", stockAll);
        }
        if (this.stockType == "1") {
          const stockAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.occupiedQuantity),
            0
          );
          this.$set(item, "stockAll", stockAll);
        }
        if (this.stockType == "2") {
          const stockAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.intransitQuantity),
            0
          );
          this.$set(item, "stockAll", stockAll);
        }
        // const stockAll = item.sizeInfoList.reduce((c, R) => c + Number(R.usableQuantity), 0);
        // this.$set(item, 'stockAll', stockAll);
        const numberAll = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number),
          0
        );
        this.$set(item, "salesAll", numberAll);
        this.$set(item, "numberAll", numberAll);
        this.$set(item, "isVisiable", true);
        if (this.sizeGroupType == 14) {
          const chAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.count),
            0
          );
          this.$set(item, "chAll", chAll);
          this.totalObj.adjustTotal =
            Number(this.totalObj.adjustTotal) + Number(item.numberAll);
        }
        if (this.sizeGroupType == 12 && !this.isAdd) {
          let oldPrice = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.oldPrice),
            0
          );
          let newPrice = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.newPrice),
            0
          );
          this.$set(item, "oldPrice", oldPrice.toFixed(2));
          this.$set(item, "newPrice", newPrice.toFixed(2));
        }
        if (this.sizeGroupType == 3) {
          const phAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.number),
            0
          );
          const chAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.shipmentNumber),
            0
          );
          const chdAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.shipmentDifferenceNumber),
            0
          );
          const rkAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.storageNumber),
            0
          );
          const rkdAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.storageDifferenceNumber),
            0
          );
          this.$set(item, "phAll", phAll);
          this.$set(item, "chAll", chAll);
          this.$set(item, "chdAll", chdAll);
          this.$set(item, "rkAll", rkAll);
          this.$set(item, "rkdAll", rkdAll);
          this.totalObj.distriNumTotal =
            Number(this.totalObj.distriNumTotal) + Number(item.phAll);
          this.totalObj.chuhNumTotal =
            Number(this.totalObj.chuhNumTotal) + Number(item.chAll);
          this.totalObj.chuhdNumTotal =
            Number(this.totalObj.chuhdNumTotal) + Number(item.chdAll);
          this.totalObj.rukuNumTotal =
            Number(this.totalObj.rukuNumTotal) + Number(item.rkAll);
          this.totalObj.rukudNumTotal =
            Number(this.totalObj.rukudNumTotal) + Number(item.rkdAll);
          this.totalObj.sellPriceTotal = (
            Number(this.totalObj.sellPriceTotal) +
            Number(item.retailPrice) * item.phAll
          ).toFixed(2);
          this.totalObj.distriPriceTotal = (
            Number(this.totalObj.distriPriceTotal) +
            Number(item.price) * item.phAll
          ).toFixed(2);
        }
        if (this.sizeGroupType == 4) {
          const chAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.shipmentNumber),
            0
          );
          const chdAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.checkNumber),
            0
          );
          const rkAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.surplusNumber),
            0
          );
          this.$set(item, "chAll", chAll);
          this.$set(item, "chdAll", chdAll);
          this.$set(item, "rkAll", rkAll);
          this.$set(item, "show", true);
          this.totalObj.distriNumTotal =
            Number(this.totalObj.distriNumTotal) + Number(item.chAll);
          this.totalObj.ReceivedTotal =
            Number(this.totalObj.ReceivedTotal) + Number(item.chdAll);
          this.totalObj.noReceivedTotal =
            Number(this.totalObj.noReceivedTotal) + Number(item.rkAll);
          if (this.isShowRow && rkAll <= 0) {
            this.$set(item, "show", false);
          }
        }
        if (this.sizeGroupType == 8) {
          let satrtTime = item.firstDeliveryTime || "";
          let endTime = item.finalDeliveryTime || "";
          this.$set(item, "firstDeliveryTime", satrtTime);
          this.$set(item, "finalDeliveryTime", endTime);
          item.stockAll = Number(item.salesAll) * Number(item.purchasePrice);
          item.sizeInfoList.forEach((ele) => {
            if (Number(ele.number) > 0) {
              this.$set(ele, "modify", true);
              this.$set(item, "modify", true);
            }
            if (!ele.bgShow) {
              ele.bgShow = false;
            }
          });
        }
        if (this.sizeGroupType == 7 || this.sizeGroupType == 9) {
          let chdAll = "";
          if (this.sizeGroupType == 9) {
            chdAll = item.sizeInfoList.reduce(
              (c, R) => this.$amount.add([c, R.takeGoodsNumber], 0), //this.$amount.add(arr1) c + Number(R.takeGoodsNumber)
              0
            );
          } else {
            chdAll = item.sizeInfoList.reduce(
              (c, R) => this.$amount.add([c, R.intoStockNumber], 0), //c + Number(R.intoStockNumber)
              0
            );
            item.stockAll = this.$amount.multiply([item.salesAll, item.price]); // item.salesAll * item.price
          }
          const chAll = item.sizeInfoList.reduce(
            (c, R) => this.$amount.add([c, R.purchaseNumber], 0), // c + Number(R.purchaseNumber)
            0
          );
          const rkAll = item.sizeInfoList.reduce(
            (c, R) => this.$amount.add([c, R.notTakeGoodsNumber], 0), //c + Number(R.notTakeGoodsNumber)
            0
          );
          const PriceAll =
            this.sizeGroupType == 9
              ? this.$amount.multiply([chAll, item.purchasePrice])
              : this.$amount.multiply([chAll, item.purchasePrice]); //price chAll * Number(item.purchasePrice)
          const isPriceAll =
            this.sizeGroupType == 9
              ? this.$amount.multiply([chdAll, item.purchasePrice])
              : this.$amount.multiply([chdAll, item.purchasePrice]);

          const noPriceAll =
            this.sizeGroupType == 9
              ? this.$amount.multiply([rkAll, item.purchasePrice])
              : this.$amount.multiply([rkAll, item.purchasePrice]);
          this.$set(item, "chAll", chAll); // 采购数
          this.$set(item, "chdAll", chdAll); //提货数
          this.$set(item, "rkAll", rkAll); // 未提数
          this.$set(item, "PriceAll", PriceAll); // 总采购价
          this.$set(item, "isPriceAll", isPriceAll); // 已提货价格
          this.$set(item, "noPriceAll", noPriceAll); // 未提数价格
        }
        if (this.sizeGroupType == 10) {
          if (
            this.headerLeft[0].type == 1 &&
            this.headerLeft[0].key == "invoicesNo"
          ) {
            var chAll = item.sizeInfoList.reduce(
              (c, R) => c + (Number(R.number) || 0),
              // (c,R) => this.$amount.add([c,R.number || 0]),
              0
            );
            this.totalObj.distriNumTotal = chAll;
            var chdAll = item.sizeInfoList.reduce(
              (c, R) => c + Number(R.shipmentNumber),
              // (c, R) => this.$amount.add([c,R.shipmentNumber]),
              0
            );
            var salesAll = item.sizeInfoList.reduce(
              (c, R) => c + Number(R.shipmentDifferenceNumber),
              // (c, R) => this.$amount.add([c,R.shipmentDifferenceNumber]),
              0
            );
          } else {
            var chAll = item.sizeInfoList.reduce(
              (c, R) => c + (Number(R.takeGoodsNumber) || 0),
              // (c, R) => this.$amount.add([c,R.takeGoodsNumber || 0]),
              0
            );
            this.totalObj.distriNumTotal = chAll;
            var chdAll = item.sizeInfoList.reduce(
              (c, R) => c + (Number(R.intoStockNumber) || 0),
              // (c, R) => this.$amount.add([c,R.intoStockNumber]),
              0
            );
            var salesAll = item.sizeInfoList.reduce(
              (c, R) => c + (Number(R.intoStockNumDiversity) || 0),
              // (c, R) => this.$amount.add([c,R.intoStockNumDiversity || 0]),
              0
            );
          }
          const PriceAll = (chAll * Number(item.purchasePrice)).toFixed(2);
          const isPriceAll = (chdAll * Number(item.purchasePrice)).toFixed(2);
          const stockAll = (salesAll * Number(item.purchasePrice)).toFixed(2);
          this.$set(item, "chAll", chAll);
          this.$set(item, "chdAll", chdAll);
          this.$set(item, "salesAll", salesAll);
          this.$set(item, "PriceAll", PriceAll);
          this.$set(item, "isPriceAll", isPriceAll);
          this.$set(item, "stockAll", stockAll);
          this.totalObj.distriNumTotal =
            Number(this.totalObj.distriNumTotal) + Number(item.chAll);
          // this.$amount.add([this.totalObj.distriNumTotal,item.chAll])
          this.totalObj.distriPriceTotal =
            Number(this.totalObj.distriPriceTotal) + Number(item.PriceAll);
          // this.$amount.add([this.totalObj.distriPriceTotal,item.PriceAll])
        }
        if (this.sizeGroupType == 11) {
          const chAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.takeGoodsNumber),
            // this.$amount.add([c,R.takeGoodsNumber]),
            0
          );
          const chdAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.notTakeGoodsNumber),
            // this.$amount.add([c,R.notTakeGoodsNumber]),
            0
          );
          const rkAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.intoStockNumber),
            // this.$amount.add([c,R.intoStockNumber]),
            0
          );
          const returnNum = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.returnGoodsNumber),
            // this.$amount.add([c,R.returnGoodsNumber]),
            0
          );
          this.$set(item, "chAll", chAll); // 提货
          this.$set(item, "chdAll", chdAll); //入库
          this.$set(item, "rkAll", rkAll); // 未提数
          this.$set(item, "returnNum", returnNum); // 退供数
          this.totalObj.distriNumTotal =
            Number(this.totalObj.distriNumTotal) + Number(item.salesAll);
          // this.$amount.add([this.totalObj.distriNumTotal,item.salesAll]);
          this.totalObj.distriPriceTotal =
            Number(this.totalObj.distriPriceTotal) +
            Number(item.purchaseTotalPrice);
          // this.$amount.add([this.totalObj.distriPriceTotal,item.purchaseTotalPrice]);
          this.totalObj.tackNum =
            Number(this.totalObj.tackNum) + Number(item.chAll);
          // this.$amount.add([this.totalObj.tackNum,item.chAll]);
          this.totalObj.notackNum =
            Number(this.totalObj.notackNum) + Number(item.chdAll);
          // this.$amount.add([this.totalObj.notackNum,item.chdAll]);
          this.totalObj.intoNum =
            Number(this.totalObj.intoNum) + Number(item.rkAll);
          // this.$amount.add([this.totalObj.intoNum,item.rkAll]);
          this.totalObj.returnNum =
            Number(this.totalObj.returnNum) + Number(item.returnNum);
          // this.$amount.add([this.totalObj.returnNum,item.returnNum]);

          // const returnNumPurchasePrice = this.$amount.multiply([item.returnNum,item.purchasePrice]);
          this.totalObj.returnPrice =
            Number(this.totalObj.returnPrice) +
            Number(item.returnNum) * Number(item.purchasePrice);
          // this.$amount.add([this.totalObj.returnPrice,returnNumPurchasePrice]);
        }
        if (this.sizeGroupType == 12) {
          item.sizeInfoList.forEach((ele) => {
            if (!ele.number) {
              if (this.headerLeft[0].ofType == 2) {
                ele.number = item.retailPrice || "";
              } else {
                ele.number = 0;
              }
            }
            ele.retailPrice = item.retailPrice || "";
            if (!ele.bgShow) {
              ele.bgShow = false;
            }
          });
        }
        if (this.sizeGroupType == 13) {
          const chAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.orderNumber),
            // this.$amount.add([c,R.orderNumber]),
            0
          );
          this.$set(item, "chAll", chAll);
          const numberAll = item.sizeInfoList.reduce(
            (c, R) => c + Number(R.number),
            // this.$amount.add([c,R.number]),
            0
          );
          this.$set(item, "numberAll", numberAll);
        }
        // 设置唯一标识字段用于去重
        if (
          this.sizeGroupType == 7 ||
          this.sizeGroupType == 9 ||
          this.sizeGroupType == 11
        ) {
          let soleLabel =
            item.spuInfoId + item.batchNo + item.invoicesNo + item.colorValue;
          this.$set(item, "soleLabel", soleLabel);
        } else if (this.sizeGroupType == 10) {
          let soleLabel =
            item.upstreamNo +
            item.spuInfoId +
            item.invoicesNo +
            item.colorValue; //解决采购单尺码规格一样被去重问题(设置key为单号)
          this.$set(item, "soleLabel", soleLabel);
        } else {
          if (item.colorValue) {
            let soleLabel = item.spuInfoId + item.colorValue;
            this.$set(item, "soleLabel", soleLabel);
            if (this.headerLeft[0].uniqueId === "deptId") {
              this.$set(item, "soleLabel", index);
            }
          } else {
            this.$set(item, "soleLabel", item.spuInfoId);
          }
        }
      });
      this.dataList = this.uniqueFunc(this.dataList.concat(list), "soleLabel");
      this.$nextTick(() => {
        if (
          this.$refs.body.scrollHeight >
          (this.$refs.body.clientHeight || this.$refs.body.innerHeight)
        ) {
          this.bodyScroll = true;
        } else {
          this.bodyScroll = false;
        }
      });
    },
    editorChange() {
      //提交前判断是否填写的数量是否正确
      let isReturn = false;
      this.dataList.forEach((line, index) => {
        let flag = false;
        line.sizeInfoList.forEach((size) => {
          if (
            size.number > 0 &&
            Number(size.number) > Number(size.usableQuantity)
          ) {
            this.$set(size, "isError", true);
            flag = true;
            isReturn = true;
          }
        });
        if (flag) {
          this.dataList.splice(index, 1);
          this.dataList.unshift(line);
        }
      });
      return isReturn;
    },
    uniqueFunc(arr, uniId) {
      const res = new Map();
      return arr.filter(
        (item) => !res.has(item[uniId]) && res.set(item[uniId], 1)
      );
    },
    queryList(No) {
      this.dataList.forEach((item) => {
        if (item.goodsNo.includes(No)) {
          item.isVisiable = true;
        } else {
          item.isVisiable = false;
        }
      });
    },
    getsendData() {
      let sendArr = [];
      let errorMessage = ""; //sizeGroupType == 8的时候才有作用
      if (this.sizeGroupType != 12) {
        this.dataList.forEach((item) => {
          if (!item.modify && this.sizeGroupType == 8) {
            return (errorMessage = "请添加需要的采购商品数量");
          }
          item.sizeInfoList.forEach((size) => {
            //判断这个货品都有输入并且过滤掉等于0的尺码数据
            if (size.modify && size.number > 0) {
              let obj = {
                spu: item.spu,
                goodsNo: item.goodsNo,
                spuInfoId: item.spuInfoId,
                sku: size.sku,
                barCode: size.barCode,
                skuInfoId: size.skuInfoId,
                number: size.number,
                price: item.price,
                retailPrice: item.retailPrice,
                batchNo: item.batchNo,
              };
              if (this.sizeGroupType == 6 || this.sizeGroupType == 8) {
                obj["purchasePrice"] = item.purchasePrice;
              }
              if (this.sizeGroupType == 7 || this.sizeGroupType == 9) {
                obj["purchasePrice"] = item.purchasePrice;
                obj["purchaseInvoicesId"] = item.invoicesId;
              }
              if (this.sizeGroupType == 9) {
                obj["purchasePrice"] = item.purchasePrice;
                obj["purchaseInvoicesId"] =
                  item.upstreamId || size.purchaseInvoicesId;
              }
              if (this.sizeGroupType == 8) {
                if (!item.firstDeliveryTime || !item.finalDeliveryTime) {
                  return (errorMessage = "请选择首批交货日期范围");
                }
                let isOverDate =
                  new Date(item.firstDeliveryTime.replace(/\-/g, "/")) >
                  new Date(item.finalDeliveryTime.replace(/\-/g, "/"));
                if (isOverDate) {
                  return (errorMessage = `${item.goodsName}首批交货日期不能大于最终交货日期`);
                }
                obj["firstDeliveryTime"] = item.firstDeliveryTime;
                obj["finalDeliveryTime"] = item.finalDeliveryTime;
              }
              if (this.sizeGroupType == 13) {
                obj["id"] = size.id;
                obj["procedureFollowId"] = size.procedureFollowId;
              }

              if (this.sizeGroupType == 1 && Number(size.number) > 0) {
                sendArr.push(obj);
              } else {
                sendArr.push(obj);
              }
            }
          });
        });
      } else {
        sendArr = this.dataList;
      }
      if (!errorMessage) {
        return sendArr;
      } else {
        return errorMessage;
      }
    },
    delRow(index) {
      this.$confirm("是否删除该条记录", "提示")
        .then(() => {
          this.dataList.splice(index, 1);
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        })
        .catch(() => {});
    },
    getinitVla(val) {
      this.curSizeVal = val;
    },
    getChangePriceVla(item, size) {
      if (size.number == this.curSizeVal) return;
      size.bgShow = true;
      this.$set(size, "modify", true);
      this.$message({
        message: "操作成功",
        type: "success",
        duration: 1500,
      });
    },
    getchangeVla(item, size) {
      if (size.number == this.curSizeVal) return;
      if (!/(^[0-9]\d*$)/.test(size.number)) {
        size.number = this.curSizeVal;
        return this.$message.error("请输入大于等于0的整数");
      }
      if (
        this.sizeGroupType != 5 &&
        this.sizeGroupType == 1 &&
        Number(size.number) > Number(size.usableQuantity) &&
        this.isAllowNegativeStocks == "N"
      ) {
        size.number = this.curSizeVal;
        let tipStr = "";
        if (this.headerLeft[0].type === "tc") {
          tipStr = "退仓数不能大于可用库存";
        } else {
          tipStr = "铺货数不能大于可用库存";
        }
        return this.$message.error(tipStr);
      }
      if (
        this.sizeGroupType == 9 &&
        Number(size.number) > Number(size.notTakeGoodsNumber)
      ) {
        size.number = this.curSizeVal;
        return this.$message.error("提货数不能大于未提数");
      }
      if (
        this.sizeGroupType == 7 &&
        Number(size.number) > Number(size.intoStockNumber) &&
        this.isAllowNegativeStocks == "N"
      ) {
        size.number = this.curSizeVal;
        return this.$message.error("退供数不能大于入库数");
      }
      item.salesAll = item.sizeInfoList.reduce(
        (c, R) => this.$amount.add([c, R.number], 0), // c + Number(R.number)
        0
      );
      if (this.sizeGroupType == 6) {
        item.totalPrice = (item.purchasePrice * item.salesAll).toFixed(2);
      }
      if (this.sizeGroupType == 8) {
        item.stockAll = this.$amount.multiply([
          item.salesAll,
          item.purchasePrice,
        ]);
        if (Number(size.number) > 0) {
          this.$set(size, "modify", true);
          this.$set(item, "modify", true);
          this.$message({
            message: "操作成功",
            type: "success",
            duration: 1500,
          });
        }
        return;
      }
      if (this.sizeGroupType == 7 || this.sizeGroupType == 9) {
        item.stockAll = this.$amount.multiply([
          item.salesAll,
          item.purchasePrice,
        ]); // Number(item.salesAll) * Number(item.purchasePrice)
      }
      if (this.sizeGroupType == 13) {
        const numberAll = item.sizeInfoList.reduce(
          (c, R) => c + Number(R.number),
          0
        );
        this.$set(item, "numberAll", numberAll);
      }
      this.$set(size, "modify", true);
      this.$message({
        message: "操作成功",
        type: "success",
        duration: 1500,
      });
    },
    getInitPrice(val) {
      this.curPriceVal = val;
    },
    clearAll(tip) {
      if (!tip) {
        this.$confirm("此操作将会清空添加记录", "提示")
          .then(() => {
            this.dataList = [];
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
            });
          })
          .catch(() => {});
      } else {
        this.dataList = [];
      }
    },
    checkValue(item) {
      item.price = item.price.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
    },
    getChangePrice(item) {
      if (item.price === this.curPriceVal) return;
      if (isNaN(item.price) && !item.price) {
        item.price = this.curPriceVal;
        return this.$message.error("请输入有效值");
      }
      this.$message({
        message: "操作成功",
        type: "success",
        duration: 1500,
      });
    },
    getRowBgc(size) {
      if (+this.totalObj.noReceivedTotal === 0) {
        return "row-bg-red";
      }
      if (+size.surplusNumber === 0) {
        return "row-bg-green";
      }
    },
    checkNumberChange(row) {
      if (row.checkNumber > row.shipmentNumber) {
        this.$message.error(`可验收数量为${row.shipmentNumber}`);
        this.$set(row, "checkNumber", this.checkHistoryNumber);
        return;
      }
      this.$set(row, "bgShow", row.checkNumber > 0);
      this.$emit("updateCheckNumber", {
        checkHistoryNumber: this.checkHistoryNumber,
        ...row,
      });
    },
    getCheckNumber(row) {
      this.checkHistoryNumber = row.checkNumber;
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  // overflow:auto;
  box-sizing: border-box;
  font-size: 12px;
}
.table-header {
  background: #fafafa;
  font-size: 14px;
  border: 1px solid #e6ebf5;
  overflow: hidden;
  display: inline-flex;
  > div {
    text-align: center;
    border-right: 1px solid #e6ebf5;
    float: left;
    // width: 80px;
    &:last-child {
      border: none;
    }
  }
  .size-group {
    border-right: 1px solid #e6ebf5;
    padding: 5px 0;
    float: left;
    div {
      text-align: left;
      span {
        border: 1px solid #eee;
        background: #fff;
        width: 40px;
        height: 26px;
        line-height: 26px;
        display: inline-block;
        text-align: center;
      }
    }
    .current-group {
      span {
        background: rgb(194, 234, 252);
      }
    }
    .groupOne {
      margin-left: 60px;
    }
    .groupTwo {
      display: flex;
      align-items: center;
    }
    .groupThree {
      display: flex;
      align-items: center;
      section {
        width: 90px;
        padding-left: 5px;
      }
    }
  }
}

.table-body {
  border: 1px solid #e6ebf5;
  border-top: none;
  overflow: auto;
  .current-row {
    background: #ebf7ff;
    div {
      background: #ebf7ff !important;
    }
  }
  .data-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6ebf5;
    overflow: hidden;
    > div {
      //   width: 80px;
      text-align: center;
      border-right: 1px solid #e6ebf5;
      display: flex;
      align-self: stretch;
      align-items: center;
      justify-content: center;
      &:last-child {
        border: none;
      }
    }
    .change-width {
      width: 74px;
    }
    .size-group {
      display: flex;
      text-align: center;
      border-right: 1px solid #e6ebf5;
    }
    .group-three {
      .group-label {
        width: 90px;
        div {
          height: 31px;
          line-height: 31px;
          border-bottom: 1px solid #e6ebf5;
          border-right: 1px solid #e6ebf5;
          &:last-child {
            border-bottom: none;
          }
        }
      }
      .group-value {
        div {
          span {
            display: inline-block;
            width: 40px;
            height: 31px;
            line-height: 31px;
            border-right: 1px solid #e6ebf5;
            border-bottom: 1px solid #e6ebf5;
          }
          & > .input-item {
            border: none;
            text-align: center;
            display: inline-block;
            width: 40px;
            height: 31px;
            line-height: 31px;
            border-right: 1px solid #e6ebf5;
            border-bottom: 1px solid #e6ebf5;
            background-color: #facd91;
          }
          .is-disabled {
            cursor: not-allowed;
            background-color: #f5f7fa;
          }
          .is-updated {
            background-color: #43d268;
          }
          &:last-child span {
            border-bottom: none;
          }
        }
      }
    }
  }
}
.total-row {
  height: 34px;
  line-height: 34px;
  display: flex;
  background: #f3f4f7;
  span {
    margin-left: 20px;
  }
}

.table-body::-webkit-scrollbar {
  width: 6px !important;
  background: #eee;
}
.table-body {
  -ms-overflow-style: none;
}
.table-body {
  overflow: -moz-scrollbars-none;
}
.table-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #78b4b4;
}
</style>
<style>
.size-group .el-checkbox__label {
  padding-left: 5px;
}
</style>
